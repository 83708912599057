import { Stack, TextField, Typography } from "@mui/material";
import { useRef } from "react";

type Props = {
  value: string;
  setValue: (v: string) => void;
  placeholder?: string;
  sx?: any;
  maxRows?: number;
  type?: string;
  onFocus?: Function;
  onBlur?: Function;
  tips?: string;
  placeholderColor?: string;
  variant?: "transparent" | "normal" | "none" | "form";
  size?: "medium" | "small";
  onSubmit?: Function;
  prefix?: string;
  disabled?: boolean;
  forceEnd?: boolean;
  className?: string;
};

export const CustomTextField: React.FC<Props> = ({
  value,
  setValue,
  placeholder = "",
  sx,
  maxRows = 1,
  type = "text",
  onFocus,
  onBlur,
  tips,
  variant = "transparent",
  placeholderColor,
  size = "medium",
  onSubmit,
  prefix,
  disabled,
  forceEnd,
  className,
}) => {
  const inputRef = useRef<HTMLInputElement>();

  if (variant === "normal") {
    return (
      <Stack
        direction="column"
        gap={0.5}
        width="100%"
        sx={sx}
        onClick={(e) => e.stopPropagation()}
      >
        <Typography variant="bodySmall" color="#1E1E1E">
          {placeholder}
        </Typography>
        <TextField
          maxRows={maxRows}
          multiline={maxRows > 1}
          disabled={disabled}
          inputRef={inputRef}
          sx={{
            "& fieldset": {
              borderRadius: "8px",
              border: "1px solid rgba(0, 0, 0, 0.10)",
            },
            "& input::placeholder": {
              fontSize: 14,
            },
            "& input": {
              fontSize: 14,
              fontWeight: 400,
              color: "#1E1E1E",
              fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
              p: 1.5,
            },
            "& textarea": {
              fontSize: 14,
              fontWeight: 400,
              color: "#1E1E1E",
              fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
            },
          }}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onSelect={() => {
            if (forceEnd) {
              setTimeout(() => {
                inputRef.current?.setSelectionRange(value.length, value.length);
              }, 10);
            }
          }}
          onFocus={(e) => {
            if (forceEnd) {
              setTimeout(() => {
                inputRef.current?.setSelectionRange(value.length, value.length);
              }, 10);
            }
            if (!!onFocus) {
              e.stopPropagation();
              onFocus();
            }
          }}
          onBlur={(e) => {
            if (!!onBlur) {
              e.stopPropagation();
              onBlur();
            }
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          type={type}
          label=""
          variant="outlined"
          placeholder={placeholder}
          inputProps={{
            pattern: type === "number" ? "[0-9].*" : undefined,
            inputMode: type === "number" ? "numeric" : "text",
            min: type === "number" ? 0 : undefined,
            className: className,
          }}
          InputProps={{
            startAdornment: !!prefix ? (
              <Typography variant="headlineSmall">{prefix}</Typography>
            ) : undefined,
          }}
        />
      </Stack>
    );
  }

  return (
    <TextField
      maxRows={maxRows}
      multiline={maxRows > 1}
      disabled={disabled}
      inputRef={inputRef}
      sx={{
        minWidth: 200,
        ...sx,
        "& fieldset": {
          border: variant === "form" ? "0.5px solid rgba(0,0,0,0.1)" : "none",
          borderRadius: "8px",
        },
        "& input::placeholder": {
          fontSize: variant === "none" ? 14 : variant === "form" ? 16 : 24,
        },
        "& input": {
          fontSize:
            size === "small"
              ? 14
              : variant === "none"
              ? 14
              : variant === "form"
              ? 16
              : 24,
          fontWeight: size === "small" ? 300 : 400,
          color: "#1E1E1E",
          fontFamily:
            size === "small" || variant === "form"
              ? "RobotoCondensed"
              : "Rye, Roboto, Helvetica, Arial, sans-serif",
          textAlign:
            variant === "none" || variant === "form" ? "start" : "center",
        },
        "& textarea": {
          fontSize:
            size === "small"
              ? 14
              : variant === "none"
              ? 14
              : variant === "form"
              ? 16
              : 24,
          fontWeight: size === "small" ? 300 : 400,
          color: "#1E1E1E",
          fontFamily:
            size === "small" || variant === "form"
              ? "RobotoCondensed"
              : "Rye, Roboto, Helvetica, Arial, sans-serif",
        },
        "& .MuiInputBase-input": {
          p: maxRows > 1 ? 0 : 1.5,
        },
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" && !!onSubmit) {
          onSubmit();
        }
      }}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onFocus={(e) => {
        if (!!onFocus) {
          e.stopPropagation();
          onFocus();
        }
      }}
      onBlur={(e) => {
        if (!!onBlur) {
          e.stopPropagation();
          onBlur();
        }
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      inputProps={{
        pattern: type === "number" ? "[0-9].*" : undefined,
        inputMode: type === "number" ? "numeric" : "text",
        min: type === "number" ? 0 : undefined,
        className: className,
      }}
      InputProps={{
        startAdornment: !!prefix ? (
          <Typography variant="headlineSmall">{prefix}</Typography>
        ) : undefined,
      }}
      type={type}
      label=""
      variant="outlined"
      placeholder={placeholder}
    />
  );
};
