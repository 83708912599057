import { LoadingButton } from "@mui/lab";
import { useState } from "react";

type Props = {
  disabled?: boolean;
  onClick: Function;
  text: string;
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "outlinedTertiary"
    | "outlined"
    | "menu"
    | "danger"
    | "white";
  sx?: any;
  loading?: boolean;
  img?: string;
  className?: string;
  size?: "large" | "medium" | "small";
  propagate?: boolean;
};

export const CustomButton: React.FC<Props> = ({
  disabled,
  onClick,
  text,
  sx,
  loading,
  img,
  className,
  variant = "primary",
  size = "large",
  propagate,
}) => {
  const [localLoading, setLocalLoading] = useState(false);

  return (
    <LoadingButton
      loading={localLoading || loading}
      className={className}
      sx={{
        color:
          variant === "outlined"
            ? "#1E1E1E"
            : variant === "outlinedTertiary"
            ? "#B4237A"
            : variant === "white"
            ? "black"
            : "white",
        background:
          variant === "primary"
            ? "#1E1E1E"
            : variant === "tertiary"
            ? "#B4237A"
            : variant === "outlined"
            ? "transparent"
            : variant === "danger"
            ? "#d65858"
            : variant === "secondary"
            ? "#137CA3"
            : variant === "white"
            ? "white"
            : null,
        border:
          variant === "outlined"
            ? "1px solid #1E1E1E"
            : variant === "outlinedTertiary"
            ? "1px solid #B4237A"
            : undefined,
        borderRadius: "16px",
        pt: size === "small" || size === "medium" ? "6px" : 1.25,
        pb: size === "small" || size === "medium" ? "6px" : 1.25,
        pl: size === "small" ? 1.5 : 3,
        pr: size === "small" ? 1.5 : 3,
        fontSize: size === "small" ? 12 : size === "medium" ? 14 : 18,
        fontWeight: 400,
        fontFamily: "Rye",
        width: "fit-content",
        "&:disabled": {
          background:
            variant === "primary"
              ? "#1E1E1E"
              : variant === "tertiary"
              ? "#B4237A"
              : variant === "outlined"
              ? "transparent"
              : variant === "danger"
              ? "#d65858"
              : variant === "secondary"
              ? "#137CA3"
              : variant === "white"
              ? "white"
              : null,
          border:
            variant === "outlined"
              ? "1px solid rgba(30, 30, 30, 0.7)"
              : variant === "outlinedTertiary"
              ? "1px solid rgba(180, 35, 122, 0.7)"
              : undefined,
          color:
            variant === "primary" ||
            variant === "tertiary" ||
            variant === "secondary"
              ? "white"
              : undefined,
          opacity: 0.2,
        },
        "&:hover": {
          color:
            variant === "outlined"
              ? "#1E1E1E"
              : variant === "outlinedTertiary"
              ? "#B4237A"
              : variant === "white"
              ? "black"
              : "white",
          background:
            variant === "primary"
              ? "#1E1E1E"
              : variant === "tertiary"
              ? "#B4237A"
              : variant === "outlined"
              ? "transparent"
              : variant === "danger"
              ? "#d65858"
              : variant === "secondary"
              ? "#137CA3"
              : variant === "white"
              ? "white"
              : null,
          border:
            variant === "outlined"
              ? "1px solid #1E1E1E"
              : variant === "outlinedTertiary"
              ? "1px solid #B4237A"
              : undefined,
          opacity: 0.8,
        },
        textTransform: "none",
        ...sx,
      }}
      disabled={!!disabled}
      onClick={async (e) => {
        if (!propagate) e.stopPropagation();
        setLocalLoading(true);
        await onClick();
        setLocalLoading(false);
      }}
    >
      {!!img && (
        <img
          src={img}
          style={{
            width: 24,
            height: 24,
            objectFit: "contain",
            marginRight: 8,
          }}
        />
      )}
      {text}
    </LoadingButton>
  );
};
