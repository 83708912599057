import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getUser, updateUser } from "../../api/user";
import { Permission, useAuth } from "../../hooks/useAuth";
import { CustomButton } from "../common/CustomButton";
import { CustomCard } from "../common/CustomCard";
import { CustomSelectField } from "../common/CustomSelectField";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { setUser } from "../../redux/features/userSlice";

export const GenderPage = () => {
  const navigate = useNavigate();
  useAuth(Permission.USER_ONLY);
  const [gender, setGender] = useState("M");
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (!!user?.gender) setGender(user.gender);
  }, [user]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
    >
      <CustomCard title="Your Delulu Details">
        <Typography variant="headlineSmall" textAlign="center" mb={1}>
          What&apos;s your Gender?
        </Typography>
        <CustomSelectField
          placeholder="Gender"
          value={gender}
          setValue={setGender}
          options={[
            {
              value: "M",
              label: "Male",
            },
            {
              value: "F",
              label: "Female",
            },
          ]}
        />
        <CustomButton
          text="Next"
          size="medium"
          variant="outlined"
          onClick={async () => {
            if (!user?.uid) return;
            try {
              await updateUser(user.uid, {
                gender: gender,
              });
              const newUser = await getUser(user.uid);
              dispatch(setUser(newUser));
              navigate("/uni");
            } catch (err: any) {
              dispatch(
                setSnackbar({
                  open: true,
                  message:
                    err?.response?.data?.error?.message ??
                    "Error updating gender.",
                  severity: "error",
                })
              );
            }
          }}
          sx={{
            mt: 2,
            mb: 4,
          }}
        />
      </CustomCard>
    </Stack>
  );
};
