import axios from "axios";

export const NoAuthAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

API.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("app_session"); // TODO: Handle refresh logic
    if (!!token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default API;
