import { Stack, Typography } from "@mui/material";
import {
  createIgPosts,
  generateIgLink,
  getIsConnectedIg,
} from "../../api/instagram";
import { Permission, useAuth } from "../../hooks/useAuth";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CustomButton } from "../common/CustomButton";
import { CustomCard } from "../common/CustomCard";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { getUser } from "../../api/user";
import { setUser } from "../../redux/features/userSlice";

//TIKTOK IMPL
// import { generateTikTokLink, getIsConnectedTikTok } from "../../api/tiktok";

export const ConnectIgPage = () => {
  useAuth(Permission.USER_ONLY);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const [hasConnected, setHasConnected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.uid) return;
    getIsConnectedIg().then((v) => {
      setHasConnected(v);
    });
  }, [user]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
    >
      <CustomCard title={"Connect Instagram"}>
        <Typography
          variant="headlineSmall"
          textAlign="center"
          mb={3}
          mt={4}
          whiteSpace="pre-line"
        >
          {hasConnected
            ? "You have connected your Instagram, click next to continue onboarding."
            : "Connect your account to pre-fill your profile"}
        </Typography>
        <CustomButton
          text={hasConnected ? "Next" : "Connect"}
          variant="outlined"
          sx={{
            mb: 2,
          }}
          size="medium"
          onClick={async () => {
            if (hasConnected) {
              // create instagram post
              try {
                await createIgPosts(user.uid);
              } catch (err) {
                console.log(err);
              }
              const newUser = await getUser(user?.uid ?? "");
              dispatch(setUser(newUser));
              navigate("/");
              return;
            }
            try {
              const link = await generateIgLink();
              if (!link) {
                dispatch(
                  setSnackbar({
                    open: true,
                    message: "Unable to generate link",
                    severity: "error",
                  })
                );
                return;
              }
              window.location.href = link;
            } catch (err: any) {
              dispatch(
                setSnackbar({
                  open: true,
                  message:
                    err?.response?.data?.error?.message ??
                    "Error linking instagram.",
                  severity: "error",
                })
              );
            }
          }}
        />
        <Typography
          fontSize={12}
          variant="notice"
          sx={{
            textDecorationLine: "underline",
            cursor: "pointer",
            WebkitTapHighlightColor: "rgba(0,0,0,0)",
            textAlign: "center",
          }}
          onClick={() => {
            navigate("/name");
          }}
          mb={6}
        >
          Do it manually
        </Typography>
      </CustomCard>
    </Stack>
  );
};
