import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { connectIg, createIgPosts } from "../../api/instagram";
//TIKTOK IMPL
// import { connectTikTok } from "../../api/tiktok";
import { Permission, useAuth } from "../../hooks/useAuth";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CustomButton } from "../common/CustomButton";
import { CustomCard } from "../common/CustomCard";

export const ConnectIgRedirectPage = () => {
  useAuth(Permission.USER_ONLY);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    if (!code || !state || !user?.uid) return;

    connectIg(code, state)
      .then(async () => {
        // create instagram post
        await createIgPosts(user.uid);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            open: true,
            message:
              err?.response?.data?.error?.message ??
              "Error connecting Instagram, redirecting back to previous page.",
            severity: "error",
          })
        );
        setTimeout(() => {
          navigate("/instagram");
        }, 3000);
      });
  }, [searchParams, user]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
    >
      <CustomCard title="Connect Instagram">
        <Typography variant="headlineSmall" textAlign="center" mb={3} mt={4}>
          {loading ? "Connecting Instagram..." : "Connected Instagram!"}
        </Typography>
        <CustomButton
          text="Next"
          size="medium"
          disabled={loading}
          variant="outlined"
          sx={{
            mb: 6,
          }}
          onClick={async () => {
            navigate("/");
          }}
        />
      </CustomCard>
    </Stack>
  );
};
