import { FC } from "react";
import { Permission, useAuth } from "../../hooks/useAuth";
import { Stack, Box, Typography } from "@mui/material";
import { useAppSelector } from "../../redux/hooks";
import QRCode from "react-qr-code";

type Props = {
  setEl?: Function;
};

export const ReferralIgPage: FC<Props> = ({ setEl }) => {
  const user = useAppSelector((state) => state.user.user);

  useAuth(Permission.USER_ONLY);

  return (
    <Box position={"absolute"} zIndex={-2} top={0} left={0} width={"100vw"}>
      <Box position={"relative"}>
        <Stack
          position="absolute"
          top={0}
          left={0}
          direction="column"
          width="100%"
          sx={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/dull_bg.png), lightgray 50% / cover no-repeat",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100dvh",
          }}
          alignItems="center"
          justifyContent="center"
          ref={(e) => {
            if (!!setEl) setEl(e);
          }}
          id="refer-ig"
        >
          <Stack direction="row">
            <Stack width={48} />
            <Stack
              direction="column"
              alignItems="center"
              sx={{
                borderRadius: "8px",
                background: "white",
              }}
            >
              <img
                src="/logo_border.png"
                alt="avatar"
                style={{
                  borderRadius: "50%",
                  width: 100,
                  height: 100,
                  objectFit: "cover",
                  marginBottom: 8,
                  marginTop: "-24px",
                }}
              />
              <Typography
                variant="headlineMedium"
                color="#22171E"
                textAlign="center"
                pl={3}
                pr={3}
              >
                Join me on Delulu to find your crush
              </Typography>
              <img
                src="/line.svg"
                alt="line"
                style={{
                  width: "100%",
                  height: "12px",
                  marginTop: 12,
                }}
              />
              <Box p={3}>
                {/* @ts-ignore */}
                <QRCode
                  size={180}
                  style={{ height: "180px", maxWidth: "100%", width: "180px" }}
                  value={`${process.env.REACT_APP_APP_URL}/refer/${
                    user?.wingman?.code ?? ""
                  }`}
                  viewBox={`0 0 180 180`}
                />
              </Box>
              <img
                src="/logo_text.svg"
                alt="Delulu"
                height="32px"
                style={{
                  marginBottom: 24,
                }}
              />
            </Stack>
            <Stack width={48} />
          </Stack>
        </Stack>

        <Box
          position={"absolute"}
          top={0}
          left={0}
          width={"100%"}
          height={"100dvh"}
          sx={{
            backgroundColor: "black",
          }}
        />
      </Box>
    </Box>
  );
};
