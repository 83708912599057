import API from "./api";

export const getInterests = async () => {
  const resp = await API.get(
    "/interests?sort[0]=label:asc&pagination[page]=1&pagination[pageSize]=1000"
  );
  return (
    resp.data?.data?.map((d: any) => ({
      value: d.id,
      label: d.label,
    })) ?? []
  );
};

export const getInfoTemplate = async () => {
  const resp = await API.get(
    "/templates?filters[group]=EDIT_PROFILE&filters[section]=INFO"
  );
  return resp.data?.data?.[0]?.fields;
};
