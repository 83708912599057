interface Country {
  country: string
  currency: string
  currencySymbol: string
  countryCode: string
  withdrawalMethod: "TNG" | "AMAZON"
  paymentGatewayCode: "payex" | "razer-pay"
}

const countries: Country[] = [
  { country: "US", currency: "USD", currencySymbol: "$", countryCode: "+1", withdrawalMethod: "AMAZON", paymentGatewayCode: "razer-pay" },
  { country: "MY", currency: "MYR", currencySymbol: "RM", countryCode: "+60", withdrawalMethod: "TNG", paymentGatewayCode: "payex" },
];


export default countries;