import { Stack, Typography } from "@mui/material";

type Props = {
  quantity: number;
  price: string;
  gender: string;
  sx?: any;
  setOpen: (v: boolean) => void;
  isFree: boolean;
};

export const PriceCard: React.FC<Props> = ({
  quantity,
  price,
  gender,
  sx,
  setOpen,
  isFree,
}) => {
  return (
    <Stack
      direction="column"
      p={2}
      sx={{
        background: "white",
        borderRadius: "16px",
        boxShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.70)",
        cursor: "pointer",
        WebkitTapHighlightColor: "rgba(0,0,0,0)",
        width: "80%",
        maxWidth: "261px",
        ...sx,
      }}
      onClick={() => {
        setOpen(true);
      }}
      className={`price-card price-card-${quantity}`}
    >
      <Stack direction="row" alignItems="center" gap={1.5}>
        <Typography variant="heroMedium">{quantity}</Typography>
        <Stack direction="column">
          <Typography variant="bodySmall" color="#22171E">
            CARD
          </Typography>
          <Typography
            variant="headlineSmall"
            color={isFree ? "#B4237A" : "#22171E"}
          >
            {isFree ? "FREE DRAW" : price}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          borderRadius: "8px",
          background:
            "radial-gradient(60.11% 60.11% at 50% 50%, rgba(0, 0, 0, 0.00) 60.94%, rgba(0, 0, 0, 0.40) 100%), url(/girl.png), lightgray 50% / cover no-repeat",
        }}
        justifyContent="center"
        alignItems="center"
        width="100%"
        p={3}
        direction="column"
      >
        <Typography variant="heroSmall">DELULU</Typography>
        <Typography variant="heroLarge" fontSize={72}>
          {gender}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap={1.5}
        alignSelf="flex-end"
        sx={{
          transform: "rotate(180deg)",
        }}
      >
        <Typography variant="heroMedium">{quantity}</Typography>
        <Stack direction="column">
          <Typography variant="bodySmall" color="#22171E">
            CARD
          </Typography>
          <Typography
            variant="headlineSmall"
            color={isFree ? "#B4237A" : "#22171E"}
          >
            {isFree ? "FREE DRAW" : price}
          </Typography>
        </Stack>
      </Stack>
      {isFree && (
        <Typography
          sx={{
            background: "#B4237A",
            borderRadius: "8px",
            py: 0.5,
            px: 1,
            width: 150,
            position: "absolute",
            bottom: -12,
            left: "50%",
            transform: "translateX(-50%)",
          }}
          variant="bodySmall"
          fontSize={10}
          color="white"
          textAlign="center"
        >
          Entitled for {quantity} Free Draw
        </Typography>
      )}
    </Stack>
  );
};
