import { TransferAssetDto } from "../types/TransferAssetDto";
import API from "./api";

export const getGiftsReceived = async (userUid: string) => {
  try {
    const resp = await API.get(
      `/transfer-assets/total?filters[user][uid]=${userUid}`
    );
    return resp?.data?.data?.total ?? 0;
  } catch (err: any) {
    return 0;
  }
};

export const getGift = async (uid: string, userUid: string) => {
  try {
    const resp = await API.get(
      `/transfer-assets?filters[user][uid]=${userUid}&filters[uid]=${uid}&pagination[pageSize]=1&pagination[page]=1`
    );
    return resp?.data?.data?.[0] as TransferAssetDto;
  } catch (err: any) {
    console.log(err);
    return undefined;
  }
};

export const returnGift = async (uid: string) => {
  const resp = await API.put(`/transfer-assets/${uid}`, {
    data: {
      returned: true,
    },
  });
  return resp.data;
};
