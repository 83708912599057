import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getUser, updateUser } from "../../api/user";
import { Permission, useAuth } from "../../hooks/useAuth";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { setUser } from "../../redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { UserDto } from "../../types/UserDto";
import { CustomButton } from "../common/CustomButton";
import { CustomCard } from "../common/CustomCard";
import { CustomTextField } from "../common/CustomTextField";

type Props = {};

export const NamePage: React.FC<Props> = () => {
  const user = useAppSelector((state) => state.user.user);
  useAuth(Permission.USER_ONLY);
  const [name, setName] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setName(user?.displayName ?? "");
  }, [user]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
    >
      <CustomCard title="Your Delulu Details">
        <Typography variant="headlineSmall" textAlign="center" mb={1}>
          What is your name?
        </Typography>
        <CustomTextField value={name} setValue={setName} placeholder="Name" />
        <CustomButton
          text="Next"
          variant="outlined"
          size="medium"
          disabled={!name}
          onClick={async () => {
            if (!user?.uid) return;
            try {
              await updateUser(user.uid, {
                displayName: name,
              });
              // get user
              const newUser: UserDto = await getUser(user.uid);
              dispatch(setUser(newUser));
              navigate("/");
            } catch (err: any) {
              dispatch(
                setSnackbar({
                  open: true,
                  message:
                    err?.response?.data?.error?.message ??
                    "Error updating name.",
                  severity: "error",
                })
              );
            }
          }}
          sx={{
            mt: 2,
            mb: 4,
          }}
        />
      </CustomCard>
    </Stack>
  );
};
