import { UserDto } from "../types/UserDto";
import API from "./api";

export const getChatRooms = async () => {
  const resp = await API.get<{
    data: {
      id: any;
      uid: string;
      users: UserDto[];
      createdAt: string;
      updatedAt?: string;
    }[];
    meta: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    }
  }>("/chat-rooms", {
    params: {
      'populate': "users",
      'sort[0]': "updatedAt:desc",
      'pagination[page]': 1,
      'pagination[pageSize]': 1000,
    }
  });
  return resp.data;
};