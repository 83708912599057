import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getWingman } from "../../api/wingman";
import { Permission, useAuth } from "../../hooks/useAuth";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { useAppDispatch } from "../../redux/hooks";
import { CustomButton } from "../common/CustomButton";

export const ReferLandingPage = () => {
  const navigate = useNavigate();
  useAuth(Permission.GUEST_ONLY);
  const params = useParams();
  const [name, setName] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!params.nickname) return;
    getWingman(params.nickname ?? "")
      .then((user) => {
        setName(user?.displayName ?? "Someone");
        localStorage.setItem("referrer", params.nickname ?? "");
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            open: true,
            message:
              "Invalid referrer link, redirect to login page in 3 seconds...",
            severity: "error",
          })
        );
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      });
  }, [params.nickname]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
      position="relative"
    >
      <img
        src="/logo.svg"
        alt="Delulu"
        style={{
          objectFit: "contain",
          width: 126,
          height: 164,
          marginBottom: 16,
          pointerEvents: "none",
        }}
      />
      <Typography variant="heroSmall" mb={3}>
        DeLuLu
      </Typography>
      <Typography
        variant="headlineSmall"
        mb={2}
        color="white"
        textAlign="center"
      >
        Hello, {name}&apos;s friend
      </Typography>
      <CustomButton
        text="Let's get Delulu"
        variant="white"
        onClick={() => {
          localStorage.setItem("referrer", params.nickname ?? "");
          navigate("/login");
        }}
        sx={{
          pl: 6,
          pr: 6,
        }}
      />
      <Typography
        textAlign="center"
        variant="notice"
        color="white"
        fontSize={14}
        mt={3}
        width={200}
      >
        By logging in, you agree with our{" "}
        <span
          style={{
            textDecorationLine: "underline",
            cursor: "pointer",
            WebkitTapHighlightColor: "rgba(0,0,0,0)",
          }}
          onClick={() => {
            navigate("/privacy-policy");
          }}
        >
          Privacy Policy
        </span>{" "}
        and{" "}
        <span
          style={{
            textDecorationLine: "underline",
            cursor: "pointer",
            WebkitTapHighlightColor: "rgba(0,0,0,0)",
          }}
          onClick={() => {
            navigate("/terms-conditions");
          }}
        >
          Terms & Conditions
        </span>
        .
      </Typography>
    </Stack>
  );
};
