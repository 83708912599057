import { ChevronLeft } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { getReward } from "../../api/reward";
import { getFreeDrawWallet } from "../../api/wallet";
import { Permission, useAuth } from "../../hooks/useAuth";
import { useAppSelector } from "../../redux/hooks";
import { ProfileBack } from "../ProfilePage/ProfileBack";

export const SettingsPage = () => {
  useAuth(Permission.USER_ONLY);
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);

  const { data: freeDraw, refetch: refetchFreeDraw } = useQuery({
    queryKey: ["freeDraw", user?.uid],
    queryFn: () => getFreeDrawWallet(),
    enabled: !!user?.uid,
  });

  const { data: reward, refetch: refetchReward } = useQuery({
    queryKey: ["reward", user?.uid],
    queryFn: () => getReward(),
    enabled: !!user?.uid,
  });

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/dull_bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        gap: 2,
        pt: 6,
        pb: 13,
        height: "100dvh",
        alignItems: "center",
      }}
    >
      <Stack
        direction="column"
        width="100%"
        maxWidth={600}
        height="100%"
        sx={{
          overflow: "auto",
          gap: 1,
        }}
      >
        <Stack direction="row" alignItems="center" gap={2} pl={1}>
          <ChevronLeft
            sx={{
              color: "white",
              fontSize: 24,
              cursor: "pointer",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
            }}
            onClick={() => {
              navigate("/profile");
            }}
          />
          <Typography color="white" variant="headlineMedium">
            Edit Profile
          </Typography>
        </Stack>
        <ProfileBack
          refetch={() => {
            refetchFreeDraw();
            refetchReward();
            localStorage.setItem("canPopFreeDraw", "true");
          }}
        />
      </Stack>
    </Stack>
  );
};
