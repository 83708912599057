import { RewardDto } from "../types/RewardDto";
import API from "./api";

export const getReward = async () => {
  const resp = await API.get("/rewards?filters[slug]=free-draw");
  return (resp.data?.data?.[0] ?? {
    id: 0,
    title: "",
    slug: "",
    createdAt: "",
    updatedAt: "",
    isActive: false,
    bonus: 0,
    missions: [],
  }) as RewardDto;
};
