import { createRef, FC, useState } from "react";
import { Modal, Box, Typography, Stack } from "@mui/material";
import { CustomButton } from "../common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CustomSelectField } from "../common/CustomSelectField";
import { CustomTextField } from "../common/CustomTextField";
import { Add } from "@mui/icons-material";
import { compressImage } from "../../utility/file";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { uploadAsset } from "../../api/file";
import { useQuery } from "@tanstack/react-query";
import { getReportReasons, reportUser } from "../../api/report";

export const ReportModal: FC<{
  open: boolean;
  setOpen: (v: boolean) => void;
  target: string;
  name: string;
}> = ({ open, setOpen, target, name }) => {
  const user = useAppSelector((state) => state.user.user);
  const fileRef = createRef<HTMLInputElement>();
  const [image, setImage] = useState("");
  const dispatch = useAppDispatch();
  const [reason, setReason] = useState("");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(false);

  const { data: reportReasons } = useQuery({
    queryKey: ["reportReasons"],
    queryFn: () => getReportReasons(),
    enabled: !!user?.uid,
  });

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(3px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        marginX={"auto"}
        width="90%"
        maxWidth={550}
        borderRadius={4}
        display={"flex"}
        flexDirection={"column"}
        sx={{
          backgroundColor: "white",
        }}
      >
        <Typography
          textAlign="center"
          variant="headlineLarge"
          paddingY={2}
          paddingX={4}
          color="black"
        >
          Report
        </Typography>
        <img
          src="/line.svg"
          alt="line"
          style={{
            width: "100%",
            height: "12px",
            objectFit: "cover",
            pointerEvents: "none",
            marginTop: 8,
            marginBottom: 16,
          }}
        />
        <Stack direction="column" py={2} px={1} gap={2}>
          <Typography fontSize={16} fontWeight={400} variant="notice">
            Why do you want to report this user?
          </Typography>
          <CustomSelectField
            placeholder="Select reason"
            value={reason}
            setValue={setReason}
            options={
              reportReasons?.map((reason) => ({
                value: reason.id,
                label: reason.label,
              })) ?? []
            }
          />
          <Typography fontSize={16} fontWeight={400} variant="notice">
            Please elaborate more
          </Typography>
          <CustomTextField
            value={desc}
            setValue={setDesc}
            placeholder="Tell us more"
            variant="form"
            maxRows={4}
          />
          <Typography fontSize={16} fontWeight={400} variant="notice">
            Please submit if you have any proof/screenshots to allow us to
            investigate/
          </Typography>
          <Stack
            direction="column"
            alignItems="center"
            gap={1}
            sx={{
              cursor: "pointer",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
              borderRadius: "8px",
              border: "0.5px solid rgba(0, 0, 0, 0.10)",
              boxShadow: "0px 1px 8px -4px rgba(0, 0, 0, 0.04)",
              background: !!image ? `url(${image})` : undefined,
              width: 88,
              height: 88,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
            onClick={() => {
              fileRef.current?.click();
            }}
            py={3}
            px={1.5}
            width="fit-content"
          >
            {!image && (
              <>
                <Add
                  sx={{
                    fontSize: 16,
                    color: "rgba(97, 53, 181, 1)",
                  }}
                />
                <Typography
                  variant="notice"
                  color="rgba(97, 53, 181, 1)"
                  fontWeight={500}
                  fontSize={12}
                >
                  Add photo
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
        <img
          src="/line.svg"
          alt="line"
          style={{
            width: "100%",
            height: "12px",
            objectFit: "cover",
            pointerEvents: "none",
            marginTop: 8,
            marginBottom: 24,
          }}
        />
        <CustomButton
          text="Submit"
          loading={loading}
          disabled={!reason || !desc}
          onClick={async () => {
            try {
              await reportUser(+reason, desc, image, target);
              setReason("");
              setDesc("");
              setImage("");
              dispatch(
                setSnackbar({
                  open: true,
                  message: `We have received your report on ${name}. We will take appropriate actions upon investigations as soon as possible.`,
                  severity: "success",
                })
              );
              setOpen(false);
            } catch (err: any) {
              dispatch(
                setSnackbar({
                  open: true,
                  message:
                    err?.response?.data?.error?.message ??
                    "Error reporting user.",
                  severity: "error",
                })
              );
            }
          }}
          sx={{
            width: "calc(100% - 44px - 44px)",
            alignSelf: "center",
            mb: 2,
          }}
        />
        <input
          id="selectImage"
          accept="image/*"
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            const file = e.target.files?.item(0);
            if (file) {
              setLoading(true);
              const compressedFile = await compressImage(file);
              if (!compressedFile) {
                dispatch(
                  setSnackbar({
                    open: true,
                    severity: "error",
                    message:
                      "You file size is too big, please choose another image.",
                  })
                );
                return;
              }
              uploadAsset(compressedFile, "report")
                .then((url) => {
                  setImage(url);
                  setLoading(false);
                })
                .catch((err: any) => {
                  setLoading(false);
                  dispatch(
                    setSnackbar({
                      open: true,
                      severity: "error",
                      message:
                        err?.response?.data?.error?.message ??
                        "Error uploading image.",
                    })
                  );
                });
            }
          }}
          ref={fileRef}
        />
      </Box>
    </Modal>
  );
};
