import { FC } from "react";
import { Permission, useAuth } from "../../hooks/useAuth";
import { Stack, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router";

export const TermsPage: FC = () => {
  useAuth(Permission.ANYONE);
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/dull_bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
        alignItems: "center",
        pt: 6,
        pb: 6,
      }}
    >
      <Stack width="100%" maxWidth={600} mb={2}>
        <ChevronLeft
          sx={{
            cursor: "pointer",
            fontSize: 16,
            color: "white",
            alignSelf: "flex-start",
            ml: 2,
          }}
          onClick={() => navigate(-1)}
        />
      </Stack>
      <Stack
        borderRadius={4}
        flexDirection={"column"}
        sx={{
          backgroundColor: "white",
          width: "calc(100% - 8px - 8px)",
          flex: 1,
          maxWidth: 600,
          overflowY: "auto",
          p: 2,
        }}
      >
        <Typography
          variant="headlineMedium"
          color="#22171E"
          mb={2}
          textAlign="center"
        >
          Delulu Terms & Conditions
        </Typography>
        <Typography
          variant="bodySmall"
          whiteSpace="pre-line"
          textAlign="justify"
        >
          {`Welcome to Delulu's Terms and Conditions of Use (these “Terms”). This is a contract between you and Path Systems Sdn Bhd (as defined further below) and we want you to know yours and our rights before you use the Delulu website or application (“Delulu” or the “App”). Please take a few moments to read these Terms before enjoying the App, because once you access, view or use the App, you are going to be legally bound by these Terms (so probably best to read them first!).

Please be aware that if you subscribe to services for a term (the “Initial Term”), then the terms of your subscription will be automatically renewed for additional periods of the same duration as the Initial Term at Delulu then-current fee for such services, unless you cancel your subscription in accordance with Section 5 below.

1. Delulu RULES

Before you can use the App, you will need to register for an account (“Account”). In order to create an Account you must:
- be at least 18 years old or the age of majority to legally enter into a contract under the laws of your home country; and
- be legally permitted to use the App by the laws of your home country.

You can create an Account via manual registration, or by using your Facebook login details. If you create an Account using your Facebook login details, you authorise us to access, display and use certain information from your Facebook account (e.g. profile pictures, relationship status, location and information about Facebook friends). For more information about what information we use and how we use it, please check out our Privacy Policy.

Unfortunately, we cannot allow you to use another person’s Account or to share your Account with any other person without permission.

You’ll have great fun on Delulu, but if you feel the need to leave, you can delete your Account at any time by going to the ‘Settings’ page when you are logged in and clicking on the ‘Delete account’ link. Your Account will be deleted immediately but it may take a little while for Your Content (defined below) to be completely removed from the App. Your profile information will be treated in accordance with our Privacy Policy. If you delete your Account and try to create a new account within this time period using the same credentials, we will re-activate your Account for you.

We use a combination of automated systems and a team of moderators to monitor and review accounts and messages for content that indicates breaches of these Terms. We reserve the right at our sole discretion to terminate or suspend any Account, restrict access to the App, or make use of any operational, technological, legal or other means available to enforce the Terms (including without limitation blocking specific IP addresses), at any time without liability and without the need to give you prior notice. Without limiting the foregoing in any way, we expressly reserve the right to terminate or suspend your Account without notice (1) for violating these terms, (2) due to your conduct on the App, or your conduct with other users of the App (including your “offline” conduct), if we, in our sole discretion, determine your conduct was inappropriate or improper, (3) if we or our affiliates, in our or their sole discretion, determine your conduct on other apps operated by our affiliates was inappropriate or improper, or (4) for any reasons whatsoever that we deem, in our sole discretion, justifies termination. If your Account is terminated or suspended, you agree you will not receive a refund for any paid service or features you have already been charged for.

You may not access, tamper with, or use non-public areas of the App or our systems. Certain portions of the App may not be accessible if you have not registered for an Account.
​
2. TYPES OF CONTENT

There are three types of content that you will be able to access on the App:
- content that you upload and provide (“Your Content”);
- content that members provide (“Member Content”); and
- content that Path Systems Sdn Bhd provides (including, without limitation, database(s) and/or software) (“Our Content”).

There is certain content we can’t allow on Delulu. We want our users to be able express themselves as much as possible and post all sorts of things on Delulu, but we have to impose restrictions on certain content which:
- contains language or imagery which could be deemed offensive or is likely to harass, upset, embarrass, alarm or annoy any other person (including for example and without limitation, language that could be deemed discriminatory towards an individual’s race, colour, ethnicity, national origin, religion, disability, sexual orientation, gender expression, gender identity or physical appearance);
- is obscene, pornographic, violent or otherwise may offend human dignity (including for example and without limitation, language that could be deemed discriminatory towards an individual’s race, colour, ethnicity, national origin, religion, disability, sexual orientation, gender expression, gender identity or physical appearance);
- is abusive, insulting or threatening, discriminatory or which promotes or encourages racism, sexism, hatred or bigotry (including for example and without limitation, language that could be deemed discriminatory towards an individual’s race, colour, ethnicity, national origin, religion, disability, sexual orientation, gender expression, gender identity or physical appearance);
- encourages any illegal activity including, without limitation, terrorism, inciting racial hatred or the submission of which in itself constitutes committing a criminal offence;
- is defamatory or libellous;
- relates to commercial activities (including, without limitation, sales, competitions and advertising, links to other websites or premium line telephone numbers);
- involves the transmission of “junk” mail or “spam”;
- contains any spyware, adware, viruses, corrupt files, worm programmes or other malicious code designed to interrupt, damage or limit the functionality of or disrupt any software, hardware, telecommunications, networks, servers or other equipment, Trojan horse or any other material designed to damage, interfere with, wrongly intercept or expropriate any data or personal information whether from Delulu or otherwise;
- itself, or the posting of which, infringes any third party’s rights (including, without limitation, intellectual property rights and privacy rights);
- shows another person which was created or distributed without that person’s consent;
- is harmful to minors; or
- impersonates any other person, including falsely stating your name.

Path Systems Sdn Bhd operates a zero-tolerance policy for this kind of content.

Your Content

You agree that Your Content must comply with our Guidelines below, as updated from time to time. As Your Content is unique, you are responsible and liable for Your Content and will indemnify, defend, release, and hold us harmless from any claims made in connection with Your Content. Sorry that was a bit of a mouthful, but you are what you post!

You may not display any personal contact or banking information on your individual profile page whether in relation to you or any other person (for example, names, home addresses or postcodes, telephone numbers, email addresses, URLs, credit/debit card or other banking details). If you do choose to reveal any personal information about yourself to other users, whether via email or otherwise, it is at your own risk. We encourage you to use the same caution in disclosing details about yourself to third parties online as you would under any other circumstances.

As Delulu is a public community, Your Content will be visible to other users of the App all around the world instantly - so make sure you are comfortable sharing Your Content before you post. As such, you agree that Your Content may be viewed by other users and any person visiting, participating in or who is sent a link to the App (e.g. individuals who receive a link to a user’s profile or shared content from other Delulu Users). By uploading Your Content on Delulu, you represent and warrant to us that you have all necessary rights and licences to do so, and automatically grant us a non-exclusive, royalty free, perpetual, worldwide licence to use Your Content in any way (including, without limitation, editing, copying, modifying, adapting, translating, reformatting, creating derivative works from, incorporating into other works, advertising, distributing and otherwise making available to the general public such Content, whether in whole or in part and in any format or medium currently known or developed in the future).

We may assign and/or sub-license the above licence to our affiliates and successors without any further approval by you.

We do not have any obligation to store Your Content - if it’s important, you should make a copy.

So that we can prevent the unconsented use of Your Content by other members or third parties outside of Delulu, you authorise us to act on your behalf with respect to such infringing and/or unauthorised uses. This expressly includes the authority, but not the obligation, for us to send takedown notices on your behalf if Your Content is taken and used by third parties outside of Delulu.

Guidelines

Upload only your own photos

We take copyrights very seriously. If you don’t own the rights to a photo or video, please don’t post it. Same goes for dogs. If the dog isn’t yours-- don’t post it…(only joking!)

Respect all users

We’re a very diverse community. This means you should respect other people’s beliefs, interests and property while on Delulu. Delulu takes a strong stance against hate speech, rude or abusive behaviour, bullying, and misogyny. You should behave the same way on Delulu as you would in real life. Additionally we encourage all of our users to report anyone who does not follow these behavioural guidelines. As a community rooted in kindness and respect, we expect all of our users to respect each other, themselves, and the Delulu staff.

Send original messages to other users

We strongly advise against copying and pasting the same message to every connection. We encourage our users to read profiles, learn about other people’s interests, and send an appropriate, relevant message to each match. We promise this will increase your chances of engaging in interesting conversations.

Don’t pretend you’re someone you’re not

As previously stated, do not post photos that are not of you. We also recommend using our photo verification tool to let other users know that your profile is legit.

Delulu photo guidelines:
- No kids on their own. They must be in the photo with an adult, and fully clothed.
- No photos in bikinis/swimwear indoors.
- No pictures in underwear.
- No Shirtless/underwear Mirror Selfies.
- Face must be clearly visible in all photos.
- No watermarks or text overlaid.
- No pornographic material.
- No graphic hunting photos.
- No guns.

Don’t do anything illegal on our site

Illegal activity will not be tolerated on Delulu . This may result in being banned or restricted from the site, and/or being reported to the authorities.

No soliciting

Delulu isn’t for selling things. If you try to use it as a marketplace, you’ll be banned.

No kids on their own, they must be in the photo with an adult & fully clothed

Delulu is for 18+ years, therefore we don’t allow kids to be alone in photos as representatives of their parents on Delulu . In addition, all children must be completely clothed. This is safer for both children and their parents.

We use a combination of automated systems and a team of moderators to monitor and review accounts and messages for content that indicates breaches of these Guidelines and our Terms and Conditions. If you don’t follow these guidelines, you’ll receive a warning (unless our team decides to block or restrict access without warning at our discretion). If you ignore this warning, you risk losing your account. These guidelines are designed to make Delulu a friendly and safe place for all our users.
​
Member Content

Other members of Delulu will also share content via the App. Member Content belongs to the user who posted the content and is stored on our servers and displayed via the App at the direction of the user providing the Member Content.

You do not have any rights in relation to other users’ Member Content, and you may only use other Delulu users’ personal information to the extent that your use of it matches Delulu purpose of allowing people to meet one another. You may not use other users’ information for commercial purposes, to spam, to harass, stalk or to make unlawful threats. We reserve the right to terminate your Account if you misuse other users’ information.

Member Content is subject to the terms and conditions of Sections 512(c) and/or 512(d) of the Digital Millennium Copyright Act 1998. If you have a complaint about Member Content, please see the Digital Millennium Copyright Act section below for more information.

Our Content

You may be wondering what happens to the rest of the Content on Delulu. Well, it belongs to us! Any other text, content, graphics, user interfaces, trademarks, logos, sounds, artwork, and other intellectual property appearing on Delulu, as well as the Delulu software and database(s), are owned, controlled or licensed by us and are protected by copyright, trademark, data, database rights and/or other intellectual property law rights. All right, title and interest in and to Our Content remains with us at all times.

We grant you a non-exclusive, limited, personal, non-transferable, revocable, licence to access and use Our Content, without the right to sublicense, under the following conditions:
- you shall not use, sell, modify, or distribute Our Content except as permitted by the functionality of the App;
- you shall not use our name in metatags, keywords and/or hidden text;
- you shall not create derivative works from Our Content or scrape, disable, decompile, analyse or in any way commercially exploit Our Content, in whole or in part, in any way; and
- you shall use Our Content for lawful purposes only.

We reserve all other rights.

No Obligation to Pre-Screen Content.

Since Delulu is an online community, we generally try to avoid getting in the way and therefore don’t assume any obligation to pre-screen any of Your Content or any Member Content. However, there may be times where we need to step in, and we reserve the right to review, pre-screen, refuse and/or remove any Member Content and Your Content, including content exchanged between users in direct messages.

3. RESTRICTIONS ON THE APP

You agree to:
- comply with all applicable laws, including without limitation, privacy laws, intellectual property laws, anti-spam laws, equal opportunity laws and regulatory requirements;
- use your real name and real age in creating your Delulu account and on your profile; and
- use the services in a professional manner.
​
You agree that you will not:
- act in an unlawful or unprofessional manner including being dishonest, abusive or discriminatory;
- misrepresent your identity, your age, your current or previous positions, qualifications or affiliations with a person or entity;
- disclose information that you do not have the consent to disclose;
- stalk or harass any other user of the App;
- create or operate a pyramid scheme, fraud or other similar practice, or;
- develop, support or use software, devices, scripts, robots, other types of mobile code or any other means or processes (including crawlers, browser plugins and add-on or other technology) to scrape or otherwise exfiltrate from Delulu or its services, or otherwise copy profiles and other data from the services.

We don’t like users misbehaving in the Delulu community. You can report any abuse or complain about Member Content by contacting us, outlining the abuse and/or complaint. You can also report a user directly from a profile or in chat by clicking the ‘Block & Report’ link. We reserve the right to investigate any possible violations of these Terms, any Delulu user’s rights, or any third party rights and we may, in our sole discretion, immediately terminate any user’s right to use of the App without prior notice, as set out further in Section 1 above, and/or remove any improper, infringing or otherwise unauthorised Member Content submitted to the App.

We don’t control any of the things our users say or do, so you are solely responsible for your interactions with other users of the App.

YOU UNDERSTAND THAT Path Systems Sdn Bhd DOES NOT CURRENTLY CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS USERS. DELULU LABS ALSO DOES NOT INQUIRE INTO THE BACKGROUNDS OF ITS USERS OR ATTEMPT TO VERIFY THE STATEMENTS OF ITS USERS. Path Systems Sdn Bhd MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF ANY USERS OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS. Path Systems Sdn Bhd RESERVES THE RIGHT TO CONDUCT ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTRATION SEARCHES) AT ANY TIME AND TO USE AVAILABLE PUBLIC RECORDS FOR ANY PURPOSE.

You agree to, and hereby do, release Path Systems Sdn Bhd and its successors from any claims, demands, losses, damages, rights, and actions of any kind, including personal injuries, death and property damage, that either directly or indirectly arises from your interactions with or conduct of other users of the App. 

Scraping or replicating any part of the App without our prior consent is expressly prohibited. This includes by any means (automated or otherwise) other than through our currently available, published interfaces - unless you have been specifically allowed to do so in a separate agreement with us.

4. PRIVACY

For information about how Path Systems Sdn Bhd collects, uses, and shares your personal data, please check out our Privacy Policy. By using Delulu, you acknowledge that we may use such data in accordance with our Privacy Policy.

5. PAYMENT TERMS

Generally, Delulu may offer products and services for purchase on the App (“In-App Purchase”). If you choose to make an In-App Purchase, you acknowledge and agree that additional terms, disclosed to you at the point of purchase, may apply, and that such additional terms are incorporated herein by reference.

You may make an In-App Purchase through the following payment methods (“Payment Method”): (a) making a purchase through a third-party platform such as the Apple App Store and Google Play Store (“Third Party Store”), or (b) paying with your credit card, debit card, or PayPal account, which will be processed by a third-party processor. Once you have made an In-App Purchase, you authorise us to charge your chosen Payment Method. If payment is not received by us from your chosen Payment Method, you agree to promptly pay all amounts due upon demand by us.

Subscriptions and Auto-Renewal: Delulu may offer some services as automatically-renewing subscriptions, e.g., a one-week subscription, one-month subscription, or three-month subscription (“Premium Services”). IF YOU PURCHASE AN AUTOMATICALLY RENEWING SUBSCRIPTION, YOUR SUBSCRIPTION WILL RENEW AT THE END OF THE PERIOD, UNLESS YOU CANCEL, AT DELULU'S THEN-CURRENT PRICE FOR SUCH SUBSCRIPTIONS. To avoid charges for a new subscription period, you must cancel, as described below, before the end of the then-current subscription period. Deleting your account or deleting the application from your device does not cancel your subscription. You will be given notice of changes in the pricing of the Premium Services to which you have subscribed and an opportunity to cancel. If Delulu changes these prices and you do not cancel your subscription, you agree that you will be charged at Delulu's then-current pricing for subscription.

Cancelling Subscriptions. If you purchased a subscription directly from Delulu, you may cancel or change your Payment Method via the payment settings option under your profile. If you purchased a subscription through a Third Party Store, such as the Apple App Store or the Google Play Store, you will need to access your account with that Third Party Store and follow instructions to change or cancel your subscription. If you cancel your subscription, you may use your subscription until the end of the period you last paid for, but (i) you will not (except as set forth in the subsection entitled “Refunds” below) be eligible for a prorated refund, (ii) your subscription will not be renewed when that period expires and (iii) you will then no longer be able to use the Premium Services or In-App Purchases enabled by your subscription.

Free Trials. If you sign up for a free trial and do not cancel, your trial may convert into a paid subscription and your Payment Method will be charged at the then-current price for such subscription. Once your free trial converts to a paid subscription, your paid subscription will continue to automatically renew at the end of each period, and your Payment Method will be charged, until you cancel. To avoid charges for a new subscription period, you must cancel before the end of the then-current subscription period or free trial period as described above. Deleting your account or deleting the application from your device does not cancel your free trial. If you have signed up for a free trial on Delulu through the Apple Store or Google Play Store previously, you will not be eligible for another free trial and you will then be automatically signed up to a subscription and charged as described in this paragraph.

Refunds. Generally, all charges for purchases are nonrefundable, and there are no refunds or credits for partially used periods.

Taxes.

The payments required under this Section 5 do not include any Sales Tax that may be due in connection with the Premium Services provided under these Terms. If Delulu determines it has a legal obligation to collect a Sales Tax from you in connection with these Terms, Delulu will collect such Sales Tax in addition to the payments required under this Section 5. If any Premium Services, or payments for any Premium Services, under these Terms are subject to any Sales Tax in any jurisdiction and you have not remitted the applicable Sales Tax to Delulu, you will be responsible for the payment of such Sales Tax and any related penalties or interest to the relevant tax authority. As used herein, “Sales Tax” means any sales or use tax and any other tax measured by sales proceeds that is the functional equivalent of a sales tax where the applicable taxing jurisdiction does not otherwise impose a sales or use tax.

6. VIRTUAL ITEMS

At times, you may be able to purchase a limited, personal, non-transferable, non-sublicensable, revocable licence to access special limited-use features from Delulu, referred to here as “Virtual Items.” You can only purchase Virtual Items through Delulu or Delulu's partners. Virtual Items represent a limited license right governed by this Agreement, and, except as otherwise prohibited by applicable law, no title or ownership in or to Virtual Items is being transferred or assigned to you. This Agreement, and your purchase of Virtual Items, does not constitute the sale of any rights in Virtual Items.

Any Virtual Item balance shown in your account does not constitute a real-world balance or reflect any stored value, but instead shows the extent of your licence to access Virtual Items. Virtual Items do not incur fees for non-use. Your licence in Virtual Items will terminate on the earlier of Delulu ceasing provision of services or your account closing or terminating. Delulu may also at times provide Virtual Items as bonuses to, or parts of, paid subscriptions for its services. Your ability to use Virtual Items you have access to in this manner may terminate at the end of each of your subscription periods and your access to Virtual Items may not “roll over” or accumulate through additional subscription periods. Your access to Virtual Items gained through subscriptions may also end if you cancel your subscription.

Delulu, in its sole discretion, reserves the right to charge fees for the right to access or use Virtual Items and/or may distribute Virtual Items with or without charge. Delulu may manage, regulate, control, modify, or eliminate Virtual Items at any time, including taking actions that may impact the perceived value or purchase price, if applicable, of any Virtual Items and Delulu shall have no liability to you for doing so. You shall not sell, redeem, or otherwise transfer Virtual Items to any person or entity. Virtual Items may only be redeemed through our Services.

ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH OUR SERVICES ARE FINAL AND NON-REFUNDABLE. YOU ACKNOWLEDGE THAT DELULU IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.

7. PUSH NOTIFICATIONS; LOCATION-BASED FEATURES

We may provide you with emails, text messages, push notifications, alerts and other messages related to the App and/or the Delulu services, such as enhancements, offers, products, events, and other promotions. After downloading the App, you will be asked to accept or deny push notifications/alerts. If you deny, you will not receive any push notifications/alerts. If you accept, push notifications/alerts will be automatically sent to you. If you no longer wish to receive push notifications/alerts from the App, you may opt out by changing your notification settings on your mobile device. With respect to other types of messaging or communications, such as emails, text messages, etc., you can unsubscribe or opt out by either following the specific instructions included in such communications, or by emailing us with your request at hello@delulu.fun.

The App may allow access to or make available opportunities for you to view certain content and receive other products, services and/or other materials based on your location. To make these opportunities available to you, the App will determine your location using one or more reference points, such as GPS, Bluetooth and/or software within your mobile device. If you have set your mobile device to disable GPS, Bluetooth or other location determining software or do not authorise the App to access your location data, you will not be able to access such location-specific content, products, services and materials. For more about how the App uses and retains your information, please read the Privacy Policy.

8. DISCLAIMER

THE APP, SITE, OUR CONTENT, AND MEMBER CONTENT ARE ALL PROVIDED TO YOU “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE DO NOT GUARANTEE THE COMPATIBILITY OF ANY MATCHES.

SHOULD APPLICABLE LAW NOT PERMIT THE FOREGOING EXCLUSION OF EXPRESS OR IMPLIED WARRANTIES, THEN WE GRANT THE MINIMUM EXPRESS OR IMPLIED WARRANTY REQUIRED BY APPLICABLE LAW. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, SHALL CREATE ANY WARRANTY, REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS SECTION.

ADDITIONALLY, WE DO NOT MAKE ANY WARRANTIES THAT THE APP OR SITE WILL BE UNINTERRUPTED, SECURE OR ERROR FREE OR THAT YOUR USE OF THE APP OR SITE WILL MEET YOUR EXPECTATIONS, OR THAT THE APP, SITE, OUR CONTENT, ANY MEMBER CONTENT, OR ANY PORTION THEREOF, IS CORRECT, ACCURATE, OR RELIABLE. YOUR USE OF THE APP OR SITE IS AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. Path Systems Sdn Bhd IS NOT RESPONSIBLE FOR THE CONDUCT OF ANY USER. Path Systems Sdn Bhd DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS.

9. LIMITATION OF LIABILITY

NEITHER US NOR ANY OWNER WILL BE LIABLE FOR ANY DAMAGES, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE, INCLUDING, WITHOUT LIMITATION, LOSS OF DATA, INCOME, PROFIT OR GOODWILL, LOSS OF OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES ARISING OUT OF YOUR ACCESS TO OR USE OF THE APP, SITE, OUR CONTENT, OR ANY MEMBER CONTENT, HOWEVER CAUSED, WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), PROPRIETARY RIGHTS INFRINGEMENT, PRODUCT LIABILITY OR OTHERWISE.

THE FOREGOING SHALL APPLY EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF YOU BECOME DISSATISFIED IN ANY WAY WITH THE APP OR SITE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP YOUR USE OF THE APP AND SITE.

YOU HEREBY WAIVE ANY AND ALL CLAIMS ARISING OUT OF YOUR USE OF THE APP OR SITE. BECAUSE SOME STATES DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN TYPES OF DAMAGES, THESE PROVISIONS MAY NOT APPLY TO YOU. IF ANY PORTION OF THIS LIMITATION ON LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON, THEN OUR AGGREGATE LIABILITY SHALL NOT EXCEED ONE HUNDRED DOLLARS ($100).

THE LIMITATION OF LIABILITY HEREIN IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN AND REFLECTS A FAIR ALLOCATION OF RISK. THE APP AND SITE WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS AND YOU AGREE THAT THE LIMITATIONS AND EXCLUSIONS OF LIABILITY, DISCLAIMERS AND EXCLUSIVE REMEDIES SPECIFIED HEREIN WILL SURVIVE EVEN IF FOUND TO HAVE FAILED IN THEIR ESSENTIAL PURPOSE. THE FOREGOING DOES NOT APPLY TO LIABILITY ARISING FROM ANY FRAUD OR FRAUDULENT MISREPRESENTATIONS, OR ANY OTHER LIABILITY THAT CANNOT BE LIMITED BY APPLICABLE LAW.

10. INDEMNITY

All the actions you make and information you post on Delulu remain your responsibility. Therefore, you agree to indemnify, defend, release, and hold us, and our partners, licensors, affiliates, contractors, officers, directors, employees, representatives and agents, harmless, from and against any third party claims, damages (actual and/or consequential), actions, proceedings, demands, losses, liabilities, costs and expenses (including reasonable legal fees) suffered or reasonably incurred by us arising as a result of, or in connection with:
- any negligent acts, omissions or wilful misconduct by you;
- your access to and use of the App;
- the uploading or submission of Content to the App by you;
- any breach of these Terms by you; and/or
- your violation of any law or of any rights of any third party.

We retain the exclusive right to settle, compromise and pay any and all claims or causes of action which are brought against us without your prior consent. If we ask, you will co-operate fully and reasonably as required by us in the defence of any relevant claim.

The foregoing provision does not require you to indemnify Path Systems Sdn Bhd for any unconscionable commercial practice or any fraud, deception, false promise, misrepresentation or concealment, suppression or omission of any material fact in connection with the App.

11. PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT

If you believe any content on Delulu infringes the copyright in a work that you own, please submit a notification alleging such infringement (“DMCA Takedown Notice”) to Path Systems Sdn Bhd’s Copyright Agent. The Takedown Notice must include the following:
- A physical or electronic signature of a person authorised to act on behalf of the owner of an exclusive right that is allegedly infringed;
- Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works;
- Identification of the material claimed to be infringing or to be the subject of infringing activity and that is to be removed or access disabled and information reasonably sufficient to permit the service provider to locate the material;
- Information reasonably sufficient to permit the service provider to contact you, such as an address, telephone number, and, if available, an electronic mail;
- A statement that you have a good faith belief that use of the material in the manner complained of is not authorised by the copyright owner, its agent, or the law; and
- A statement that, under penalty of perjury, the information in the notification is accurate and you are authorised to act on behalf of the owner of the exclusive right that is allegedly infringed.
- Takedown Notices should be sent to hello@delulu.fun.

12. THIRD PARTY APP STORE

The following additional terms and conditions apply to you if you download the App from a Third Party Store. To the extent that the other terms and conditions of these Terms are less restrictive than, or otherwise conflict with, the terms and conditions of this Section, the more restrictive or conflicting terms and conditions in this Section will apply, but solely with respect to the App and the Third Party Store. You acknowledge and agree that:
- These Terms are concluded solely between you and Path Systems Sdn Bhd and not with the providers of the Third Party Store, and Path Systems Sdn Bhd (and not the Third Party Store providers) is solely responsible for the App and the content thereof. To the extent that these Terms provide for usage rules for the App which are less restrictive or in conflict with the applicable terms of service of the Third Party Store from which you obtain the App, the more restrictive or conflicting term of the Third Party Store will take precedence and will apply.
- The Third Party Store provider has no obligation whatsoever to provide any maintenance and support services with respect to the App. Path Systems Sdn Bhd is solely responsible for any product warranties, whether express or implied by law, to the extent not effectively disclaimed. The Third Party Store provider will have no warranty obligation whatsoever with respect to the App, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Path Systems Sdn Bhd.
- Path Systems Sdn Bhd, not the Third Party Store provider, is responsible for addressing any claims you or any third party may have relating to the App or your possession and/or use of the App, including, but not limited to: (i) product liability claims; (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; (iii) claims arising under consumer protection or similar legislation; and/or (iv) intellectual property infringement claims.
- The Third Party Store provider and its subsidiaries are third party beneficiaries of these Terms, and, upon your acceptance of these Terms, the Third Party Store provider from whom you obtained the App will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary thereof.

In the event of a conflict between a Third Party Store’s or mobile carrier’s applicable terms and conditions and these Terms, the terms and conditions of the Third Party Store or mobile carrier shall govern and control. We are not responsible and have no liability whatsoever for third-party goods or services you obtain through a Third Party Store or mobile carrier. We encourage you to make whatever investigation you feel necessary or appropriate before proceeding with any online transaction with any of these third parties.

13. TERMINATION AND REMEDIES

These Terms commence on the date you accept them (as described in the preamble) and continue until terminated in accordance with the terms herein.

If you want to terminate these Terms, you may do so by (a) notifying Path Systems Sdn Bhd at any time, and (b) closing your Account. Your notice should be sent to Path Systems Sdn Bhd’s address below. Please note that if you terminate these Terms, your subscription will continue until the end of the subscription period for which applicable fees have been paid, and you will not be entitled to a refund except as stated in Section 5.

Path Systems Sdn Bhd may terminate or suspend these Terms, including your Account, if you breach these Terms or if Path Systems Sdn Bhd is required to do so by applicable law. You agree that all terminations for cause shall be made in Path Systems Sdn Bhd’s sole discretion and that Path Systems Sdn Bhd shall not be liable to you or any third party for any termination of your Account.

In the event that Path Systems Sdn Bhd determines, in its sole discretion, that you have breached any portion of these Terms, or have otherwise demonstrated conducted inappropriate for the App, Path Systems Sdn Bhd reserves the right to: (a) warn you via email (to any email addresses you have provided to Path Systems Sdn Bhd) that you have violated the Terms; (b) delete your User Content; (c) discontinue your Account; (d) discontinue your subscription(s); (e) notify and/or send your User Content to and/or fully cooperate with the proper law enforcement authorities for further action; and/or (f) pursue to any other action which Path Systems Sdn Bhd deems to be appropriate.

Termination of these Terms or your Account includes the removal of access to your Account, and all related information and content associated with or inside your Account.

All provisions of these Terms, which by their nature should survive, shall survive termination of these Terms, including, without limitation, the Arbitration Agreement, ownership provisions, warranty disclaimers and limitation of liability.

14. MISCELLANEOUS

There are a few more things we need to mention before you can use Delulu.

These Terms, which we may amend from time to time, constitute the entire agreement between you and Path Systems Sdn Bhd. The Terms supersede all previous agreements, representations and arrangements between us (written or oral), excluding the Privacy Policy. Nothing in this clause shall limit or exclude any liability for fraudulent misrepresentation.

Path Systems Sdn Bhd has taken reasonable steps to ensure the currency, availability, correctness and completeness of the information contained on Delulu and provides that information on an "as is", "as available" basis. Path Systems Sdn Bhd does not give or make any warranty or representation of any kind about the information contained on Delulu, whether express or implied. Use of Delulu and the materials available on it is at your sole risk. Path Systems Sdn Bhd is not responsible for any loss arising from the transmission, use of data, or inaccurate Member Content.

You are responsible for taking all necessary precautions to ensure that any material you may obtain from Delulu is free of viruses or other harmful components. You accept that Delulu will not be provided uninterrupted or error free, that defects may not be corrected or that Path Systems Sdn Bhd, or the server that makes it available, are free of viruses or bugs, spyware, Trojan horse or any similar malicious software. Path Systems Sdn Bhd is not responsible for any damage to your computer hardware, computer software, or other equipment or technology including, but without limitation damage from any security breach or from any virus, bugs, tampering, fraud, error, omission, interruption, defect, delay in operation or transmission, computer line or network failure or any other technical or other malfunction.

The communications between you and Path Systems Sdn Bhd may take place via electronic means, whether you use the App or send Path Systems Sdn Bhd emails, or whether Path Systems Sdn Bhd posts notices in the App or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Path Systems Sdn Bhd in electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Path Systems Sdn Bhd provides to you electronically satisfy if it were to be in writing. The foregoing does not affect your statutory rights.

We know our Terms are awesome, but we may have to change them now and again

As Delulu grows, we might have to make changes to these Terms so we reserve the right to modify, amend or change the Terms at any time (a “Change”). If we do this then the Changes will be posted on this page and we will indicate the Effective Date of the updates at the bottom of the Terms. In certain circumstances, we may send an email to you notifying you of a Change. It’s also possible that we might ask you to agree to our Changes, but we’ll let you know. You should regularly check this page for notice of any Changes – we want our users to be as informed as possible.

Your continued use of Delulu following any Change constitutes your acceptance of the Change and you will be legally bound by the new updated Terms. If you do not accept any Changes to the Terms, you should stop using Delulu immediately (uh oh, that’s going to be hard!).

Additional items:

If, for any reason, any of the Terms are declared illegal, invalid or otherwise unenforceable by a court of a competent jurisdiction, then to the extent that term is illegal, invalid or unenforceable, it shall be severed and deleted from the Terms and the remainder of the Terms shall survive, remain in full force and effect and continue to be binding and enforceable.

No failure or delay in exercising any right, power or privilege under the Terms shall operate as a waiver of such right or acceptance of any variation of the Terms and nor shall any single or partial exercise by either party of any right, power or privilege preclude any further exercise of the right or the exercise of any other right, power or privilege.

You represent and warrant that:
- you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and
- you are not listed on any U.S. Government list of prohibited or restricted parties.

By using the App, you agree and acknowledge that Delulu is a global app operating through servers located in a number of countries around the world, including the United States. If you live in a country with data protection laws, the storage of your personal data may not provide you with the same protections as you enjoy in your country of residence. By submitting your personal information, or by choosing to upgrade the services you use, or by making use of the applications available on Delulu, you agree to the transfer of your personal information to, and storage and processing of your personal information in, any such countries and destinations.

The App may contain links to third-party websites or resources. In such cases, you acknowledge and agree that we are not responsible or liable for:
- the availability or accuracy of such websites or resources; or
- the content, products, or services on or available from such websites or resources.

Links to such websites or resources do not imply any endorsement. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources. Framing, in-line linking or other methods of association with the App are expressly prohibited without first obtaining our prior written approval.

These Terms, and any rights and licences granted hereunder, may not be transferred or assigned by you, but may be assigned by us without restriction.

In the event there is a discrepancy between this English language version and any translated copies of the Terms, the English version shall prevail.

If you have any questions, complaints or claims with respect to the App, please contact us at hello@delulu.fun.

15. GOVERNING LAW AND FORUM.

Subject to Section 13(3), your access to the App, Our Content, and any Member Content, any claims arising from or related to your relationship with Path Systems Sdn Bhd, and these Terms are governed and interpreted by the laws of Hong Kong. You agree that the courts shall have personal jurisdiction and venue and waive any objection based on inconvenient forum. You agree that you will not file or participate in a class action against us.

16. Path Systems Sdn Bhd.

The Terms constitute a binding legal agreement between you as user (“you”) and Path Systems Sdn Bhd (“we” or “us”). 

Effective date
The Terms were last updated on: 6th October, 2023.`}
        </Typography>
      </Stack>
    </Stack>
  );
};
