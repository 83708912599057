export const lookingForOptions = [
  {
    value: "Friends",
    label: "Friends",
  },
  {
    value: "Maybe more than friends",
    label: "Maybe more than friends",
  },
  {
    value: "Just curious",
    label: "Just curious",
  },
  {
    value: "Wingman my friends",
    label: "Wingman my friends",
  },
];
