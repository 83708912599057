import { FC } from "react";
import { Modal, Typography, Stack } from "@mui/material";
import { CustomButton } from "../common/CustomButton";
import { Close } from "@mui/icons-material";

export const GiftModal: FC<{
  open: boolean;
  setOpen: (v: boolean) => void;
  setOpenGiftConfirm: (v: boolean) => void;
  count: number;
  setCount: (v: number) => void;
  loading: boolean;
}> = ({ open, setOpen, setOpenGiftConfirm, count, setCount, loading }) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(3px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        direction="column"
        sx={{
          borderRadius: "16px",
          background: "white",
          position: "relative",
          cursor: "auto",
          alignItems: "center",
          pl: 6,
          pr: 6,
          pb: 3,
          mt: 3,
          overflow: "visible",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Close
          sx={{
            width: 24,
            height: 24,
            cursor: "pointer",
            color: "black",
            WebkitTapHighlightColor: "rgba(0,0,0,0)",
            position: "absolute",
            top: -24,
            right: -24,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
        />
        <img
          src="/gift_big.svg"
          alt="gift"
          width={140}
          height={140}
          style={{
            objectFit: "contain",
            width: 140,
            height: 140,
            marginTop: -48,
            pointerEvents: "none",
            marginBottom: 16,
          }}
        />
        <Typography variant="headlineMedium" mb={2} textAlign="center">
          Gifting
        </Typography>
        <img
          src="/short_line.svg"
          alt="short_line"
          style={{
            width: 100,
            height: 12,
            objectFit: "cover",
            pointerEvents: "none",
            marginBottom: 24,
          }}
        />
        <Typography
          textAlign="center"
          variant="headlineSmall"
          color="#22171E"
          mb={2}
        >
          Sending gifts is a <br />
          wonderful moment
        </Typography>
        <img
          src="/gift_big.svg"
          alt="gift"
          width={60}
          height={60}
          style={{
            objectFit: "contain",
            width: 60,
            height: 60,
            pointerEvents: "none",
            marginBottom: 20,
          }}
        />

        <Stack direction="row" alignItems="center" gap={2} mb={3}>
          <CustomButton
            text="-"
            disabled={count <= 0}
            onClick={() => {
              setCount(count - 1);
            }}
            sx={{
              p: 0,
              width: 36,
              height: 36,
              minWidth: 36,
            }}
          />
          <Typography variant="headlineMedium" color="#22171E">
            {count}
          </Typography>
          <CustomButton
            text="+"
            onClick={() => {
              setCount(count + 1);
            }}
            sx={{
              p: 0,
              width: 36,
              height: 36,
              minWidth: 36,
            }}
          />
        </Stack>
        <img
          src="/short_line.svg"
          alt="short_line"
          style={{
            width: 100,
            height: 12,
            objectFit: "cover",
            pointerEvents: "none",
            transform: "rotateX(180deg)",
            marginBottom: 16,
          }}
        />
        <CustomButton
          text={loading ? "Processing" : "Send a gift now"}
          loading={loading}
          disabled={count <= 0}
          onClick={() => {
            setOpen(false);
            setOpenGiftConfirm(true);
          }}
        />
      </Stack>
    </Modal>
  );
};
