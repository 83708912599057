import API from "./api";
import { createPost } from "./post";
import { updateUser } from "./user";

const MAX_RETRIES = 6;
const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const getIsConnectedIg = async () => {
  try {
    const resp = await API.get(`/user-socials`);
    return !!resp.data?.data?.[0]?.token;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const generateIgLink = async () => {
  const resp = await API.get(
    `/user-socials/oauth?type=INSTAGRAM&callbackUrl=${process.env.REACT_APP_APP_URL}/socialGateway`
  );
  return resp.data?.data?.link;
};

export const connectIg = async (code: string, state: string) => {
  const resp = await API.post("/user-socials", {
    data: {
      callbackUrl: `${process.env.REACT_APP_APP_URL}/socialGateway`,
      state: `${state}`,
      code: `${code}`,
      type: "INSTAGRAM",
    },
  });
  return resp.data;
};

const fetchIgDataWithRetries = async (
  id: number,
  retryCount: number
): Promise<any> => {
  try {
    const response = await API.get(`/user-socials/${id}/socialInfo`);

    if (!response.data?.data) {
      if (retryCount < MAX_RETRIES) {
        await sleep(500);
        return await fetchIgDataWithRetries(id, retryCount + 1);
      } else {
        throw new Error("Max retries exceeded");
      }
    } else {
      return response;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const createIgPosts = async (uid: string) => {
  const userSocialsResp = await API.get("/user-socials");
  const userSocial = userSocialsResp?.data?.data?.[0];
  if (!userSocial) throw new Error("User social not found.");
  const getInfoResp = await fetchIgDataWithRetries(userSocial?.id, 0);
  if (!getInfoResp.data?.data)
    throw new Error("Instagram linking error, please try again later.");
  const igUserInfo = getInfoResp.data.data ?? "";
  const igName = !!igUserInfo.full_name
    ? igUserInfo.full_name
    : userSocial.displayName ?? "";
  const igBio = igUserInfo.biography ?? "";
  const igAvatar = igUserInfo.profile_pic_url_hd ?? "";

  await updateUser(uid, {
    displayName: igName,
    avatar: igAvatar,
  });

  await createPost("BIO", {
    text: igBio,
  });

  let photos = igUserInfo.edge_owner_to_timeline_media.edges.filter((o: any) =>
    ["GraphVideo", "GraphImage"].includes(o.node?.__typename)
  );
  const photosCount = Math.min(7, photos.length);
  if (photosCount) {
    for (let i = 0; i < photosCount; i++) {
      const photo = photos[i].node.display_url;
      await createPost(`PHOTO${i + 1}`, undefined, photo);
    }
  }
  // avatar cannot be updated as the ig image link has cors
  // await updateUser(uid, {
  //   avatar: igAvatar,
  // });
};
