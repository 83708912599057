import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getOrder } from "../../api/payment";
import { getReward } from "../../api/reward";
import { getUser } from "../../api/user";
import { getFreeDrawWallet } from "../../api/wallet";
import { Permission, useAuth } from "../../hooks/useAuth";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { setUser } from "../../redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { delay } from "../../utility/time";
import { FreeDrawModal } from "../FreeDrawModal/FreeDrawModal";
import { PaymentSuccessModal } from "../PaymentSuccessModal/PaymentSuccessModal";
import { TopUpModal } from "../TopUpModal/TopUpModal";
import { ProfileFront } from "./ProfileFront";

let localLoading = false;

export const ProfilePage = () => {
  useAuth(Permission.USER_ONLY);
  const [openTopup, setOpenTopup] = useState(false);
  const [params] = useSearchParams();
  const [openTopupSuccess, setOpenTopupSuccess] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const [openFreeDraw, setOpenFreeDraw] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    localLoading = loading;
  }, [loading]);

  const checkOrder = async (orderUid: string) => {
    await delay(1);
    const newOrder = await getOrder(orderUid);
    if (
      newOrder?.status === "PAYMENT_FAILED" ||
      newOrder?.status === "CANCELLED" ||
      newOrder?.status === "ERROR"
    ) {
      setLoading(false);
      dispatch(
        setSnackbar({
          open: true,
          message: "Error processing order.",
          severity: "error",
        })
      );
    } else if (newOrder?.status === "COMPLETED") {
      setLoading(false);
      setOpenTopupSuccess(true);
      const newUser = await getUser(user?.uid ?? "");
      dispatch(setUser(newUser));
    }
    if (!localLoading) return;
    checkOrder(orderUid);
  };

  useEffect(() => {
    if (!user?.uid) return;
    const profileTopupOrderUid = localStorage.getItem("profileTopupOrderUid");
    if (!profileTopupOrderUid) return;
    localStorage.removeItem("profileTopupOrderUid");
    setLoading(true);
    // check if order comes thru, if so, popup modal
    checkOrder(profileTopupOrderUid);
  }, [user?.uid]);

  const { data: freeDraw } = useQuery({
    queryKey: ["freeDraw", user?.uid],
    queryFn: () => getFreeDrawWallet(),
    enabled: !!user?.uid,
  });

  const { data: reward } = useQuery({
    queryKey: ["reward", user?.uid],
    queryFn: () => getReward(),
    enabled: !!user?.uid,
  });

  useEffect(() => {
    const comingSoon = process.env.REACT_APP_COMING_SOON;
    const canPopFreeDraw = localStorage.getItem("canPopFreeDraw");
    if (
      (freeDraw?.balance ?? 0) > 0 &&
      comingSoon === "false" &&
      canPopFreeDraw === "true"
    ) {
      // if there is any free draw, don't show mission
      setOpenFreeDraw(true);
      localStorage.setItem("canPopFreeDraw", "false");
      return;
    }
  }, [reward, freeDraw]);

  useEffect(() => {
    const paymentSuccess = params.get("paymentSuccess");
    if (paymentSuccess === "true") {
      setOpenTopupSuccess(true);
    }
  }, [params]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/dull_bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        gap: 2,
        justifyContent: "center",
        pt: 6,
        pb: 13,
        height: "100dvh",
      }}
    >
      <ProfileFront
        openTopup={openTopup}
        setOpenTopup={setOpenTopup}
        loading={loading}
      />
      <TopUpModal
        open={openTopup}
        setOpen={setOpenTopup}
        isRequired={false}
        successUrl={`${process.env.REACT_APP_APP_URL}/profile`}
        from="profile"
      />
      <PaymentSuccessModal
        open={openTopupSuccess}
        setOpen={setOpenTopupSuccess}
      />
      <FreeDrawModal
        open={openFreeDraw}
        setOpen={setOpenFreeDraw}
        missions={reward?.missions ?? []}
      />
    </Stack>
  );
};
