import { ThemeOptions, createTheme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import React from "react";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    heroLarge: true;
    heroMedium: true;
    heroSmall: true;
    superscript: true;
    bodyMedium: true;
    bodySmall: true;
    bodyLarge: true;
    headlineLarge: true;
    headlineMedium: true;
    headlineMediumNoGradient: true;
    headlineSmall: true;
    notice: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  heroLarge: React.CSSProperties;
  heroMedium: React.CSSProperties;
  heroSmall: React.CSSProperties;
  superscript: React.CSSProperties;
  bodyMedium: React.CSSProperties;
  bodySmall: React.CSSProperties;
  bodyLarge: React.CSSProperties;
  headlineLarge: React.CSSProperties;
  headlineMedium: React.CSSProperties;
  headlineMediumNoGradient: React.CSSProperties;
  headlineSmall: React.CSSProperties;
  notice: React.CSSProperties;
}

export const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        bodyMedium: {
          color: "#22171E",
          fontSize: 14,
          fontWeight: 400,
          fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
          whiteSpace: "pre-line",
        },
        bodySmall: {
          color: "#1E1E1E",
          fontSize: 12,
          fontWeight: 400,
          fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
          whiteSpace: "pre-line",
        },
        headlineLarge: {
          fontSize: 32,
          fontWeight: 400,
          color: "white",
          fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
          lineHeight: "normal",
        },
        headlineMedium: {
          fontSize: 24,
          fontWeight: 400,
          color: "#22171E",
          fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
          lineHeight: 1.5,
        },
        headlineSmall: {
          fontSize: 16,
          fontWeight: 400,
          color: "#555B6E",
          fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
          lineHeight: "normal",
        },
        heroLarge: {
          fontSize: 60,
          fontWeight: 400,
          color: "white",
          fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
          lineHeight: "normal",
        },
        heroMedium: {
          fontSize: 48,
          fontWeight: 400,
          color: "#22171E",
          fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
        },
        heroSmall: {
          fontSize: 36,
          fontWeight: 400,
          color: "white",
          fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
        },
        superscript: {
          color: "#3B3F53",
          fontSize: 16,
          fontWeight: 700,
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        },
        bodyLarge: {
          fontSize: 18,
          fontWeight: 400,
          color: "#1E1E1E",
          fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
          lineHeight: 1.5,
        },
        headlineMediumNoGradient: {
          fontSize: 48,
          fontWeight: 700,
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        },
        notice: {
          color: "black",
          fontSize: 10,
          fontWeight: 300,
          whiteSpace: "pre-line",
          fontFamily: "RobotoCondensed, Roboto, Helvetica, Arial, sans-serif",
        },
      } as ExtendedTypographyOptions,
    },
  },
} as ThemeOptions);
