import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import gsap from "gsap";
import { Close } from "@mui/icons-material";

type Props = {
  open: boolean;
  setOpen: Function;
};

export const NotificationReminder: React.FC<Props> = ({ open, setOpen }) => {
  const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!backdrop || !container) return;
    if (open) {
      gsap.to(backdrop, {
        visibility: "visible",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: 0,
        overwrite: true,
        duration: 0.05,
      });
    } else {
      gsap.to(backdrop, {
        visibility: "hidden",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: "100%",
        overwrite: true,
        duration: 0.05,
      });
    }
  }, [open, backdrop, container]);

  return (
    <Stack
      direction="column"
      width="100vw"
      height="100%"
      sx={{
        position: "fixed",
        justifyContent: "center",
        alignItems: "center",
        top: 0,
        left: 0,
        zIndex: 100,
        visibility: "hidden",
      }}
      ref={(el) => setBackdrop(el)}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: 350,
          maxWidth: "90vw",
          transform: "translateY(100%)",
          borderRadius: "24px",
          background: "white",
          position: "relative",
          cursor: "auto",
          maxHeight: "90vh",
          overflowY: "scroll",
          boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.15)",
          alignItems: "center",
          justifyContent: "center",
          pl: 3,
          pr: 3,
          pt: 3,
          pb: 6,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={(el) => setContainer(el)}
      >
        <Stack direction="row" justifyContent="flex-end" width="100%" mb={1}>
          <Close
            sx={{
              width: 24,
              height: 24,
              cursor: "pointer",
              color: "#5C646B",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
          />
        </Stack>

        <Typography variant="bodyLarge" mb={3}>
          Enable Notifications
        </Typography>
        <Stack direction="column" gap={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="notice" fontSize={14}>
              Step 1:
            </Typography>
            <Typography variant="notice" fontSize={14}>
              Please head over to “Settings”
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="notice" fontSize={14}>
              Step 2:
            </Typography>
            <Typography variant="notice" fontSize={14}>
              Search “Delulu”
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="notice" fontSize={14}>
              Step 3:
            </Typography>
            <Typography variant="notice" fontSize={14}>
              Enable “Notification
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
