import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import gsap from "gsap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CustomButton } from "../common/CustomButton";
import { returnGift } from "../../api/gift";
import { setSnackbar } from "../../redux/features/snackbarSlice";

type Props = {
  open: boolean;
  setOpen: Function;
  amount: number;
  uid: string;
  name: string;
  sendTextMessage: Function;
};

export const ReturnGiftModal: React.FC<Props> = ({
  open,
  setOpen,
  amount,
  uid,
  name,
  sendTextMessage,
}) => {
  const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!backdrop || !container) return;
    if (open) {
      gsap.to(backdrop, {
        visibility: "visible",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: 0,
        overwrite: true,
        duration: 0.05,
      });
    } else {
      gsap.to(backdrop, {
        visibility: "hidden",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: "100%",
        overwrite: true,
        duration: 0.05,
      });
    }
  }, [open, backdrop, container]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        position: "fixed",
        justifyContent: "flex-end",
        top: 0,
        left: 0,
        zIndex: 10,
        alignItems: "center",
        backdropFilter: "blur(5px)",
        visibility: "hidden",
        height: "100dvh",
      }}
      ref={(el) => setBackdrop(el)}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "600px",
          transform: "translateY(100%)",
          borderRadius: "24px 24px 0px 0px",
          background: "white",
          position: "relative",
          cursor: "auto",
          overflowY: "scroll",
          p: 3,
          alignItems: "center",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={(el) => setContainer(el)}
      >
        <Typography variant="headlineMedium" mb={2} textAlign="center">
          Return Gift
        </Typography>
        <img
          src="/short_line.svg"
          alt="short_line"
          style={{
            width: 100,
            height: 12,
            objectFit: "cover",
            pointerEvents: "none",
            marginBottom: 16,
          }}
        />
        <Typography color="#1E1E1E" variant="bodyMedium" mb={2}>
          You&apos;re returning
        </Typography>
        <Stack
          direction="row"
          gap={2}
          mb={2}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="heroLarge" color="black">
            {amount}
          </Typography>
          <img
            src="/gift_big.svg"
            alt="gift"
            style={{
              width: 60,
              height: 60,
              objectFit: "contain",
            }}
          />
        </Stack>
        <Typography variant="bodyMedium" textAlign="center" mb={2}>
          to {name}
        </Typography>
        <CustomButton
          text="Confirm"
          loading={loading}
          onClick={async () => {
            try {
              await returnGift(uid);
              dispatch(
                setSnackbar({
                  open: true,
                  message: `You have returned the gift to ${name}`,
                  severity: "success",
                })
              );
              sendTextMessage("Returned gift", "GIFT", amount, uid, true);
              setOpen(false);
            } catch (err: any) {
              dispatch(
                setSnackbar({
                  open: true,
                  message:
                    err?.response?.data?.error?.message ??
                    "Error returning gift, please ensure that you have sufficient balance.",
                  severity: "error",
                })
              );
            }
          }}
          sx={{
            width: "100%",
            mb: 3,
          }}
        />
        <Typography
          sx={{
            cursor: "pointer",
          }}
          alignSelf="center"
          variant="notice"
          fontSize={14}
          fontWeight={400}
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Typography>
      </Stack>
    </Stack>
  );
};
