import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type MessageSuggestion = {
  suggestion: string;
};

const initialState = {
  suggestion: "",
} as MessageSuggestion;

export const messageSuggestion = createSlice({
  name: "suggestion",
  initialState,
  reducers: {
    resetMessageSuggestion: () => initialState,
    setMessageSuggestion: (state, action: PayloadAction<string>) => {
      state.suggestion = action.payload;
    },
  },
});

export const { resetMessageSuggestion, setMessageSuggestion } =
  messageSuggestion.actions;
export default messageSuggestion.reducer;
