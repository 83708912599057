import gsap from "gsap";
import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomButton } from "../common/CustomButton";
import { useQuery } from "@tanstack/react-query";
import { createCustomOrder, getProductCatalogs } from "../../api/payment";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSnackbar } from "../../redux/features/snackbarSlice";

export const CashGiftModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  giftCount: number;
  onComplete: () => void;
}> = ({ open, handleClose, giftCount, onComplete }) => {
  const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.appState);

  const { data: products } = useQuery({
    queryKey: ["products", "gift-cashout"],
    queryFn: () => getProductCatalogs("gift-cashout"),
    enabled: true,
  });

  const selectedProduct = (products || []).find(
    (a) => a.metadata?.currency === appState.currency
  );

  const balance = giftCount / (selectedProduct?.prices?.[0]?.rate || 0);

  useEffect(() => {
    if (!backdrop || !container) return;
    if (open) {
      gsap.to(backdrop, {
        visibility: "visible",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: 0,
        overwrite: true,
        duration: 0.05,
      });
    } else {
      gsap.to(backdrop, {
        visibility: "hidden",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: "100%",
        overwrite: true,
        duration: 0.05,
      });
    }
  }, [open, backdrop, container]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        position: "fixed",
        justifyContent: "flex-end",
        top: 0,
        left: 0,
        zIndex: 10,
        alignItems: "center",
        backdropFilter: "blur(5px)",
        visibility: "hidden",
        height: "100dvh",
      }}
      ref={(el) => setBackdrop(el)}
      onClick={(e) => {
        e.stopPropagation();
        handleClose();
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "600px",
          transform: "translateY(100%)",
          borderRadius: "24px 24px 0px 0px",
          background: "white",
          position: "relative",
          cursor: "auto",
          overflowY: "scroll",
          alignItems: "center",
          p: 3,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={(el) => setContainer(el)}
      >
        <Typography variant="headlineMedium" mb={2} textAlign="center">
          Cash Out
        </Typography>

        <img
          src="/sep.svg"
          width={118}
          alt="sep"
          style={{
            marginBottom: 16,
          }}
        />

        <Typography variant="bodyMedium" textAlign="center" mb={2}>
          You are cashing out
        </Typography>

        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
          mb={2}
        >
          <Typography variant="bodyLarge" fontSize={60}>
            {giftCount}
          </Typography>

          <img
            src="/gift.png"
            alt="gift"
            style={{
              width: 60,
              height: 60,
            }}
          />
        </Stack>

        <Stack flexDirection={"row"} gap={1} mb={2}>
          <img src="/left_line.svg" alt="left_line" />
          <Typography variant="bodySmall">to</Typography>

          <img src="/right_line.svg" alt="right_line" />
        </Stack>

        <Typography variant="bodyLarge" fontSize={36} mb={2}>
          {appState.currencySymbol} {balance.toFixed(2)}
        </Typography>

        <CustomButton
          text="Confirm"
          variant="tertiary"
          sx={{
            width: "100%",
            paddingY: 1,
            mb: 4,
          }}
          size="medium"
          loading={isLoading}
          disabled={(products || []).length === 0 || balance === 0}
          onClick={async () => {
            try {
              setIsLoading(true);
              await createCustomOrder(
                selectedProduct?.id ?? 0,
                selectedProduct?.prices?.[0]?.currency?.id ?? 0,
                balance
              );
              onComplete();
              handleClose();

              dispatch(
                setSnackbar({
                  open: true,
                  message: "Cash out successful.",
                  severity: "success",
                })
              );
            } catch (err) {
              dispatch(
                setSnackbar({
                  open: true,
                  message: "Cash out failed.",
                  severity: "error",
                })
              );
            } finally {
              setIsLoading(false);
            }
          }}
        />

        <Typography
          variant="caption"
          mb={2}
          textAlign="center"
          onClick={() => {
            handleClose();
          }}
          sx={{
            cursor: "pointer",
          }}
        >
          Close
        </Typography>
      </Stack>
    </Stack>
  );
};
