import { ReportReasonDto } from "../types/ReportReasonDto";
import API from "./api";

export const getReportReasons = async () => {
  const resp = await API.get(
    "/report-reasons?sort[0]=label:asc&pagination[page]=1&pagination[pageSize]=1000"
  );
  return (resp.data?.data ?? []) as ReportReasonDto[];
};

export const reportUser = async (
  reason: number,
  message: string,
  screenshot: string,
  reportee: string
) => {
  const resp = await API.post("/reports", {
    data: {
      reason,
      message,
      screenshot,
      reportee,
    },
  });
  return resp.data;
};
