import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Layout } from "./components/Layout/Layout";
import { Typography } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { Providers } from "./redux/provider";
import { CustomSnackbar } from "./components/common/CustomSnackbar";
import { LoginPage } from "./components/LoginPage/LoginPage";
import { MainPage } from "./components/MainPage/MainPage";
import { ConnectIgPage } from "./components/ConnectIgPage/ConnectIgPage";
import { GenderPage } from "./components/GenderPage/GenderPage";
import { LookingForPage } from "./components/LookingForPage/LookingForPage";
import { DobPage } from "./components/DobPage/DobPage";
import { InterestsPage } from "./components/InterestsPage/InterestsPage";
import { ConnectIgRedirectPage } from "./components/ConnectIgRedirectPage/ConnectIgRedirectPage";
import ChatPage from "./components/ChatPage/ChatPage";
import ChatRoomsPage from "./components/ChatRoomsPage/ChatRoomsPage";
import { ProfilePage } from "./components/ProfilePage/ProfilePage";
import ReferralPage from "./components/ReferralPage/ReferralPage";
import { ReferralIgPage } from "./components/ReferIgPage/ReferIgPage";
import { PaymentSuccess } from "./components/PaymentPage/PaymentSuccess";
import { PaymentFailure } from "./components/PaymentPage/PaymentFailure";
import { UniPage } from "./components/UniPage/UniPage";
import { ReferLandingPage } from "./components/ReferLandingPage/ReferLandingPage";
import { LocationPage } from "./components/LocationPage/LocationPage";
import DBService from "./services/DBService";
import { useCallback, useEffect } from "react";
import OneSignal from "react-onesignal";
import { SettingsPage } from "./components/SettingsPage/SettingsPage";
import { OtherProfilePage } from "./components/OtherProfilePage/OtherProfilePage";
import { ConnectIgMiddlePage } from "./components/ConnectIgMiddlePage/ConnectIgMiddlePage";
import { NamePage } from "./components/NamePage/NamePage";
import { AvatarPage } from "./components/AvatarPage/AvatarPage";
import { TermsPage } from "./components/TermsPage/TermsPage";
import { PrivacyPage } from "./components/PrivacyPage/PrivacyPage";
import { NotificationPage } from "./components/NotificationPage/NotificationPage";
import axios from "axios";
import { auth } from "./utility/firebase";
import ChatSocket from "./socket/chatSocket";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
});

function App() {
  const versionCheck = useCallback(async () => {
    const currentVersion = localStorage.getItem("version");
    const response = await axios.get<{
      version: string;
    }>("/version.json");
    const expectedVersion = response.data.version;

    if (currentVersion !== expectedVersion) {
      localStorage.setItem("version", expectedVersion);

      window.caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          window.caches.delete(cacheName);
        });
      });

      window.location.reload();
    }
  }, []);

  useEffect(() => {
    versionCheck();

    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "visible") {
        versionCheck();
      }
    });
  }, [versionCheck]);

  useEffect(() => {
    try {
      OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_ID ?? "",
        allowLocalhostAsSecureOrigin: true,
        autoResubscribe: true,
        autoRegister: true,
        serviceWorkerParam: { scope: "/push/" },
        serviceWorkerPath: "/push/OneSignalSDKWorker.js",
      });
    } catch (err) {
      console.error(err);
    }

    const query = new URLSearchParams(window.location.search);
    const referrer = query.get("ref");
    if (referrer) {
      localStorage.setItem("referrer", referrer);
    }
    DBService.Instance.init();
  }, []);

  auth.onAuthStateChanged((user) => {
    if (user) {
      ChatSocket.Instance.connect();
    } else {
      ChatSocket.Instance.disconnect();
    }
  });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={<Layout />}
        errorElement={<Typography>404 Page Not Found.</Typography>}
      >
        <Route index element={<MainPage />} />

        <Route path="/login" element={<LoginPage />} />
        <Route path="/instagram" element={<ConnectIgPage />} />
        <Route path="/gender" element={<GenderPage />} />
        <Route path="/looking_for" element={<LookingForPage />} />
        <Route path="/dob" element={<DobPage />} />
        <Route path="/interests" element={<InterestsPage />} />
        <Route path="/socialGateway" element={<ConnectIgRedirectPage />} />
        <Route path="/chat" element={<ChatRoomsPage />} />
        <Route path="/chat/:roomId" element={<ChatPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/referral" element={<ReferralPage />} />
        <Route path="/refer/ig" element={<ReferralIgPage />} />
        <Route path="/payment_success" element={<PaymentSuccess />} />
        <Route path="/payment_failure" element={<PaymentFailure />} />
        <Route path="/uni" element={<UniPage />} />
        <Route path="/refer/:nickname" element={<ReferLandingPage />} />
        <Route path="/location" element={<LocationPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/profile/:uid" element={<OtherProfilePage />} />
        <Route path="/ig" element={<ConnectIgMiddlePage />} />
        <Route path="/name" element={<NamePage />} />
        <Route path="/avatar" element={<AvatarPage />} />
        <Route path="/terms-conditions" element={<TermsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/notification" element={<NotificationPage />} />
      </Route>
    )
  );

  return (
    <Providers>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <CustomSnackbar />
      </QueryClientProvider>
    </Providers>
  );
}

export default App;
