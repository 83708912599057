import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useAppDispatch } from "../../redux/hooks";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { Typography } from "@mui/material";

export type Option = {
  value: number | string;
  label: string;
};

type Props = {
  placeholder: string;
  sx?: any;
  values: Option[];
  setValues: (v: Option[]) => void;
  options: Option[];
  max?: number;
  variant?: "outlined" | "normal";
};

export const CustomAutocomplete: React.FC<Props> = ({
  placeholder,
  sx,
  values,
  setValues,
  options,
  max,
  variant = "outlined",
}) => {
  const dispatch = useAppDispatch();

  if (variant === "normal") {
    return (
      <Stack
        direction="column"
        gap={0.5}
        width="100%"
        sx={sx}
        onClick={(e) => e.stopPropagation()}
      >
        <Typography variant="bodySmall" color="#1E1E1E">
          {placeholder}
        </Typography>
        <Autocomplete
          multiple
          options={options}
          getOptionLabel={(option) => option.label}
          defaultValue={values}
          value={values}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => {
            return option.value === value.value;
          }}
          onChange={(e, v) => {
            if (!!max && v.length > max) {
              dispatch(
                setSnackbar({
                  open: true,
                  message: `You can only select a maximum of ${max} items.`,
                  severity: "warning",
                })
              );
              return;
            }
            setValues(v);
          }}
          sx={{
            borderRadius: "8px",
            border: "1px solid rgba(0, 0, 0, 0.10)",
            minWidth: 200,
            "& fieldset": { border: "none" },
            "& input::placeholder": {
              fontSize: 14,
            },
            "& input": {
              fontSize: 14,
              fontWeight: 400,
              color: "#1E1E1E",
              fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
            },
            "& textarea": {
              fontSize: 14,
              fontWeight: 400,
              color: "#1E1E1E",
              fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label=""
              placeholder={placeholder}
            />
          )}
        />
      </Stack>
    );
  }

  return (
    <Stack spacing={3} sx={{ width: "100%", ...sx }}>
      <Autocomplete
        multiple
        options={options}
        getOptionLabel={(option) => option.label}
        defaultValue={values}
        value={values}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        onChange={(e, v) => {
          if (!!max && v.length > max) {
            dispatch(
              setSnackbar({
                open: true,
                message: `You can only select a maximum of ${max} items.`,
                severity: "warning",
              })
            );
            return;
          }
          setValues(v);
        }}
        sx={{
          borderRadius: "8px",
          border: "1px solid rgba(0, 0, 0, 0.10)",
          minWidth: 200,
          ...sx,
          "& fieldset": { border: "none" },
          "& input::placeholder": {
            fontSize: 14,
          },
          "& input": {
            fontSize: 14,
            fontWeight: 400,
            color: "#1E1E1E",
            fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
          },
          "& textarea": {
            fontSize: 14,
            fontWeight: 400,
            color: "#1E1E1E",
            fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label=""
            placeholder={placeholder}
          />
        )}
      />
    </Stack>
  );
};
