import { FC, useCallback, useEffect, useState } from "react";
import { Stack, Box, Typography, Avatar, Divider } from "@mui/material";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Permission, useAuth } from "../../hooks/useAuth";
import moment from "moment";
import { useNavigate } from "react-router";
import { getChatRooms } from "../../api/chat";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setChatRooms } from "../../redux/features/chatSlice";
import DBService from "../../services/DBService";
import { CustomButton } from "../common/CustomButton";
import { NotificationReminder } from "../NotificationReminder/NotificationReminder";
import { ChatRoomBanner } from "./ChatRoomBanner";
import ChatSocket from "../../socket/chatSocket";

export const ChatRoomsPage: FC = () => {
  const chatRooms = useAppSelector((state) => state.chat.rooms);
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [notificationPermission, setNotificationPermission] =
    useState("granted");
  const appState = useAppSelector((state) => state.appState);

  useAuth(Permission.USER_ONLY);

  const fetchChatList = useCallback(async () => {
    const { data } = await getChatRooms();
    const rooms = await Promise.all(
      data.map(async (chatRoom) => {
        const chatRoomState = await DBService.Instance.getChatRoomState(
          chatRoom.uid
        );

        return {
          id: chatRoom.uid,
          user: chatRoom.users.find((a) => a.uid !== user.uid)!,
          lastMessage: chatRoomState?.lastMessage ?? null,
          timestamp: new Date(
            chatRoom.updatedAt || chatRoom.createdAt
          ).getTime(),
          hasUnreadMessages: chatRoomState?.hasUnreadMessages ?? false,
        };
      })
    );

    dispatch(setChatRooms(rooms));
  }, [dispatch]);

  useEffect(() => {
    ChatSocket.Instance.connect();
    fetchChatList();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        const permission = Notification.permission;
        setNotificationPermission(permission);
      } catch (err) {}
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/dull_bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          maxWidth: 600,
          width: "100%",
          height: "100%",
          pb: 13,
        }}
        direction="column"
      >
        <Typography variant="headlineMedium" color="white" mb={1} pl={2} mt={4}>
          Chat
        </Typography>
        <ChatRoomBanner
          primaryText={`Did you know you earn ${appState.currencySymbol}0.40 every time you get a match`}
          secondaryText="The more you chat, the more matches you’ll get"
        />
        {notificationPermission !== "granted" && (
          <Stack
            direction="row"
            paddingY={1.5}
            px={4}
            sx={{
              backgroundColor: "white",
              gap: 1,
            }}
          >
            <Typography
              variant="notice"
              color={"#DC2D6D"}
              fontSize={14}
              fontWeight={500}
            >
              You have disabled notifications. Turn it back on to get notified
              when your friends reply you
            </Typography>
            <CustomButton
              size="small"
              onClick={() => {
                try {
                  // hard prompt
                  Notification.requestPermission();
                  setOpen(true);
                } catch (err) {
                  console.log(err);
                }
              }}
              text="Enable"
            />
          </Stack>
        )}
        <Stack
          direction="column"
          sx={{
            backgroundColor: "white",
            overflow: "auto",
          }}
        >
          {chatRooms.map((chat, index) => (
            <Box key={chat.id}>
              <Box
                py={2}
                px={2.5}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                gap={2}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/chat/${chat.id}`);
                }}
              >
                <Avatar
                  src={
                    chat.user?.avatar ??
                    (chat.user?.gender === "M"
                      ? "/new_male.png"
                      : chat.user?.gender === "F"
                      ? "new_female.png"
                      : "/logo_border.png")
                  }
                  alt="profile_pic"
                  sx={{
                    width: 50,
                    height: 50,
                    objectFit: "cover",
                    borderRadius: "50%",
                    cursor: "pointer",
                    WebkitTapHighlightColor: "rgba(0,0,0,0)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (chat?.id?.startsWith("sys-") || !chat?.user?.uid)
                      return;
                    navigate(`/profile/${chat?.user?.uid ?? ""}`);
                  }}
                />
                <Stack direction="column" flexGrow={1}>
                  <Typography fontWeight={700} fontSize={16} variant="notice">
                    {chat.user?.displayName ?? "-"}
                  </Typography>
                  <Typography fontSize={14} variant="notice">
                    {!chat.lastMessage
                      ? "Start a conversation"
                      : `${
                          chat.lastMessage.authorId === user.uid
                            ? "You"
                            : chat.user?.displayName
                        }: ` +
                        (chat.lastMessage.text.toString().trim() ||
                          "Sent an attachment")}
                  </Typography>
                </Stack>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-end"}
                  gap={1}
                >
                  <Typography
                    fontSize={12}
                    variant="notice"
                    fontWeight={500}
                    sx={{
                      opacity: 0.5,
                    }}
                  >
                    {moment(chat.timestamp).isSame(moment(), "day")
                      ? moment(chat.timestamp).format("hh.mma")
                      : moment(chat.timestamp).format("DD/MM/YYYY")}
                  </Typography>
                  <Box
                    borderRadius="50%"
                    width={8}
                    height={8}
                    sx={{
                      backgroundColor: chat.hasUnreadMessages
                        ? "orange"
                        : "transparent",
                    }}
                  />
                </Box>
              </Box>
              {index !== chatRooms.length - 1 && (
                <Divider
                  sx={{
                    borderColor: "#EAEAEA",
                    ml: 2,
                    mr: 2,
                  }}
                />
              )}
            </Box>
          ))}
        </Stack>
      </Stack>
      <NotificationReminder open={open} setOpen={setOpen} />
    </Stack>
  );
};

export default ChatRoomsPage;
