import { Option } from "../components/common/CustomAutocomplete";

export const getDays = () => {
  const days = 31;
  const opt = [];
  for (let i = 1; i <= days; i++) {
    opt.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  return opt;
};

export const getMonths = () => {
  const months = 12;
  const opt = [];
  const monthLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  for (let i = 1; i <= months; i++) {
    opt.push({
      value: i.toString(),
      label: monthLabels[i - 1],
    });
  }
  return opt;
};

export const getOptionsByMinMax = (from: number, to: number) => {
  const opt: Option[] = [];
  for (let i = from; i <= to; i++) {
    opt.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  return opt;
};
