import { Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import gsap from "gsap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { CustomTextField } from "../common/CustomTextField";
import { CustomButton } from "../common/CustomButton";
import { useQuery } from "@tanstack/react-query";
import {
  createOrder,
  getOrder,
  getProductCatalogs,
  getWalletAssets,
  transferAsset,
} from "../../api/payment";
import { getFiatWallet } from "../../api/wallet";
import { UserDto } from "../../types/UserDto";
import { delay } from "../../utility/time";

type Props = {
  open: boolean;
  setOpen: Function;
  count: number;
  setOpenTopup: Function;
  recipient?: UserDto;
  sendTextMessage: Function;
  roomUid: string;
  setMinAmount: Function;
  paymentLoading: boolean;
};

let localLoading = true;

export const GiftConfirmModal: React.FC<Props> = ({
  open,
  setOpen,
  count,
  setOpenTopup,
  recipient,
  sendTextMessage,
  roomUid,
  setMinAmount,
  paymentLoading,
}) => {
  const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const appState = useAppSelector((state) => state.appState);

  useEffect(() => {
    localLoading = loading;
  }, [loading]);

  const { data: wallet } = useQuery({
    queryKey: ["wallet", user?.uid],
    queryFn: () => getFiatWallet(),
    enabled: !!user?.uid,
  });

  const { data: products } = useQuery({
    queryKey: ["products", "gift"],
    queryFn: () => getProductCatalogs("gift"),
    enabled: true,
  });

  const price = useMemo(() => {
    return (
      products?.[0]?.prices?.find((a) => a.currency.label === appState.currency)
        ?.rate ?? 0
    );
  }, [products, appState]);

  const sendGift = async (orderUid: string) => {
    await delay(0.5);
    const newOrder = await getOrder(orderUid);
    if (!!newOrder?.meta?.deliveryResults?.[0]?.result) {
      const walletAssets = await getWalletAssets();
      const giftWalletAsset = walletAssets.find(
        (wa: any) => wa.label === "Gift"
      );
      const transferred = await transferAsset(
        recipient?.uid ?? "",
        count,
        giftWalletAsset?.id ?? 0,
        roomUid,
        orderUid
      );
      // order completed
      dispatch(
        setSnackbar({
          open: true,
          message: `Successfully sent gift to ${recipient?.displayName ?? "-"}`,
          severity: "success",
        })
      );
      sendTextMessage("Gifted", "GIFT", count.toString(), transferred?.uid);
      setOpen(false);
      setLoading(false);
      return;
    } else if (
      newOrder?.status === "PAYMENT_FAILED" ||
      newOrder?.status === "CANCELLED" ||
      newOrder?.status === "ERROR"
    ) {
      dispatch(
        setSnackbar({
          open: true,
          message: "Error processing order.",
          severity: "error",
        })
      );
      setLoading(false);
    }
    if (!localLoading) return;
    sendGift(orderUid);
  };

  useEffect(() => {
    if (!backdrop || !container) return;
    if (open) {
      gsap.to(backdrop, {
        visibility: "visible",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: 0,
        overwrite: true,
        duration: 0.05,
      });
    } else {
      gsap.to(backdrop, {
        visibility: "hidden",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: "100%",
        overwrite: true,
        duration: 0.05,
      });
    }
  }, [open, backdrop, container]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        position: "fixed",
        justifyContent: "flex-end",
        top: 0,
        left: 0,
        zIndex: 10,
        alignItems: "center",
        backdropFilter: "blur(5px)",
        visibility: "hidden",
        height: "100dvh",
      }}
      ref={(el) => setBackdrop(el)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "600px",
          transform: "translateY(100%)",
          borderRadius: "24px 24px 0px 0px",
          background: "white",
          position: "relative",
          cursor: "auto",
          overflowY: "scroll",
          p: 3,
          alignItems: "center",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={(el) => setContainer(el)}
      >
        <Typography variant="headlineMedium" mb={2} textAlign="center">
          Gifting
        </Typography>
        <img
          src="/short_line.svg"
          alt="short_line"
          style={{
            width: 100,
            height: 12,
            objectFit: "cover",
            pointerEvents: "none",
            marginBottom: 16,
          }}
        />
        <Typography color="#1E1E1E" variant="bodyMedium" mb={2}>
          You&apos;re gifting {recipient?.displayName ?? "-"}
        </Typography>
        <Stack
          direction="row"
          gap={2}
          mb={2}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="heroLarge" color="black">
            {count}
          </Typography>
          <img
            src="/gift_big.svg"
            alt="gift"
            style={{
              width: 60,
              height: 60,
              objectFit: "contain",
            }}
          />
        </Stack>
        <CustomTextField
          variant="normal"
          value={`${appState.currencySymbol} ${(price * count).toFixed(2)}`}
          setValue={() => {}}
          disabled
          placeholder="Total"
          sx={{
            mb: 2,
          }}
        />
        <CustomButton
          text="Confirm"
          loading={loading || paymentLoading}
          onClick={async () => {
            if ((wallet?.balance ?? 0) < price * count) {
              setOpenTopup(true);
              setMinAmount(Math.max(price * count - (wallet?.balance ?? 0), 0));
              return;
            }
            try {
              setLoading(true);
              // create order
              const order = await createOrder(
                products?.[0]?.id ?? 0,
                products?.[0]?.prices.find(
                  (a) => a.currency.label === appState.currency
                )?.currency.id ?? 0,
                "internal",
                count
              );
              sendGift(order.uid);
            } catch (err: any) {
              setLoading(false);
              dispatch(
                setSnackbar({
                  open: true,
                  message:
                    err?.response?.data?.error?.message ??
                    "Error creating order, please reload your page and try again.",
                  severity: "error",
                })
              );
              setLoading(false);
            }
          }}
          sx={{
            width: "100%",
            mb: 3,
          }}
        />
        <Typography
          sx={{
            cursor: "pointer",
          }}
          alignSelf="center"
          variant="notice"
          fontSize={14}
          fontWeight={400}
          onClick={() => {
            setLoading(false);
            setOpen(false);
          }}
        >
          Close
        </Typography>
      </Stack>
    </Stack>
  );
};
