import { Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { truncate } from "../../utility/string";
import { CustomFlipCard } from "../common/CustomFlipCard";
import { CustomTextField } from "../common/CustomTextField";
import { GachaImages } from "./GachaImages";
import { gsap } from "gsap";
import { SwipeDto } from "../../types/SwipeDto";
import moment from "moment";
import ChatSocket from "../../socket/chatSocket";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addMessage } from "../../redux/features/messageSlice";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { resetMessageSuggestion } from "../../redux/features/messageSuggestionSlice";

type Props = {
  id: string;
  selected: boolean;
  swipe: SwipeDto;
  showSuggestions: boolean;
  setShowSuggestions: Function;
  setSelectNext: Function;
  index: number;
  open: boolean;
};

export const GachaCard: React.FC<Props> = ({
  id,
  selected,
  swipe,
  showSuggestions,
  setShowSuggestions,
  setSelectNext,
  index,
  open,
}) => {
  const [showback, setShowback] = useState(false);
  const [message, setMessage] = useState("");
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const messageSuggestion = useAppSelector(
    (state) => state.messageSuggestion.suggestion
  );

  useEffect(() => {
    if (!!messageSuggestion && selected) {
      // auto send message
      sendTextMessage(messageSuggestion);
      dispatch(resetMessageSuggestion());
    }
  }, [messageSuggestion, selected]);

  useEffect(() => {
    if (selected) {
      gsap.set(`.gacha-card-container-${index}`, {
        opacity: 1,
        // x: 0,
        // y: 0,
      });
      setShowback(false);
    } else {
      gsap.set(`.gacha-card-container-${index}`, {
        opacity: 0,
        // x: 0,
        // y: 0,
      });
    }
  }, [selected]);

  const ageRange = useMemo(() => {
    if (!swipe?.recommendedUser?.dob) return "-";
    var years = moment().diff(swipe?.recommendedUser?.dob, "years");
    if (years >= 18 && years <= 25) return "18 - 25";
    if (years >= 26 && years <= 35) return "26 - 35";
    if (years >= 36) return "36 and above";
    return "-";
  }, [swipe?.recommendedUser?.dob]);

  const description = useMemo(() => {
    if (!swipe?.recommendedUser?.posts) return "";
    const result = [];
    const infoPost = swipe?.recommendedUser?.posts?.find(
      (p) => p.section === "INFO"
    );
    if (!!infoPost?.content?.school) {
      result.push(infoPost?.content?.school);
    }
    if (!!infoPost?.content?.job) {
      result.push(infoPost?.content?.job);
    }
    if (!!infoPost?.content?.religion) {
      result.push(infoPost?.content?.religion);
    }
    if (!!infoPost?.content?.height) {
      result.push(infoPost?.content?.height + " cm");
    }
    return result.join(", ");
  }, [swipe?.recommendedUser?.posts]);

  const bio = useMemo(() => {
    if (!swipe?.recommendedUser?.posts) return "";
    const bioPost = swipe?.recommendedUser?.posts?.find(
      (p) => p.section === "BIO"
    );
    if (!!bioPost?.content?.text) {
      return bioPost?.content?.text;
    }
    return "";
  }, [swipe?.recommendedUser?.posts]);

  const sendTextMessage = (text: string) => {
    const roomId = swipe?.match?.chatRoom?.uid ?? "";
    if (!text || !roomId) {
      dispatch(
        setSnackbar({
          open: true,
          message: "Chatroom not found.",
          severity: "error",
        })
      );
      setMessage("");
      setSelectNext();
      return;
    }
    const msg = ChatSocket.Instance.sendMessage({
      type: "TEXT",
      authorId: user.uid,
      roomId: roomId,
      text: text,
    });

    dispatch(addMessage({ roomId: roomId, message: msg }));
    dispatch(
      setSnackbar({
        open: true,
        message: `Sent a message to ${
          swipe?.recommendedUser?.displayName ?? "the person"
        }.`,
        severity: "success",
      })
    );
    setMessage("");
    setSelectNext();
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        position: "absolute",
        width: "100%",
        gap: 2,
        height: index !== 0 && open ? "100%" : "500px",
        paddingBottom: "48px",
        bottom: 0,
        // pointerEvents: selected ? "auto" : "none",
        zIndex: selected ? 5 : -1,
      }}
      className={`gacha-card-container gacha-card-container-${index} ${
        index !== 0 ? "gacha-card-container-others" : ""
      }`}
      onClick={() => {
        if (showSuggestions) return;
        setShowback((prev) => !prev);
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "100%",
          borderRadius: "16px",
          background:
            index !== 0 && open
              ? "white"
              : "url(/card_back.png) no-repeat center",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
          alignItems: "center",
          transform: "translate(0%, -300%)",
          maxWidth: index !== 0 && open ? "450px" : "300px",
          height: "100%",
          cursor: "auto",
        }}
        className={`gacha-card-${index} gacha-card ${
          index !== 0 ? "gacha-card-others" : ""
        }`}
      >
        <CustomFlipCard
          showBack={showback}
          frontComponent={
            <Stack
              direction="column"
              alignItems="center"
              width="100%"
              height="100%"
              sx={{
                opacity: index !== 0 && open ? 1 : 0,
                // transform: rotated ? "rotateY(-180deg)" : undefined,
                overflowY: "auto",
                pointerEvents: "none",
                // background: "white",
              }}
              className={`gacha-content gacha-content-${index}`}
            >
              <Stack
                direction="column"
                alignItems="center"
                position="absolute"
                top={16}
                left={16}
              >
                <Typography
                  variant="headlineSmall"
                  color="#22171E"
                  mb={1}
                  textAlign="center"
                >
                  {ageRange}
                </Typography>
                {/* <img
                  src="/face.png"
                  alt="face"
                  style={{
                    width: 36,
                    height: 36,
                    objectFit: "contain",
                    marginBottom: 16,
                  }}
                />
                <img
                  src="/lang.svg"
                  alt="lang"
                  style={{
                    width: 16,
                    height: 16,
                    objectFit: "contain",
                    marginBottom: 4,
                  }}
                />
                <img
                  src="/country.png"
                  alt="country"
                  style={{
                    width: 24,
                    height: 24,
                    objectFit: "contain",
                  }}
                /> */}
              </Stack>
              <img
                src="/sep.svg"
                height={20}
                width={118}
                alt="sep"
                style={{
                  objectFit: "contain",
                  marginBottom: 16,
                  marginTop: 16,
                  pointerEvents: "none",
                }}
              />
              <img
                src={
                  !!swipe?.recommendedUser?.avatar
                    ? swipe?.recommendedUser?.avatar
                    : swipe?.recommendedUser?.gender === "M"
                    ? "/new_male.png"
                    : swipe?.recommendedUser?.gender === "F"
                    ? "new_female.png"
                    : "/logo_border.png"
                }
                alt="avatar"
                style={{
                  borderRadius: "50%",
                  width: 100,
                  height: 100,
                  objectFit: "cover",
                  marginBottom: 8,
                  pointerEvents: "none",
                }}
              />
              <Typography
                variant="headlineLarge"
                color="#22171E"
                textAlign="center"
                mb={1}
              >
                {swipe?.recommendedUser?.displayName ?? "-"}
              </Typography>
              <Typography
                variant="bodySmall"
                color="#22171E"
                textAlign="center"
                mb={1.5}
              >
                looking for {swipe?.recommendedUser?.lookingFor ?? "-"}
              </Typography>
              <img
                src="/line.svg"
                alt="line"
                style={{
                  width: "100%",
                  height: "12px",
                  objectFit: "cover",
                  marginBottom: 8,
                  pointerEvents: "none",
                }}
              />
              <Typography
                variant="headlineSmall"
                color="#22171E"
                mb={1}
                ml={4}
                mr={4}
                textAlign="center"
                whiteSpace="pre-line"
              >
                {description}
              </Typography>
              <Stack
                p={3}
                sx={{
                  background: !!bio ? "url(/container.svg)" : undefined,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  mb: 2,
                }}
              >
                <Typography
                  variant="notice"
                  color="black"
                  textAlign="center"
                  whiteSpace="pre-line"
                  fontSize={12}
                >
                  {truncate(bio, 100)}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  flex: 1,
                }}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                pr={1.5}
                sx={{
                  borderRadius: "16px",
                  border: "1px solid rgba(0, 0, 0, 0.10)",
                  width: "calc(100% - 32px - 32px)",
                  visibility: showback ? "hidden" : "visible",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <CustomTextField
                  value={message}
                  setValue={setMessage}
                  placeholder="Send a message"
                  variant="none"
                  sx={{
                    width: "100%",
                    flex: 1,
                  }}
                  onFocus={() => {
                    setShowSuggestions(true);
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setShowSuggestions(false);
                    }, 250);
                  }}
                  onSubmit={() => {
                    setShowSuggestions(false);
                    dispatch(resetMessageSuggestion());
                    sendTextMessage(message);
                  }}
                />
                <img
                  src="/send.svg"
                  alt="send"
                  style={{
                    width: 14,
                    height: 14,
                    objectFit: "contain",
                    cursor: "pointer",
                    WebkitTapHighlightColor: "rgba(0,0,0,0)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSuggestions(false);
                    dispatch(resetMessageSuggestion());
                    sendTextMessage(message);
                  }}
                />
              </Stack>
              <img
                src="/sep.svg"
                height={20}
                width={118}
                alt="sep"
                style={{
                  objectFit: "contain",
                  marginBottom: 16,
                  marginTop: 16,
                  pointerEvents: "none",
                }}
              />
            </Stack>
          }
          backComponent={
            <Stack
              direction="column"
              alignItems="center"
              width="100%"
              height="100%"
              sx={{
                opacity: index !== 0 && open ? 1 : 0,
                pointerEvents: "none",
                // transform: rotated ? "rotateY(-180deg)" : undefined,
              }}
              className={`gacha-content gacha-content-${index}`}
            >
              <Stack height={8} />
              <GachaImages user={swipe?.recommendedUser} />
              <img
                src="/short_line.svg"
                alt="shirt_line"
                style={{
                  width: 184,
                  height: 10,
                  objectFit: "cover",
                  pointerEvents: "none",
                  marginBottom: 16,
                  marginTop: 24,
                }}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                pr={1.5}
                sx={{
                  borderRadius: "16px",
                  border: "1px solid rgba(0, 0, 0, 0.10)",
                  width: "calc(100% - 32px - 32px)",
                  visibility: !showback ? "hidden" : "visible",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onSubmit={() => {
                  setShowSuggestions(false);
                  dispatch(resetMessageSuggestion());
                  sendTextMessage(message);
                }}
              >
                <CustomTextField
                  value={message}
                  setValue={setMessage}
                  placeholder="Send a message"
                  variant="none"
                  sx={{
                    width: "100%",
                    flex: 1,
                  }}
                  onFocus={() => {
                    setShowSuggestions(true);
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setShowSuggestions(false);
                    }, 250);
                  }}
                />
                <img
                  src="/send.svg"
                  alt="send"
                  style={{
                    width: 14,
                    height: 14,
                    objectFit: "contain",
                    cursor: "pointer",
                    WebkitTapHighlightColor: "rgba(0,0,0,0)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSuggestions(false);
                    dispatch(resetMessageSuggestion());
                    sendTextMessage(message);
                  }}
                />
              </Stack>
              <img
                src="/sep.svg"
                height={20}
                width={118}
                alt="sep"
                style={{
                  objectFit: "contain",
                  marginBottom: 16,
                  marginTop: 16,
                  pointerEvents: "none",
                }}
              />
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};
