import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { useAppDispatch } from "../../redux/hooks";

// this page ensures that users will not be redirected to instagram app during login
export const ConnectIgMiddlePage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const url = searchParams.get("url");
    if (!url) {
      navigate("/");
      return;
    }

    const decodedUrl = decodeURIComponent(url);

    const newWin = window.open(decodedUrl);

    if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
      // fallback to location href
      window.location.href = decodedUrl;
      // dispatch(
      //   setSnackbar({
      //     open: true,
      //     message: "Please enable popup permission to connect instagram.",
      //     severity: "warning",
      //   })
      // );
      // navigate("/");
    }
  }, []);

  return <></>;
};
