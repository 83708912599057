import { Stack, ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import { theme } from "../../constants/theme";
import { Permission, useAuth } from "../../hooks/useAuth";
import { useGtm } from "../../hooks/useGtm";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { BottomMenuBar } from "../BottomMenuBar/BottomMenuBar";

type Props = {};

export const Layout: React.FC<Props> = ({}) => {
  useAuth(Permission.ANYONE);

  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  useGtm(); // initialize google tag manager

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="column" width="100vw" alignItems="center">
        <Stack width="100%" position="relative">
          <Outlet />
          <BottomMenuBar />
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};
