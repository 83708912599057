import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "../redux/hooks";

export enum Permission {
  ANYONE,
  GUEST_ONLY,
  USER_ONLY,
}

export const useAuth = (permission: Permission) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (permission === Permission.GUEST_ONLY && !!user?.uid) navigate("/");
    if (permission === Permission.USER_ONLY && !user?.uid) navigate("/login");
  }, [permission, user]);
};
