import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ChatMessage } from "./chatSlice";
import DBService from "../../services/DBService";

interface MessagesState {
  messages: ChatMessage[];
}

const initialState: MessagesState = {
  messages: [],
};

export const addMessage = createAsyncThunk(
  "messages/add",
  async (payload: { roomId: string; message: ChatMessage | ChatMessage[] }, thunkAPI) => {
    const { roomId, message } = payload;
    const existingMessages =
      (await DBService.Instance.getMessages(roomId)) || [];

    if (Array.isArray(message)) {
      return [...existingMessages, ...message];
    } else {
      existingMessages.push(message);
    }

    return existingMessages;
  }
);

export const loadMessages = createAsyncThunk(
  "messages/load",
  async (roomId: string, thunkAPI) => {
    return (await DBService.Instance.getMessages(roomId)) || [];
  }
);

export const messages = createSlice({
  name: "messages",
  initialState,
  reducers: {
    resetMessages: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(addMessage.fulfilled, (state, action) => {
      state.messages = action.payload;
    });
    builder.addCase(loadMessages.fulfilled, (state, action) => {
      state.messages = action.payload;
    });
  },
});

export const { resetMessages } = messages.actions;
export default messages.reducer;
