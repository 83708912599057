import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDto } from "../../types/UserDto";

type UserState = {
  user: UserDto;
};

const initialState = {
  user: {
    avatar: "",
    blocked: false,
    confirmed: false,
    dob: "",
    displayName: "",
    email: "",
    gender: "",
    horoscope: "",
    provider: "",
    uid: "",
    updatedAt: "",
    username: "",
    lookingFor: "",
    interests: [],
    posts: [],
  },
} as UserState;

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: () => initialState,
    setUser: (state, action: PayloadAction<UserDto>) => {
      state.user = action.payload;
    },
  },
});

export const { resetUser, setUser } = user.actions;
export default user.reducer;
