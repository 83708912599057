import { Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { signOut } from "firebase/auth";
import { useCallback, useEffect, useMemo, useState } from "react";
import OneSignal from "react-onesignal";
import { useNavigate } from "react-router";
import { uploadAsset } from "../../api/file";
import { getIsConnectedIg } from "../../api/instagram";
import { createPost } from "../../api/post";
import { getInfoTemplate, getInterests } from "../../api/template";
import { getUser, updateUser } from "../../api/user";
import { lookingForOptions } from "../../constants/options";
import { Permission, useAuth } from "../../hooks/useAuth";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { resetUser, setUser } from "../../redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { UserDto } from "../../types/UserDto";
import { compressImage } from "../../utility/file";
import { auth } from "../../utility/firebase";
import { getOptionsByMinMax } from "../../utility/options";
import { CustomAutocomplete, Option } from "../common/CustomAutocomplete";
import { CustomButton } from "../common/CustomButton";
import { CustomSelectField } from "../common/CustomSelectField";
import { CustomSingleAutocomplete } from "../common/CustomSingleAutocomplete";
import { CustomTextField } from "../common/CustomTextField";

type Props = {
  refetch: Function;
};

export const ProfileBack: React.FC<Props> = ({ refetch }) => {
  const user = useAppSelector((state) => state.user.user);
  useAuth(Permission.USER_ONLY);
  const [formData, setFormData] = useState(user);
  const [avatar, setAvatar] = useState("");
  const [school, setSchool] = useState("");
  const [job, setJob] = useState("");
  const [religion, setReligion] = useState("");
  const [height, setHeight] = useState("");
  const [lang, setLang] = useState<Option[]>([]);
  const [bio, setBio] = useState("");
  const [heightOptions, setHeightOptions] = useState<Option[]>([]);
  const [religionOptions, setReligionOptions] = useState<Option[]>([]);
  const [langOptions, setLangOptions] = useState<Option[]>([]);
  const [schoolOptions, setSchoolOptions] = useState<Option[]>([]);
  const [interests, setInterests] = useState<Option[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const appState = useAppSelector((state) => state.appState);

  const { data: infoFields } = useQuery({
    queryKey: ["infoFields"],
    queryFn: () => getInfoTemplate(),
    enabled: !!user?.uid,
  });

  const { data: hasConnected } = useQuery({
    queryKey: ["hasConnected", user?.uid],
    queryFn: () => getIsConnectedIg(),
    enabled: !!user?.uid,
  });

  useEffect(() => {
    setFormData(user);
    setInterests(
      user.interests.map((inter) => ({
        value: inter.id,
        label: inter.label,
      }))
    );
    setAvatar(user?.avatar ?? "");
  }, [user]);

  const { data: interestsOptionsRaw } = useQuery({
    queryKey: ["interests"],
    queryFn: () => getInterests(),
    enabled: !!user?.uid,
  });

  const interestOptions = useMemo(() => {
    if (!interestsOptionsRaw) return [];
    return interestsOptionsRaw.map((inter: any) => ({
      value: inter.value,
      label: inter.label,
    }));
  }, [interestsOptionsRaw]);

  useEffect(() => {
    if (!infoFields) return;
    const heightInfo = infoFields.find((f: any) => f.key === "height");
    if (!!heightInfo) {
      setHeightOptions(getOptionsByMinMax(heightInfo.min, heightInfo.max));
    }
    const religionInfo = infoFields.find((f: any) => f.key === "religion");
    if (!!religionInfo?.options) {
      setReligionOptions(
        religionInfo?.options
          ?.map((opt: any) => ({
            value: opt.value,
            label: opt.label,
          }))
          ?.sort((a: any, b: any) => {
            if (a?.value < b?.value) return -1;
            return 1;
          })
      );
    }
    const langInfo = infoFields.find((f: any) => f.key === "languages");
    if (!!langInfo?.options) {
      setLangOptions(
        langInfo?.options
          ?.map((opt: any) => ({
            value: opt.value,
            label: opt.label,
          }))
          ?.sort((a: any, b: any) => {
            if (a?.value < b?.value) return -1;
            return 1;
          })
      );
    }
    const schoolInfo = infoFields.find(
      (f: any) => f.key === `school_${appState.country}`
    );
    if (!!schoolInfo?.options) {
      setSchoolOptions(
        schoolInfo?.options
          ?.map((opt: any) => ({
            value: opt.value,
            label: opt.label,
          }))
          ?.sort((a: any, b: any) => {
            if (a?.value < b?.value) return -1;
            return 1;
          })
      );
    }
  }, [infoFields]);

  const updateFormField = useCallback(
    (formData: UserDto, k: string, v: any) => {
      setFormData({ ...formData, [k]: v });
    },
    []
  );

  const infoPost = useMemo(() => {
    return user.posts?.find((p) => p.section === "INFO");
  }, [user]);

  useEffect(() => {
    setSchool(infoPost?.content?.school ?? "");
    setJob(infoPost?.content?.job ?? "");
    setHeight(infoPost?.content?.height ?? "");
    setReligion(infoPost?.content?.religion ?? "");
    setLang(
      (infoPost?.content?.languages ?? []).map((s: string) => ({
        value: s,
        label: s,
      }))
    );
  }, [infoPost]);

  const bioPost = useMemo(() => {
    return user.posts?.find((p) => p.section === "BIO");
  }, [user]);

  useEffect(() => {
    setBio(bioPost?.content?.text ?? "");
  }, [bioPost]);

  const saveProfile = async () => {
    if (!user?.uid) return;
    if (!formData.displayName) {
      dispatch(
        setSnackbar({
          open: true,
          message: "Please do not leave your name blank!",
          severity: "warning",
        })
      );
      return;
    }
    try {
      // update user
      await updateUser(user.uid, {
        displayName: formData.displayName,
        lookingFor: formData.lookingFor,
        avatar: avatar,
      });
      // create post
      await createPost("BIO", {
        text: bio,
      });
      await createPost("INTERESTS", {
        interests: interests.map((v) => v.value),
      });
      await createPost("INFO", {
        job: job,
        height: height,
        school: school,
        religion: religion,
        languages: lang.map((l) => l.value),
      });
      refetch();
      const newUser = await getUser(user.uid);
      dispatch(setUser(newUser));
      dispatch(
        setSnackbar({
          open: true,
          message: "Updated your profile.",
          severity: "success",
        })
      );
      navigate("/profile");
    } catch (err: any) {
      dispatch(
        setSnackbar({
          open: true,
          message:
            err?.response?.data?.error?.message ?? "Error updating profile.",
          severity: "error",
        })
      );
    }
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        background: "white",
        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
        alignItems: "center",
        overflow: "auto",
        maxWidth: 600,
      }}
    >
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="image-file"
        hidden
        type="file"
        onChange={async (e) => {
          setLoading(true);
          // Upload to S3 and setAvatar link after getting URL
          if (!!e.target.files?.[0]) {
            const compressedFile = await compressImage(e.target.files[0]);
            if (!compressedFile) {
              dispatch(
                setSnackbar({
                  open: true,
                  severity: "error",
                  message:
                    "You file size is too big, please choose another image.",
                })
              );
              return;
            }
            uploadAsset(compressedFile, "avatar")
              .then((url) => {
                setAvatar(url);
                setLoading(false);
              })
              .catch((err: any) => {
                setLoading(false);
                dispatch(
                  setSnackbar({
                    open: true,
                    severity: "error",
                    message:
                      err?.response?.data?.error?.message ??
                      "Error uploading avatar.",
                  })
                );
              });
          } else {
            setLoading(false);
          }
        }}
      />
      <label
        htmlFor="image-file"
        style={{
          alignSelf: "center",
        }}
      >
        <Stack
          position="relative"
          sx={{
            width: "120px",
            height: "120px",
            mt: 2,
            cursor: "pointer",
            WebkitTapHighlightColor: "rgba(0,0,0,0)",
          }}
        >
          <img
            src={
              !!avatar
                ? avatar
                : user?.gender === "M"
                ? "/new_male.png"
                : "/new_female.png"
            }
            alt="avatar"
            style={{
              width: 120,
              height: 120,
              objectFit: "cover",
              borderRadius: "50%",
              position: "absolute",
            }}
            className="avatar"
          />
          <Stack
            justifyContent="center"
            alignItems="center"
            width="120px"
            height="120px"
            sx={{
              borderRadius: "50%",
              position: "absolute",
              background: "rgba(0,0,0,0.3)",
            }}
          >
            <img
              src="/camera.svg"
              alt="camera"
              style={{
                width: 40,
                height: 40,
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </Stack>
        </Stack>
      </label>
      <CustomTextField
        value={formData.displayName ?? ""}
        setValue={(v) => {
          updateFormField(formData, "displayName", v);
        }}
        variant="normal"
        placeholder="Name"
        sx={{
          mt: 2,
          pl: 2,
          pr: 2,
          mb: 2,
        }}
      />
      <CustomAutocomplete
        placeholder="Interests"
        values={interests}
        setValues={setInterests}
        options={interestOptions}
        max={5}
        variant="normal"
        sx={{
          pl: 2,
          pr: 2,
          mb: 2,
        }}
      />
      <CustomSelectField
        value={formData.lookingFor ?? ""}
        setValue={(v) => {
          updateFormField(formData, "lookingFor", v);
        }}
        placeholder="Looking For"
        options={lookingForOptions}
        variant="normal"
        sx={{
          pl: 2,
          pr: 2,
          mb: 2,
        }}
      />
      <CustomSelectField
        placeholder="University"
        variant="normal"
        value={school}
        setValue={(v) => {
          setSchool(v.toString());
        }}
        options={schoolOptions}
        sx={{
          pl: 2,
          pr: 2,
          mb: 2,
        }}
      />
      {/* <CustomTextField
        value={job}
        setValue={setJob}
        variant="normal"
        placeholder="Title"
        sx={{
          pl: 2,
          pr: 2,
          mb: 2,
        }}
      /> */}
      <CustomSelectField
        value={religion}
        setValue={setReligion}
        placeholder="Religion"
        options={religionOptions}
        variant="normal"
        sx={{
          pl: 2,
          pr: 2,
          mb: 2,
        }}
      />
      <CustomSelectField
        value={height}
        setValue={setHeight}
        placeholder="Height (cm)"
        options={heightOptions}
        variant="normal"
        sx={{
          pl: 2,
          pr: 2,
          mb: 2,
        }}
      />
      <CustomAutocomplete
        values={lang}
        setValues={setLang}
        placeholder="Languages"
        options={langOptions}
        variant="normal"
        sx={{
          pl: 2,
          pr: 2,
          mb: 2,
        }}
      />
      <CustomTextField
        value={bio}
        setValue={setBio}
        variant="normal"
        placeholder="Bio"
        maxRows={4}
        sx={{
          pl: 2,
          pr: 2,
          mb: 2,
        }}
      />
      <Stack
        direction="column"
        gap={0.5}
        width="100%"
        sx={{
          pl: 2,
          pr: 2,
          mb: 4,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Typography variant="bodySmall" color="#1E1E1E">
          Social
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="notice" fontSize={14}>
            Instagram
          </Typography>
          <CustomButton
            text={hasConnected ? "Connected" : "Connect"}
            disabled={hasConnected}
            onClick={async () => {
              navigate("/instagram");
            }}
            size="small"
            variant="tertiary"
          />
        </Stack>
      </Stack>
      <CustomButton
        text="Save"
        onClick={async () => {
          await saveProfile();
        }}
        loading={loading}
        size="medium"
        variant="outlined"
        sx={{
          width: "calc(100% - 16px - 16px)",
          mb: 3,
        }}
      />
      <Typography
        onClick={() => {
          signOut(auth);
          dispatch(resetUser());
          localStorage.clear();
          OneSignal.logout();
        }}
        variant="bodySmall"
        sx={{
          mb: 2,
          cursor: "pointer",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
        }}
      >
        Logout
      </Typography>
    </Stack>
  );
};
