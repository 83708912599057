import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { CustomButton } from "../common/CustomButton";

export const PaymentSuccess = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/dull_bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
      position="relative"
    >
      <img
        src="/logo.svg"
        alt="Delulu"
        style={{
          objectFit: "contain",
          width: 126,
          height: 164,
          marginBottom: 16,
          pointerEvents: "none",
        }}
      />
      <Typography
        variant="headlineMedium"
        mb={3}
        color="white"
        textAlign="center"
        ml={6}
        mr={6}
      >
        Payment success, thank you for your purchase!
      </Typography>
      <Typography
        variant="bodyMedium"
        mb={3}
        color="white"
        textAlign="center"
        ml={6}
        mr={6}
      >
        It may take up to 5 minutes to credit to your account.
      </Typography>
      <CustomButton
        text="Back to Home"
        onClick={() => {
          navigate("/");
        }}
      />
    </Stack>
  );
};
