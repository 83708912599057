import API from "./api";

export const updateUser = async (uid: string, data: any) => {
  const resp = await API.put(`/users/${uid}`, {
    data: data,
  });
  return resp.data;
};

export const getUser = async (uid: string) => {
  const resp = await API.get(
    `/users/${uid}?populate=posts,interests,wingman,referrer`
  );
  return resp.data?.data;
};

export const updateUserLocation = async (lat: number, lng: number) => {
  const resp = await API.post("/user-locations", {
    data: {
      lat,
      lng,
    },
  });
  return resp.data?.data;
};
