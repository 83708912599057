import API from "./api";
import axios from "axios";

export const uploadAsset = async (file: File, category: string) => {
  const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
  const res = await API.post<any, any>(`assets/uploads`, {
    data: {
      category: category,
      fileExtension: extension,
    },
  });
  if (!res.data?.data?.uploadUrl || !res.data?.data?.url)
    throw new Error("Upload failed, please try again.");

  await axios.put(res.data.data.uploadUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
  });

  return res.data.data.url;
};
