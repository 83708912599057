import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Permission, useAuth } from "../../hooks/useAuth";
import { getDays, getMonths, getOptionsByMinMax } from "../../utility/options";
import { CustomButton } from "../common/CustomButton";
import { CustomCard } from "../common/CustomCard";
import { CustomSelectField } from "../common/CustomSelectField";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { setUser } from "../../redux/features/userSlice";
import { getUser, updateUser } from "../../api/user";
import moment from "moment-timezone";

export const DobPage = () => {
  const navigate = useNavigate();
  useAuth(Permission.USER_ONLY);
  const [date, setDate] = useState("1");
  const [month, setMonth] = useState("1");
  const [year, setYear] = useState("2023");
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!user?.dob) {
      const splitted = user.dob.split("-");
      setYear((+splitted[0]).toString());
      setMonth((+splitted[1]).toString());
      setDate((+splitted[2]).toString());
    }
  }, [user]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
    >
      <CustomCard title="Your Delulu Details">
        <Typography variant="headlineSmall" textAlign="center" mb={1}>
          Your Date of Birth?
        </Typography>
        <Stack direction="row" gap={1} width="100%">
          <CustomSelectField
            placeholder="Date"
            value={date}
            setValue={setDate}
            options={getDays()}
          />
          <CustomSelectField
            placeholder="Month"
            value={month}
            setValue={setMonth}
            options={getMonths()}
          />
          <CustomSelectField
            placeholder="Year"
            value={year}
            setValue={setYear}
            options={getOptionsByMinMax(1900, 2023)}
          />
        </Stack>
        <CustomButton
          text="Next"
          size="medium"
          variant="outlined"
          onClick={async () => {
            if (!user?.uid) return;
            try {
              const dob = moment.tz(
                {
                  year: +year,
                  month: +month - 1,
                  date: +date,
                },
                "Africa/Abidjan"
              );
              // check diff in year
              const diff = moment.tz("Africa/Abidjan").diff(dob, "years");
              if (diff < 18) {
                dispatch(
                  setSnackbar({
                    open: true,
                    message: "You must be at least 18 to use this application.",
                    severity: "error",
                  })
                );
                return;
              }
              await updateUser(user.uid, {
                dob: dob.toISOString(),
              });
              const newUser = await getUser(user.uid);
              dispatch(setUser(newUser));
              // assign referrer if exists
              const referrer = localStorage.getItem("referrer");
              if (!!referrer && !user.referrer) {
                await updateUser(user.uid, {
                  referralCode: referrer,
                });
              }
              navigate("/gender");
            } catch (err: any) {
              dispatch(
                setSnackbar({
                  open: true,
                  message:
                    err?.response?.data?.error?.message ??
                    "Error updating dob.",
                  severity: "error",
                })
              );
            }
          }}
          sx={{
            mt: 2,
            mb: 4,
          }}
        />
      </CustomCard>
    </Stack>
  );
};
