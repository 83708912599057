import { FC, useEffect, useMemo, useState } from "react";
import { Permission, useAuth } from "../../hooks/useAuth";
import { Stack, Box, Typography } from "@mui/material";
import { CustomButton } from "../common/CustomButton";
import HowItWorksModal from "./HowItWorksModal";
import { WingmanReferralPage } from "./WingmanReferralPage";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { TotalEarningCard } from "./TotalEarningCard";
import { WingmanSuccessModal } from "../WingmanSuccessModal/WingmanSuccessModal";
import { useSearchParams } from "react-router-dom";
import { WithdrawModal } from "../WithdrawModal/WithdrawModal";
import { getUser } from "../../api/user";
import { setUser } from "../../redux/features/userSlice";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getTransactions } from "../../api/transaction";
import {
  GetTransactionsResponse,
  TransactionDto,
} from "../../types/TransactionDto";
import moment from "moment";
import { delay } from "../../utility/time";
import {
  createWingmanOrder,
  getOrder,
  getProductCatalogs,
} from "../../api/payment";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { TopUpModal } from "../TopUpModal/TopUpModal";
import { getFreeDrawWallet } from "../../api/wallet";
import { getReward } from "../../api/reward";
import { ReferModal } from "../ReferModal/ReferModal";
import { FreeDrawModal } from "../FreeDrawModal/FreeDrawModal";

let localLoading = false;

const ReferralPage: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const appState = useAppSelector((state) => state.appState);
  const dispatch = useAppDispatch();
  const [openWingmanSuccess, setOpenWingmanSuccess] = useState(false);
  const [params] = useSearchParams();
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openTopup, setOpenTopup] = useState(false);
  const [minAmount, setMinAmount] = useState(0);
  const [isReferModalOpen, setIsReferModalOpen] = useState(false);
  const [openFreeDraw, setOpenFreeDraw] = useState(false);

  useEffect(() => {
    localLoading = loading;
  }, [loading]);

  const checkWingmanOrder = async (orderUid: string) => {
    await delay(1);
    const newOrder = await getOrder(orderUid);
    if (
      newOrder?.status === "PAYMENT_FAILED" ||
      newOrder?.status === "CANCELLED" ||
      newOrder?.status === "ERROR"
    ) {
      dispatch(
        setSnackbar({
          open: true,
          message: "Error processing order.",
          severity: "error",
        })
      );
    } else if (newOrder?.status === "COMPLETED") {
      setOpenWingmanSuccess(true);
      const newUser = await getUser(user?.uid ?? "");
      dispatch(setUser(newUser));
    } else {
      checkWingmanOrder(orderUid);
    }
  };

  const purchaseWingman = async () => {
    // purchase wingman here
    try {
      const wingmanCatalog = await getProductCatalogs("wingman");
      const product = wingmanCatalog?.[0];
      if (!product) {
        dispatch(
          setSnackbar({
            open: true,
            message: "Product not found.",
            severity: "error",
          })
        );
        return;
      }
      const currencyId = product.prices?.[0]?.currency?.id;
      if (!currencyId) {
        dispatch(
          setSnackbar({
            open: true,
            message: "Currency not found.",
            severity: "error",
          })
        );
        return;
      }
      const wingmanOrder = await createWingmanOrder(product.id, currencyId);
      checkWingmanOrder(wingmanOrder.uid);
    } catch (err: any) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message:
            err?.response?.data?.error?.message ??
            "Error purchasing, please reload and try again.",
        })
      );
    }
  };

  const checkOrder = async (orderUid: string) => {
    await delay(1);
    const newOrder = await getOrder(orderUid);
    if (
      newOrder?.status === "PAYMENT_FAILED" ||
      newOrder?.status === "CANCELLED" ||
      newOrder?.status === "ERROR"
    ) {
      setLoading(false);
      dispatch(
        setSnackbar({
          open: true,
          message: "Error processing order.",
          severity: "error",
        })
      );
    } else if (newOrder?.status === "COMPLETED") {
      setLoading(false);
      purchaseWingman();
    }
    if (!localLoading) return;
    checkOrder(orderUid);
  };

  useEffect(() => {
    const comingSoon = process.env.REACT_APP_COMING_SOON;
    if (comingSoon === "false") setDisabled(false);
  }, []);

  useEffect(() => {
    if (!user?.uid) return;
    const wingmanOrderUid = localStorage.getItem("wingmanOrderUid");
    if (!wingmanOrderUid) return;
    localStorage.removeItem("wingmanOrderUid");
    setLoading(true);
    // check if order comes thru, if so, popup modal
    checkOrder(wingmanOrderUid);
  }, [user?.uid]);

  const { data: freeDraw, refetch: refetchFreeDraw } = useQuery({
    queryKey: ["freeDraw", user?.uid],
    queryFn: () => getFreeDrawWallet(),
    enabled: !!user?.uid,
  });

  const { data: reward, refetch: refetchReward } = useQuery({
    queryKey: ["reward", user?.uid],
    queryFn: () => getReward(),
    enabled: !!user?.uid,
  });

  useEffect(() => {
    const comingSoon = process.env.REACT_APP_COMING_SOON;
    const canPopFreeDraw = localStorage.getItem("canPopFreeDraw");
    if (
      (freeDraw?.balance ?? 0) > 0 &&
      comingSoon === "false" &&
      canPopFreeDraw === "true"
    ) {
      // if there is any free draw, don't show mission
      setOpenFreeDraw(true);
      localStorage.setItem("canPopFreeDraw", "false");
      return;
    }
  }, [reward, freeDraw]);

  useAuth(Permission.USER_ONLY);

  const {
    data: transactionPages,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    [`TRANSACTIONS`, user.uid],
    (queryContext) => getTransactions(25, queryContext?.pageParam ?? 0),
    {
      enabled: !!user?.uid,
      cacheTime: 0,
      getNextPageParam: (lastPage: GetTransactionsResponse, allPages: any) => {
        if (
          lastPage?.data?.length < lastPage?.meta.pagination.limit ||
          lastPage?.meta.pagination.start + lastPage?.meta.pagination.limit >=
            lastPage?.meta.pagination.total
        ) {
          return undefined;
        }
        return lastPage.meta.pagination.start + lastPage.meta.pagination.limit;
      },
    }
  );

  const transactions = useMemo(() => {
    const result: TransactionDto[] = [];
    transactionPages?.pages.forEach((page) => {
      result.push(...page.data);
    });
    return result;
  }, [transactionPages]);

  if (!!user?.wingman)
    return (
      <WingmanReferralPage
        openWingmanSuccess={openWingmanSuccess}
        setOpenWingmanSuccess={setOpenWingmanSuccess}
        isActive={!!user?.wingman?.active}
        loading={loading}
        setLoading={setLoading}
        purchaseWingman={purchaseWingman}
        onRefetch={() => {
          refetchFreeDraw();
          refetchReward();
          localStorage.setItem("canPopFreeDraw", "true");
        }}
      />
    );

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/dull_bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
        alignItems: "center",
        pt: 6,
        pb: 13,
      }}
    >
      <Stack
        borderRadius={4}
        flexDirection={"column"}
        sx={{
          backgroundColor: "white",
          alignItems: "center",
          width: "calc(100% - 8px - 8px)",
          flex: 1,
          maxWidth: 600,
          overflowY: "auto",
        }}
      >
        <TotalEarningCard setOpenWithdraw={setOpenWithdraw} />
        <Box display={"flex"} flexDirection={"column"}>
          <Typography variant="bodyLarge" textAlign="center" fontSize={24}>
            Earn when
          </Typography>
          <Box width={200} mb={3}>
            <Typography
              display={"flex"}
              variant="bodyLarge"
              textAlign="center"
              fontSize={14}
              marginTop={1}
            >
              your friends spend, get matched or sign up as a wingman
            </Typography>
          </Box>
        </Box>
        <img
          src="/line.svg"
          alt="line"
          style={{
            width: "100%",
            height: "12px",
            objectFit: "cover",
            pointerEvents: "none",
          }}
        />
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          pt={3}
          alignItems={"center"}
          width="100%"
        >
          <CustomButton
            text="Activate Wingman Mode"
            variant="tertiary"
            size="small"
            disabled={disabled}
            onClick={() => {
              setIsModalOpen(true);
            }}
            sx={{
              alignSelf: "center",
              pt: 1,
              pb: 1,
              pl: 3,
              pr: 3,
              width: 250,
              maxWidth: "100%",
            }}
          />
          <CustomButton
            text="Invite a friend"
            onClick={() => setIsReferModalOpen(true)}
            size="small"
            variant="secondary"
            sx={{
              pt: 1,
              pb: 1,
              pl: 3,
              pr: 3,
              mb: 1,
              mt: 1,
              width: 250,
              maxWidth: "100%",
            }}
          />
          <Typography
            variant="bodySmall"
            sx={{
              cursor: "pointer",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
            }}
            onClick={() => setIsModalOpen(true)}
            mb={3}
          >
            How it works
          </Typography>
          {transactions.length > 0 && (
            <Typography
              variant="bodySmall"
              color="#22171E"
              width="100%"
              pt={1}
              pl={2}
              pr={2}
              textAlign="center"
            >
              Transaction History
            </Typography>
          )}
          <Stack direction="column" width="100%" height="100%" pl={2} pr={2}>
            {transactions
              .filter(
                (t) =>
                  t.wallet.asset.label === "FREE DRAW" ||
                  (t.wallet.asset.type === "GIFT" &&
                    t.type === "TRANSFER_IN") ||
                  t.wallet.asset.label === appState.currency
              )
              .map((transaction, index) => {
                return (
                  <Stack
                    width="100%"
                    gap={1}
                    direction="column"
                    justifyContent="flex-start"
                    pl={2}
                    pr={2}
                    key={index}
                  >
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <Stack direction="column" gap={0.5}>
                        <Typography
                          variant="notice"
                          fontSize={12}
                          fontWeight={400}
                          color="#22171E"
                        >
                          {transaction.wallet.asset.label === "FREE DRAW" &&
                          transaction.metadata?.refType !== "swipe"
                            ? "Free Draw"
                            : transaction.wallet.asset.type === "GIFT"
                            ? "Received Gift"
                            : transaction?.description || "GIft Cash Out"}
                        </Typography>
                        <Typography variant="notice" color="#22171E">
                          {moment(transaction?.updatedAt).format(
                            "DD-MM, h:mm a"
                          )}{" "}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="notice"
                        color={transaction.amount >= 0 ? "#4F9238" : "#DC2D6D"}
                        fontSize={12}
                        fontWeight={700}
                      >
                        {transaction.amount >= 0 ? "+" : "-"}
                        {transaction.wallet.asset.type !== "GIFT" &&
                          (transaction.wallet.asset.label === "FREE DRAW"
                            ? appState.currencySymbol
                            : transaction.wallet.asset.symbol)}{" "}
                        {Math.abs(transaction.amount).toFixed(2)}
                        {transaction.wallet.asset.type === "GIFT" && " 🎁"}
                      </Typography>
                    </Stack>
                    {index !== transactions.length - 1 && (
                      <Stack
                        width="100%"
                        height="1px"
                        sx={{
                          backgroundColor: "#EAEAEA",
                          mb: 1,
                        }}
                      />
                    )}
                    {index === transactions.length - 1 && <Stack height={16} />}
                  </Stack>
                );
              })}
            {hasNextPage && (
              <>
                <CustomButton
                  size="small"
                  text="Load More"
                  variant="outlined"
                  sx={{
                    alignSelf: "center",
                  }}
                  onClick={() => {
                    fetchNextPage();
                  }}
                />
                <Stack pb={2} />
                <img
                  src="/short_line.svg"
                  alt="short_line"
                  style={{
                    width: 200,
                    height: 12,
                    objectFit: "cover",
                    pointerEvents: "none",
                    alignSelf: "center",
                  }}
                />
                <Stack pb={2} />
              </>
            )}
          </Stack>
        </Box>
      </Stack>
      <TopUpModal
        open={openTopup}
        setOpen={setOpenTopup}
        isRequired
        minAmount={minAmount}
        successUrl={`${process.env.REACT_APP_APP_URL}/referral`}
        from="profile"
      />
      <HowItWorksModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        loading={loading}
        setLoading={setLoading}
        setMinAmount={setMinAmount}
        setOpenTopup={setOpenTopup}
        purchaseWingman={purchaseWingman}
      />
      <WingmanSuccessModal
        open={openWingmanSuccess}
        setOpen={setOpenWingmanSuccess}
      />
      <ReferModal
        open={isReferModalOpen}
        setOpen={setIsReferModalOpen}
        setHowItWorksOpen={setIsModalOpen}
        refetch={() => {
          refetchFreeDraw();
          refetchReward();
          localStorage.setItem("canPopFreeDraw", "true");
        }}
      />
      <WithdrawModal
        isWingman={false}
        open={openWithdraw}
        setOpen={setOpenWithdraw}
        activateWingman={() => {
          setIsModalOpen(true);
          setOpenWithdraw(false);
        }}
      />
      <FreeDrawModal
        open={openFreeDraw}
        setOpen={setOpenFreeDraw}
        missions={reward?.missions ?? []}
      />
    </Stack>
  );
};

export default ReferralPage;
