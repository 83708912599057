import { Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import gsap from "gsap";
import { CustomButton } from "../common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { useQuery } from "@tanstack/react-query";
import { getFiatWallet } from "../../api/wallet";
import { createOrder, getProductCatalogs } from "../../api/payment";

type Props = {
  open: boolean;
  setOpen: Function;
  isRequired?: boolean;
  successUrl?: string;
  minAmount?: number;
  from: string;
};

export const TopUpModal: React.FC<Props> = ({
  open,
  setOpen,
  isRequired = true,
  successUrl,
  minAmount = 0,
  from,
}) => {
  const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [selected, setSelected] = useState(0);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const appState = useAppSelector((state) => state.appState);

  const { data: wallet } = useQuery({
    queryKey: ["wallet", user?.uid],
    queryFn: () => getFiatWallet(),
    enabled: !!user?.uid,
  });

  const { data: products } = useQuery({
    queryKey: ["products", "topup"],
    queryFn: () => getProductCatalogs("topup"),
    enabled: !!user?.uid,
  });

  const sortedProducts = useMemo(() => {
    return (products || [])
      ?.filter((a) =>
        a.prices.find((p) => p.currency.label === appState.currency)
      )
      .sort((a, b) => {
        return (a?.metadata?.amount ?? 0) - (b?.metadata?.amount ?? 0);
      });
  }, [products, appState]);

  useEffect(() => {
    if (!backdrop || !container) return;
    if (open) {
      gsap.to(backdrop, {
        visibility: "visible",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: 0,
        overwrite: true,
        duration: 0.05,
      });
    } else {
      gsap.to(backdrop, {
        visibility: "hidden",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: "100%",
        overwrite: true,
        duration: 0.05,
      });
    }
  }, [open, backdrop, container]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        position: "fixed",
        justifyContent: "flex-end",
        top: 0,
        left: 0,
        zIndex: 11,
        alignItems: "center",
        backdropFilter: "blur(5px)",
        visibility: "hidden",
        height: "100dvh",
      }}
      ref={(el) => setBackdrop(el)}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "600px",
          transform: "translateY(100%)",
          borderRadius: "24px 24px 0px 0px",
          background: "white",
          position: "relative",
          cursor: "auto",
          overflowY: "scroll",
          alignItems: "center",
          p: 3,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={(el) => setContainer(el)}
      >
        <Typography variant="headlineMedium" mb={2} textAlign="center">
          {isRequired ? "Top up Required" : "Top up"}
        </Typography>
        <Typography
          variant="headlineSmall"
          mb={2}
          textAlign="center"
          color="#22171E"
          sx={{
            opacity: 0.5,
          }}
        >
          {isRequired
            ? "Please top up your wallet. Remaining balance will be credited to your wallet."
            : "Please select the desired token package to proceed"}
        </Typography>
        <Stack direction="row" gap={2} width="100%" mb={2}>
          {(sortedProducts ?? []).map((product) => {
            return (
              <Stack
                sx={{
                  pt: 5,
                  pb: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "16px",
                  border:
                    selected === product.id
                      ? "1px solid #B4237A"
                      : "1px solid rgba(0, 0, 0, 0.10)",
                  cursor:
                    (product?.metadata?.amount ?? 0) < minAmount
                      ? "auto"
                      : "pointer",
                  opacity:
                    (product?.metadata?.amount ?? 0) < minAmount ? 0.5 : 1,
                  WebkitTapHighlightColor: "rgba(0,0,0,0)",
                }}
                key={product.id}
                flex={1}
                onClick={() => {
                  if ((product?.metadata?.amount ?? 0) < minAmount) return;
                  setSelected(product.id);
                }}
              >
                <Typography variant="bodyLarge" color="#22171E">
                  {product.label}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
        <Typography
          textAlign="center"
          variant="bodySmall"
          color="#22171E"
          sx={{
            opacity: 0.5,
          }}
        >
          Your Balance
        </Typography>
        <Typography
          textAlign="center"
          variant="bodyMedium"
          color="#22171E"
          mb={2}
        >
          {wallet?.asset?.symbol ?? appState.currencySymbol}
          {wallet?.balance?.toFixed(2) ?? "0.00"}
        </Typography>
        <CustomButton
          text={"Top up"}
          size="medium"
          disabled={selected <= 0}
          onClick={async () => {
            const product = (products ?? []).find((p) => p.id === selected);
            if (!product || !product?.prices?.[0]?.currency?.id) {
              dispatch(
                setSnackbar({
                  open: true,
                  message: "Invalid product.",
                  severity: "error",
                })
              );
              return;
            }
            try {
              const order = await createOrder(
                selected,
                product?.prices?.[0]?.currency?.id,
                appState.paymentGatewayCode,
                undefined,
                successUrl
              );
              if (from === "profile")
                localStorage.setItem("profileTopupOrderUid", order.uid);
              else if (from === "main")
                localStorage.setItem("mainTopupOrderUid", order.uid);
              else if (from === "wingman")
                localStorage.setItem("wingmanOrderUid", order.uid);
              else if (from === "chat")
                localStorage.setItem("chatTopupOrderUid", order.uid);
              else localStorage.setItem("orderUid", order.uid); // set order uid to set interval for getting purchase status later on
              const payment = order?.payments?.[0];
              if (!payment) {
                dispatch(
                  setSnackbar({
                    open: true,
                    severity: "error",
                    message: "Payment unsuccessful, please try again later.",
                  })
                );
                return;
              }
              window.location.href = payment?.paymentUrl ?? "";
            } catch (err: any) {
              dispatch(
                setSnackbar({
                  open: true,
                  severity: "error",
                  message:
                    err?.response?.data?.error?.message ??
                    "Error purchasing, please reload the page and try again.",
                })
              );
            }
          }}
          variant="tertiary"
          sx={{
            pl: 6,
            pr: 6,
            pt: "10px",
            pb: "10px",
          }}
        />
      </Stack>
    </Stack>
  );
};
