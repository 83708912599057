import { Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import gsap from "gsap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { useNavigate } from "react-router";
import { CustomTextField } from "../common/CustomTextField";
import { CustomButton } from "../common/CustomButton";
import { useQuery } from "@tanstack/react-query";
import { getFiatWallet } from "../../api/wallet";
import { createWithdrawal } from "../../api/withdrawal";
import { PriceDto } from "../../types/PriceDto";
import { getProductCatalogs } from "../../api/payment";
import { getEarnStats } from "../../api/stats";

type Props = {
  open: boolean;
  setOpen: Function;
  isWingman: boolean;
  activateWingman: () => void;
};

export const WithdrawModal: React.FC<Props> = ({
  open,
  setOpen,
  isWingman,
  activateWingman,
}) => {
  const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [accountName, setAccountName] = useState("");
  const [amount, setAmount] = useState("0.00");
  const user = useAppSelector((state) => state.user.user);
  const appState = useAppSelector((state) => state.appState);
  const [pending, setPending] = useState(false);

  const [showActivateWingman, setShowActivateWingman] = useState(!isWingman);

  const { data: wingmanCatalog } = useQuery({
    queryKey: ["wingmanCatalog"],
    queryFn: () => getProductCatalogs("wingman"),
    enabled: !!user?.uid,
  });

  const wingmanPrice: PriceDto | undefined = useMemo(() => {
    if (!wingmanCatalog?.[0]?.prices?.[0]) return undefined;
    return wingmanCatalog[0].prices[0];
  }, [wingmanCatalog]);

  const { data: wallet, refetch } = useQuery({
    queryKey: ["wallet", user?.uid],
    queryFn: () => getFiatWallet(),
    enabled: !!user?.uid,
  });

  const { data: stat } = useQuery({
    queryKey: ["stat", user?.uid],
    queryFn: () => getEarnStats(user.uid),
    enabled: !!user?.uid,
  });

  const referralEarnings = stat?.referralEarnings ?? 0;
  const validBalance = Math.max(
    0,
    isWingman ? wallet?.balance ?? 0 : (wallet?.balance ?? 0) - referralEarnings
  );

  useEffect(() => {
    setShowActivateWingman(!isWingman);
  }, [isWingman]);

  useEffect(() => {
    if (!referralEarnings && showActivateWingman) {
      setShowActivateWingman(false);
    }
  }, [referralEarnings, showActivateWingman]);

  const allowed = useMemo(() => {
    const validateEmail = (email: string) => {
      return email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const hasPhoneOrEmail = !!phone || !!validateEmail(email);

    if (
      !amount ||
      !validBalance ||
      !hasPhoneOrEmail ||
      (!accountName && appState.withdrawalMethod === "TNG")
    ) {
      return false;
    }
    return +amount <= validBalance && +amount >= 1;
  }, [validBalance, amount, phone, accountName, email]);

  const exceededLimit = useMemo(() => {
    if (!validBalance) return true;
    return +amount > validBalance;
  }, [validBalance, amount]);

  useEffect(() => {
    if (!backdrop || !container) return;
    if (open) {
      gsap.to(backdrop, {
        visibility: "visible",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: 0,
        overwrite: true,
        duration: 0.05,
      });
    } else {
      gsap.to(backdrop, {
        visibility: "hidden",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: "100%",
        overwrite: true,
        duration: 0.05,
      });
    }
  }, [open, backdrop, container]);

  useEffect(() => {
    if (appState.withdrawalMethod !== "TNG") return;
    if (!user?.email) return;
    setPhone(user.email.substring(1, user.email.indexOf("@")));
  }, [user?.email]);

  if (showActivateWingman) {
    return (
      <Stack
        direction="column"
        width="100%"
        sx={{
          position: "fixed",
          justifyContent: "flex-end",
          top: 0,
          left: 0,
          zIndex: 10,
          alignItems: "center",
          backdropFilter: "blur(5px)",
          visibility: "hidden",
          height: "100dvh",
        }}
        ref={(el) => setBackdrop(el)}
        onClick={(e) => {
          e.stopPropagation();
          setShowActivateWingman(!isWingman);
          setOpen(false);
        }}
      >
        <Stack
          direction="column"
          sx={{
            width: "100%",
            maxWidth: "600px",
            transform: "translateY(100%)",
            borderRadius: "24px 24px 0px 0px",
            background: "white",
            position: "relative",
            cursor: "auto",
            overflowY: "scroll",
            alignItems: "center",
            p: 3,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={(el) => setContainer(el)}
        >
          <Typography variant="headlineMedium" mb={2} textAlign="center">
            Withdraw
          </Typography>

          <Typography variant="bodySmall" mb={2} textAlign="center">
            You have
          </Typography>
          <img
            src="/short_line.svg"
            alt="short_line"
            style={{
              width: 200,
              height: 12,
              objectFit: "cover",
              pointerEvents: "none",
              marginBottom: 16,
              transform: "rotate(180deg)",
            }}
          />
          <Typography
            textAlign="center"
            variant="headlineMedium"
            color="#22171E"
            mb={2}
          >
            {wallet?.asset?.symbol ?? appState.currencySymbol}{" "}
            {referralEarnings.toFixed(2)}
          </Typography>
          <img
            src="/short_line.svg"
            alt="short_line"
            style={{
              width: 200,
              height: 12,
              objectFit: "cover",
              pointerEvents: "none",
              marginBottom: 16,
            }}
          />
          <Typography variant="bodyMedium" mb={2} textAlign="center">
            in referral earnings!
          </Typography>
          <Typography variant="bodyMedium" mb={2} textAlign="center">
            Activate Wingman Mode to unlock now!
          </Typography>

          <CustomButton
            text={`Activate for ${wingmanPrice?.currency?.symbol} ${(
              wingmanPrice?.rate ?? 0
            ).toFixed(2)}`}
            sx={{
              pt: 1.25,
              pb: 1.25,
              mb: 4,
            }}
            size="medium"
            onClick={async () => {
              activateWingman();
            }}
          />

          <Typography
            variant="caption"
            mb={2}
            textAlign="center"
            onClick={() => {
              setShowActivateWingman(false);
            }}
            sx={{
              cursor: "pointer",
            }}
          >
            Skip, I only want to withdraw other earnings
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        position: "fixed",
        justifyContent: "flex-end",
        top: 0,
        left: 0,
        zIndex: 10,
        alignItems: "center",
        backdropFilter: "blur(5px)",
        visibility: "hidden",
        height: "100dvh",
      }}
      ref={(el) => setBackdrop(el)}
      onClick={(e) => {
        e.stopPropagation();
        setShowActivateWingman(!isWingman);
        setOpen(false);
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "600px",
          transform: "translateY(100%)",
          borderRadius: "24px 24px 0px 0px",
          background: "white",
          position: "relative",
          cursor: "auto",
          overflowY: "scroll",
          alignItems: "center",
          p: 3,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={(el) => setContainer(el)}
      >
        <Typography variant="headlineMedium" mb={2} textAlign="center">
          Withdraw
        </Typography>
        <img
          src="/short_line.svg"
          alt="short_line"
          style={{
            width: 118,
            height: 12,
            objectFit: "cover",
            pointerEvents: "none",
            marginBottom: 16,
          }}
        />
        <Typography
          textAlign="center"
          variant="bodySmall"
          color="#22171E"
          sx={{
            opacity: 0.5,
          }}
        >
          Withdrawable balance
        </Typography>
        <Typography
          textAlign="center"
          variant="bodyMedium"
          color="#22171E"
          mb={2}
        >
          {wallet?.asset?.symbol ?? appState.currencySymbol}
          {validBalance.toFixed(2)}
        </Typography>
        {pending && (
          <Stack
            height={300}
            alignItems="center"
            justifyContent="center"
            width="100%"
            pl={4}
            pr={4}
          >
            <Typography variant="headlineSmall" textAlign="center">
              Your withdrawal request has been submitted. Please allow 3-5
              working days to credit to your account.
            </Typography>
          </Stack>
        )}
        {!pending && (
          <>
            {appState.withdrawalMethod === "TNG" && (
              <>
                <CustomTextField
                  placeholder="Phone Number"
                  value={phone}
                  setValue={setPhone}
                  variant="normal"
                  sx={{
                    mb: 2,
                  }}
                  type="number"
                  prefix="+"
                />
                <CustomTextField
                  placeholder="TnG Account Name"
                  value={accountName}
                  setValue={setAccountName}
                  variant="normal"
                  sx={{
                    mb: 2,
                  }}
                />
              </>
            )}

            {appState.withdrawalMethod === "AMAZON" && (
              <>
                <CustomTextField
                  placeholder="Email to receive Gift Card"
                  value={email}
                  setValue={setEmail}
                  variant="normal"
                  sx={{
                    mb: 2,
                  }}
                />
              </>
            )}

            <CustomTextField
              placeholder={`Withdrawal Amount (${wallet?.asset?.symbol})`}
              value={amount}
              setValue={(v) => {
                let tmp = v.match(/[0-9]+/g)?.join("") ?? "000";
                tmp = tmp.padStart(3, "0");
                tmp =
                  tmp.slice(0, tmp.length - 2) +
                  "." +
                  tmp.slice(tmp.length - 2);
                setAmount((+tmp).toFixed(2));
              }}
              variant="normal"
              sx={{
                mb: 2,
              }}
              type="number"
            />
            {(!amount || exceededLimit) && (
              <Typography
                variant="notice"
                sx={{
                  mb: 2,
                }}
                color="#F00"
                fontSize={14}
                fontWeight={400}
                alignSelf="flex-start"
              >
                {!amount
                  ? `You can withdraw up to ${
                      wallet?.asset?.symbol ?? appState.currencySymbol
                    }${validBalance.toFixed(2) ?? "0.00"}`
                  : "Your withdrawal amount cannot exceed your wallet balance"}
              </Typography>
            )}
            <CustomButton
              text="Confirm"
              sx={{
                width: "100%",
                pt: 1.25,
                pb: 1.25,
                mb: 6,
              }}
              disabled={!allowed}
              size="medium"
              onClick={async () => {
                try {
                  await createWithdrawal(
                    appState.withdrawalMethod,
                    +amount,
                    accountName,
                    {
                      currency: wallet?.asset?.label,
                      phone: phone ? `+${phone}` : undefined,
                      email: email ? email : undefined,
                    }
                  );
                  setPending(true);
                  setPhone("");
                  setEmail("");
                  setAmount("");
                  setAccountName("");
                  refetch();
                } catch (err: any) {
                  dispatch(
                    setSnackbar({
                      open: true,
                      message:
                        err?.response?.data?.error?.message ??
                        "Error creating withdrawal.",
                      severity: "error",
                    })
                  );
                }
              }}
            />
          </>
        )}
        <Typography
          sx={{
            cursor: "pointer",
          }}
          alignSelf="center"
          variant="notice"
          fontSize={14}
          fontWeight={400}
          onClick={() => {
            setShowActivateWingman(!isWingman);
            setOpen(false);
          }}
        >
          Close
        </Typography>
      </Stack>
    </Stack>
  );
};
