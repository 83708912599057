import { useEffect } from "react";
// @ts-ignore
import TagManager from "react-gtm-module";
import { useLocation } from "react-router";
import { useAppSelector } from "../redux/hooks";
import { UserDto } from "../types/UserDto";

const TRACKING_ID = process.env.REACT_APP_GTM_ID;

export function initialize(user: UserDto | null | undefined) {
  if (TRACKING_ID) {
    TagManager.initialize({
      gtmId: TRACKING_ID || "",
      auth: process.env.REACT_APP_GTM_AUTH || "",
      preview: process.env.REACT_APP_GTM_PREVIEW || "",
      ...(user?.uid
        ? {
            dataLayer: {
              userId: user.uid,
            },
          }
        : {}),
    });
  }
}

export function pageview() {
  if (TRACKING_ID) {
    setTimeout(() => {
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          url: window.location.pathname + window.location.search,
          pageTitle: document.title,
        },
      });
    }, 500);
  }
}

export const useGtm = () => {
  const location = useLocation();
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (typeof window !== "undefined") {
      initialize(user);
      pageview();
    }
  }, [user]);

  useEffect(() => {
    pageview();
  }, [location.pathname]);
};
