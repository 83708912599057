import { Stack, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

type Props = {
  primaryText: string;
  secondaryText: string;
};

export const ChatRoomBanner: React.FC<Props> = ({
  primaryText,
  secondaryText,
}) => {
  return (
    <Stack
      direction="row"
      paddingY={1}
      sx={{
        backgroundColor: "black",
        gap: 0.5,
      }}
      position={"relative"}
      zIndex={1}
    >
      <Stack
        direction={"column"}
        paddingY={1}
        px={4}
        sx={{
          backgroundColor: "black",
        }}
        paddingRight={"30%"}
      >
        <Typography variant="bodyMedium" textAlign="left" color={"white"}>
          {primaryText}
        </Typography>
        <Typography
          textAlign="left"
          variant="notice"
          color={"white"}
          fontSize={12}
        >
          {secondaryText}
        </Typography>
      </Stack>
      <img
        src="/gift.png"
        alt="gift"
        style={{
          right: "3%",
          top: "-6%",
          position: "absolute",
          objectFit: "contain",
          width: 100,
        }}
      />
    </Stack>
  );
};
