import { Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import gsap from "gsap";
import { MissionDto } from "../../types/MissionDto";
import { useNavigate } from "react-router";
import { CustomButton } from "../common/CustomButton";

type Props = {
  open: boolean;
  setOpen: Function;
  missions: MissionDto[];
};

export const FreeDrawModal: React.FC<Props> = ({ open, setOpen, missions }) => {
  const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!backdrop || !container) return;
    if (open) {
      gsap.to(backdrop, {
        visibility: "visible",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: 0,
        overwrite: true,
        duration: 0.05,
      });
    } else {
      gsap.to(backdrop, {
        visibility: "hidden",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: "100%",
        overwrite: true,
        duration: 0.05,
      });
    }
  }, [open, backdrop, container]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        position: "fixed",
        justifyContent: "center",
        top: 0,
        left: 0,
        zIndex: 10,
        alignItems: "center",
        visibility: "hidden",
        height: "100dvh",
      }}
      ref={(el) => setBackdrop(el)}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
    >
      <Stack
        direction="column"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        sx={{
          backdropFilter: "blur(5px)",
        }}
      >
        <Stack
          direction="column"
          sx={{
            transform: "translateY(100%)",
            borderRadius: "16px",
            background: "white",
            position: "relative",
            cursor: "auto",
            alignItems: "center",
            pl: 6,
            pr: 6,
            pb: 3,
            mt: 3,
            overflow: "visible",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={(el) => setContainer(el)}
        >
          <img
            src="/close.svg"
            alt="close"
            style={{
              width: 16,
              height: 16,
              cursor: "pointer",
              color: "black",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
              position: "absolute",
              top: -16,
              right: -16,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
          />
          <img
            src="/popup_thumbnail.png"
            alt="popup"
            width={200}
            height={200}
            style={{
              objectFit: "contain",
              width: 200,
              height: 200,
              marginTop: -48,
              pointerEvents: "none",
            }}
          />
          <Typography variant="headlineMedium" mb={3} textAlign="center">
            Free Draw
            <br />
            Available!
          </Typography>
          <img
            src="/short_line.svg"
            alt="short_line"
            style={{
              width: 100,
              height: 12,
              objectFit: "cover",
              pointerEvents: "none",
              marginBottom: 24,
            }}
          />
          <CustomButton
            text="Draw Now"
            variant="tertiary"
            sx={{ mb: 3 }}
            onClick={() => {
              setOpen(false);
              navigate("/");
            }}
          />
          <Stack direction="row" alignItems="center" gap={1.5} mb={3}>
            <img
              src="/left_line.svg"
              alt="left_line"
              style={{
                width: 45,
                height: 12,
                objectFit: "contain",
                pointerEvents: "none",
              }}
            />
            <Typography variant="bodySmall" color="#22171E">
              get more free draws
            </Typography>
            <img
              src="/right_line.svg"
              alt="right_line"
              style={{
                width: 45,
                height: 12,
                objectFit: "contain",
                pointerEvents: "none",
              }}
            />
          </Stack>
          {missions.map((mission, index) => {
            return (
              <Fragment key={index}>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  mb={3}
                  sx={{
                    cursor: mission.completionCount === 0 ? "pointer" : "auto",
                  }}
                  onClick={() => {
                    if (mission.completionCount > 0) return;
                    navigate(mission.cta ?? "/");
                  }}
                >
                  <Typography
                    variant="headlineSmall"
                    color="#22171E"
                    sx={{
                      textDecoration:
                        mission.completionCount > 0
                          ? "line-through"
                          : undefined,
                    }}
                  >
                    {mission.title}
                  </Typography>
                  {mission.completionCount === 0 && (
                    <img
                      src="/arrow_right.svg"
                      alt="arrow_right"
                      style={{
                        width: 12,
                        height: 12,
                        objectFit: "contain",
                      }}
                    />
                  )}
                </Stack>
              </Fragment>
            );
          })}
          <img
            src="/short_line.svg"
            alt="short_line"
            style={{
              width: 100,
              height: 12,
              objectFit: "cover",
              pointerEvents: "none",
              transform: "rotateX(180deg)",
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
