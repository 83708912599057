import { Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { createPost } from "../../api/post";
import { getInfoTemplate } from "../../api/template";
import { getUser } from "../../api/user";
import { Permission, useAuth } from "../../hooks/useAuth";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { setUser } from "../../redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { UserDto } from "../../types/UserDto";
import { Option } from "../common/CustomAutocomplete";
import { CustomButton } from "../common/CustomButton";
import { CustomCard } from "../common/CustomCard";
import { CustomSelectField } from "../common/CustomSelectField";

type Props = {};

export const UniPage: React.FC<Props> = () => {
  const user = useAppSelector((state) => state.user.user);
  const appState = useAppSelector((state) => state.appState);
  useAuth(Permission.USER_ONLY);
  const [school, setSchool] = useState("");
  const [job, setJob] = useState("");
  const [religion, setReligion] = useState("");
  const [height, setHeight] = useState("");
  const [lang, setLang] = useState<Option[]>([]);
  const [schoolOptions, setSchoolOptions] = useState<Option[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: infoFields } = useQuery({
    queryKey: ["infoFields"],
    queryFn: () => getInfoTemplate(),
    enabled: !!user?.uid,
  });

  useEffect(() => {
    if (!infoFields) return;
    const schoolInfo = infoFields.find(
      (f: any) => f.key === `school_${appState.country}`
    );
    if (!!schoolInfo?.options) {
      setSchoolOptions(
        schoolInfo?.options?.map((opt: any) => ({
          value: opt.value,
          label: opt.label,
        }))
      );
    }
  }, [infoFields]);

  const infoPost = useMemo(() => {
    return user.posts?.find((p) => p.section === "INFO");
  }, [user]);

  useEffect(() => {
    setJob(infoPost?.content?.job ?? "");
    setHeight(infoPost?.content?.height ?? "");
    setReligion(infoPost?.content?.religion ?? "");
    setLang(
      (infoPost?.content?.languages ?? []).map((s: string) => ({
        value: s,
        label: s,
      }))
    );
  }, [infoPost]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
    >
      <CustomCard title="Your Delulu Details">
        <Typography variant="headlineSmall" textAlign="center" mb={1}>
          What is your university?
        </Typography>
        <CustomSelectField
          placeholder=""
          value={school}
          setValue={(v) => {
            setSchool(v.toString());
          }}
          options={schoolOptions}
        />
        <CustomButton
          text="Next"
          variant="outlined"
          size="medium"
          disabled={!school}
          onClick={async () => {
            if (!user?.uid) return;
            try {
              await createPost("INFO", {
                job: job,
                height: height,
                school: school,
                religion: religion,
                languages: lang.map((l) => l.value),
              });
              // get user
              const newUser: UserDto = await getUser(user.uid);
              dispatch(setUser(newUser));
              navigate("/notification");
            } catch (err: any) {
              dispatch(
                setSnackbar({
                  open: true,
                  message:
                    err?.response?.data?.error?.message ??
                    "Error updating university.",
                  severity: "error",
                })
              );
            }
          }}
          sx={{
            mt: 2,
            mb: 4,
          }}
        />
      </CustomCard>
    </Stack>
  );
};
