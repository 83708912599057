import { Stack, Typography } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Fragment, useEffect, useMemo, useState } from "react";
import { UserDto } from "../../types/UserDto";

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

type Props = {
  user: UserDto | undefined;
};

export const ProfileImages: React.FC<Props> = ({ user }) => {
  const [height, setHeight] = useState(0);
  const [el, setEl] = useState<HTMLUListElement | null>(null);

  const itemData = useMemo(() => {
    const defaultItemData = [
      {
        img: "/photo1.png",
        title: "Photo 1",
        rows: 1,
        cols: 2,
        interests: [] as string[],
      },
      {
        img: "/photo2.png",
        title: "Photo 2",
        rows: 1,
        cols: 1,
        interests: [] as string[],
      },
      {
        img: "/photo3.png",
        title: "Photo 3",
        rows: 1,
        cols: 1,
        interests: [] as string[],
      },
      {
        img: "/photo4.png",
        title: "Photo 4",
        rows: 1,
        cols: 2,
        interests: [] as string[],
      },
      {
        img: "/new_male.png",
        title: "Avatar",
        rows: 3,
        cols: 2,
        interests: [] as string[],
      },
      {
        rows: 2,
        cols: 1,
        img: "/wingman_bg.png",
        title: "Interests",
        interests: [] as string[],
      },
      {
        img: "/photo5.png",
        title: "Photo 5",
        rows: 1,
        cols: 1,
        interests: [] as string[],
      },
      {
        img: "/photo6.png",
        title: "Photo 6",
        rows: 1,
        cols: 1,
        interests: [] as string[],
      },
      {
        img: "/photo7.png",
        title: "Photo 7",
        rows: 1,
        cols: 2,
        interests: [] as string[],
      },
    ];
    defaultItemData.forEach((data, index) => {
      if (index < 4) {
        const photoPost = user?.posts?.find(
          (p) => p.section === `PHOTO${index + 1}`
        );
        if (!!photoPost?.url) data.img = photoPost.url;
      } else if (index === 4) {
        data.img =
          user?.avatar ??
          (user?.gender === "M" ? "/new_male.png" : "/new_female.png");
      } else if (index === 5) {
        data.interests = user?.interests?.map((inter) => inter.label) ?? [];
      } else if (index >= 6 && index <= 8) {
        const photoPost = user?.posts?.find(
          (p) => p.section === `PHOTO${index - 1}`
        );
        if (!!photoPost?.url) data.img = photoPost.url;
      }
    });
    return defaultItemData;
  }, [user?.posts, user?.avatar, user?.interests]);

  useEffect(() => {
    if (!el) return;
    setHeight(Math.max(el.offsetHeight / 6 - 8, 80));
  }, [el?.offsetHeight]);

  return (
    <ImageList
      sx={{ width: "100%", height: "100%" }}
      variant="quilted"
      cols={3}
      rowHeight={height}
      gap={8}
      ref={(e) => setEl(e)}
    >
      {itemData.map((item, index) => (
        <ImageListItem
          key={item.img + index}
          cols={item.cols || 1}
          rows={item.rows || 1}
        >
          {!!item.interests?.length ? (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              gap={0.5}
              width="100%"
              height="100%"
              sx={{
                background: "rgba(0, 0, 0, 0.10)",
                borderRadius: "16px",
              }}
            >
              <img
                src="/short_line.svg"
                alt="short_line"
                style={{
                  width: 36,
                  height: 4,
                  objectFit: "cover",
                  pointerEvents: "none",
                }}
              />
              {item.interests.map((inter) => (
                <Fragment key={inter}>
                  <Typography variant="bodySmall" textAlign="center">
                    {inter}
                  </Typography>
                </Fragment>
              ))}
              <img
                src="/short_line.svg"
                alt="short_line"
                style={{
                  width: 36,
                  height: 4,
                  objectFit: "cover",
                  pointerEvents: "none",
                  transform: "translateX(180deg)",
                }}
              />
            </Stack>
          ) : (
            <img
              {...srcset(item.img, 121, item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
              style={{
                borderRadius: "16px",
                pointerEvents: "none",
                objectFit: "cover",
              }}
            />
          )}
        </ImageListItem>
      ))}
    </ImageList>
  );
};
