import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import gsap from "gsap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CustomButton } from "../common/CustomButton";

type Props = {
  open: boolean;
  setOpen: Function;
};

export const PaymentSuccessModal: React.FC<Props> = ({ open, setOpen }) => {
  const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (!backdrop || !container) return;
    if (open) {
      gsap.to(backdrop, {
        visibility: "visible",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: 0,
        overwrite: true,
        duration: 0.05,
      });
    } else {
      gsap.to(backdrop, {
        visibility: "hidden",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: "100%",
        overwrite: true,
        duration: 0.05,
      });
    }
  }, [open, backdrop, container]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        position: "fixed",
        justifyContent: "flex-end",
        top: 0,
        left: 0,
        zIndex: 12,
        alignItems: "center",
        backdropFilter: "blur(5px)",
        visibility: "hidden",
        height: "100dvh",
      }}
      ref={(el) => setBackdrop(el)}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "600px",
          transform: "translateY(100%)",
          borderRadius: "24px 24px 0px 0px",
          background: "white",
          position: "relative",
          cursor: "auto",
          overflowY: "scroll",
          alignItems: "center",
          p: 3,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={(el) => setContainer(el)}
      >
        <Typography variant="headlineMedium" mb={2} textAlign="center">
          Payment Success!
        </Typography>
        <img
          src="/short_line.svg"
          alt="short_line"
          style={{
            width: 184,
            height: 12,
            objectFit: "cover",
            pointerEvents: "none",
            marginBottom: 16,
          }}
        />
        <Typography
          variant="bodyMedium"
          color="#22171E"
          textAlign="center"
          mb={2}
        >
          Start drawing now
        </Typography>
        <img
          src="/short_line.svg"
          alt="short_line"
          style={{
            width: 184,
            height: 12,
            objectFit: "cover",
            pointerEvents: "none",
            marginBottom: 16,
            transform: "rotateX(180deg)",
          }}
        />
        <CustomButton
          text="Dismiss"
          onClick={() => {
            setOpen(false);
          }}
          size="small"
          variant="primary"
          sx={{
            mb: 2,
            width: 200,
          }}
        />
      </Stack>
    </Stack>
  );
};
