import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import OneSignal from "react-onesignal";
import { useLocation, useNavigate } from "react-router";
import { useAppSelector } from "../../redux/hooks";

export const BottomMenuBar = () => {
  const location = useLocation();
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();

  const isVisible = useMemo(() => {
    if (!user?.uid) return false;
    return [
      "/",
      "/wingman",
      "/chat",
      "/referral",
      "/profile",
      "/settings",
    ].includes(location.pathname);
  }, [user, location.pathname]);

  if (!isVisible) return <></>;

  return (
    <Stack
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
        pb: 2,
      }}
      direction="row"
    >
      <Stack
        direction="column"
        gap={0.5}
        alignItems="center"
        sx={{
          cursor: "pointer",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        <Stack
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            backgroundColor:
              location.pathname === "/" || location.pathname === "/wingman"
                ? "white"
                : "black",
            justifyContent: "center",
            alignItems: "center",
            mt: "6px",
          }}
        >
          <img
            src="/menu_draw.png"
            alt="draw"
            width={20}
            height={20}
            style={{
              objectFit: "contain",
              width: 20,
              height: 20,
            }}
          />
        </Stack>
        <Typography
          textAlign="center"
          variant="bodySmall"
          color={
            location.pathname === "/" || location.pathname === "/wingman"
              ? "white"
              : "rgba(255,255,255,0.5)"
          }
          mb="6px"
        >
          Draw
        </Typography>
      </Stack>
      <Stack
        direction="column"
        gap={0.5}
        alignItems="center"
        sx={{
          cursor: "pointer",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
        }}
        onClick={() => {
          navigate("/chat");
        }}
      >
        <Stack
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            backgroundColor: location.pathname === "/chat" ? "white" : "black",
            justifyContent: "center",
            alignItems: "center",
            mt: "6px",
          }}
        >
          <img
            src="/menu_chat.png"
            alt="chat"
            width={20}
            height={20}
            style={{
              objectFit: "contain",
              width: 20,
              height: 20,
            }}
          />
        </Stack>
        <Typography
          textAlign="center"
          variant="bodySmall"
          color={
            location.pathname === "/chat" ? "white" : "rgba(255,255,255,0.5)"
          }
          mb="6px"
        >
          Chat
        </Typography>
      </Stack>
      <Stack
        direction="column"
        gap={0.5}
        alignItems="center"
        sx={{
          cursor: "pointer",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
        }}
        onClick={() => {
          navigate("/referral");
        }}
      >
        <Stack
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            backgroundColor:
              location.pathname === "/referral" ? "white" : "black",
            justifyContent: "center",
            alignItems: "center",
            mt: "6px",
          }}
        >
          <img
            src="/menu_earn.png"
            alt="earn"
            width={20}
            height={20}
            style={{
              objectFit: "contain",
              width: 20,
              height: 20,
            }}
          />
        </Stack>
        <Typography
          textAlign="center"
          variant="bodySmall"
          color={
            location.pathname === "/referral"
              ? "white"
              : "rgba(255,255,255,0.5)"
          }
          mb="6px"
        >
          Earn
        </Typography>
      </Stack>
      <Stack
        direction="column"
        gap={0.5}
        alignItems="center"
        sx={{
          cursor: "pointer",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
        }}
        onClick={() => {
          navigate("/profile");
        }}
      >
        <Stack
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            backgroundColor:
              location.pathname === "/profile" ||
              location.pathname === "/settings"
                ? "white"
                : "black",
            justifyContent: "center",
            alignItems: "center",
            mt: "6px",
          }}
        >
          <img
            src="/menu_profile.png"
            alt="profile"
            width={20}
            height={20}
            style={{
              objectFit: "contain",
              width: 20,
              height: 20,
            }}
          />
        </Stack>
        <Typography
          textAlign="center"
          variant="bodySmall"
          color={
            location.pathname === "/profile" ||
            location.pathname === "/settings"
              ? "white"
              : "rgba(255,255,255,0.5)"
          }
          mb="6px"
        >
          Profile
        </Typography>
      </Stack>
    </Stack>
  );
};
