import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { Permission, useAuth } from "../../hooks/useAuth";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { useAppDispatch } from "../../redux/hooks";
import { CustomButton } from "../common/CustomButton";

export const LocationPage = () => {
  useAuth(Permission.USER_ONLY);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/dull_bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        gap: 2,
        justifyContent: "center",
        pt: 6,
        pb: 11,
        height: "100dvh",
      }}
    >
      <Stack
        sx={{
          width: "calc(100% - 12px - 12px)",
          height: "100%",
          borderRadius: "16px",
          background: "white",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
          alignItems: "center",
          maxWidth: 600,
        }}
      >
        <img
          src="/sep.svg"
          height={20}
          width={118}
          alt="sep"
          style={{
            objectFit: "contain",
            marginBottom: 16,
            marginTop: 16,
          }}
        />
        <img
          src="/map.svg"
          alt="Map"
          style={{
            width: 72,
            height: 72,
            objectFit: "contain",
            marginBottom: 24,
          }}
        />
        <Typography
          variant="headlineLarge"
          color="#22171E"
          textAlign="center"
          mb={1}
        >
          Location Access
        </Typography>
        <Typography
          variant="bodySmall"
          color="#22171E"
          textAlign="center"
          mb={3}
        >
          so we can find your friends
        </Typography>
        <img
          src="/line.svg"
          alt="line"
          style={{
            width: "100%",
            height: "12px",
            objectFit: "cover",
            marginBottom: 16,
          }}
        />
        <Stack
          direction="column"
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          <Stack
            p={3}
            sx={{
              background: "url(/container.svg)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <CustomButton
              variant="tertiary"
              text="Enable Location Access"
              size="medium"
              sx={{
                pt: 1.5,
                pb: 1.5,
                mt: 3,
                mb: 3,
              }}
              onClick={() => {
                try {
                  if (!navigator.geolocation) {
                    dispatch(
                      setSnackbar({
                        open: true,
                        message:
                          "Geolocation is not supported by your browser.",
                        severity: "error",
                      })
                    );
                    navigate("/");
                  } else {
                    navigator.geolocation.getCurrentPosition(
                      (pos) => {
                        navigate("/");
                      },
                      (err: any) => {
                        dispatch(
                          setSnackbar({
                            open: true,
                            message:
                              "Location permission is not granted, you may go to website settings and grant the location permission manually.",
                            severity: "error",
                          })
                        );
                        navigate("/");
                      }
                    );
                  }
                } catch (err: any) {
                  dispatch(
                    setSnackbar({
                      open: true,
                      message:
                        "Location permission is not granted, you may go to website settings and grant the location permission manually.",
                      severity: "error",
                    })
                  );
                  navigate("/");
                }
              }}
            />
          </Stack>
        </Stack>
        <img
          src="/sep.svg"
          height={20}
          width={118}
          alt="sep"
          style={{
            objectFit: "contain",
            marginBottom: 16,
            marginTop: 16,
          }}
        />
      </Stack>
    </Stack>
  );
};
