import { Stack, Typography } from "@mui/material";
import { Permission, useAuth } from "../../hooks/useAuth";
import { PwaReminder } from "../PwaReminder/PwaReminder";

type Props = {
  setComingSoon: (v: boolean) => void;
};

export const ComingSoonPage: React.FC<Props> = ({ setComingSoon }) => {
  useAuth(Permission.USER_ONLY);
  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/coming_soon.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
    >
      <Typography
        textAlign="center"
        whiteSpace="pre-line"
        variant="heroLarge"
        mb={3}
      >
        {"Delulu\nis the\nSolulu"}
      </Typography>
      <img
        src="/sep_white.svg"
        alt="sep"
        width={118}
        height={20}
        style={{
          objectFit: "contain",
          marginBottom: 16,
        }}
      />
      {/* TODO: Remove event */}
      <Typography
        variant="headlineMedium"
        textAlign="center"
        color="white"
        mb={2}
        onClick={() => {
          // setComingSoon(false);
        }}
      >
        Coming Soon
      </Typography>{" "}
      <img
        src="/sep_white.svg"
        alt="sep"
        width={118}
        height={20}
        style={{
          objectFit: "contain",
        }}
      />
      <PwaReminder />
    </Stack>
  );
};
