import { FC, useEffect, useMemo, useState } from "react";
import { Modal, Box, Typography, Stack } from "@mui/material";
import { CustomButton } from "../common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getProductCatalogs } from "../../api/payment";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { useQuery } from "@tanstack/react-query";
import { getFiatWallet } from "../../api/wallet";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { PriceDto } from "../../types/PriceDto";

interface CarouselItem {
  image: string;
  title: JSX.Element;
}

const items: CarouselItem[] = [
  {
    image: "/how_it_works_3.png",
    title: (
      <Typography variant="bodyLarge" textAlign="center" height={140}>
        Start earning with Delulu. Use your unique invite link to invite your
        friends to Delulu
      </Typography>
    ),
  },
  {
    image: "/how_it_works_4.png",
    title: (
      <Typography variant="bodyLarge" textAlign="center" height={140}>
        Everyone who registers using your invite link is your {""}
        <Typography variant="bodyLarge" textAlign="center" color={"#137CA3"}>
          Referee
        </Typography>
      </Typography>
    ),
  },
  {
    image: "/how_it_works_5.png",
    title: (
      <Typography variant="bodyLarge" textAlign="center" height={140}>
        If{" "}
        <Typography variant="bodyLarge" textAlign="center" color={"#137CA3"}>
          Referee{" "}
        </Typography>
        draws cards, you get{" "}
        <Typography variant="bodyLarge" textAlign="center" color={"#137CA3"}>
          28%{" "}
        </Typography>
        of the amount spent
      </Typography>
    ),
  },
  {
    image: "/how_it_works_6.png",
    title: (
      <Typography variant="bodyLarge" textAlign="center" height={140}>
        If{" "}
        <Typography variant="bodyLarge" textAlign="center" color={"#137CA3"}>
          Referee{" "}
        </Typography>
        gets drawn, you get{" "}
        <Typography variant="bodyLarge" textAlign="center" color={"#137CA3"}>
          7%{" "}
        </Typography>
        of the amount spent
      </Typography>
    ),
  },
  {
    image: "/how_it_works_7.png",
    title: (
      <Typography variant="bodyLarge" textAlign="center" height={140}>
        If{" "}
        <Typography variant="bodyLarge" textAlign="center" color={"#137CA3"}>
          Referee{" "}
        </Typography>
        becomes a wingman like you...
      </Typography>
    ),
  },
  {
    image: "/how_it_works_8.png",
    title: (
      <Typography variant="bodyMedium" textAlign="center" height={140}>
        You get{" "}
        <Typography variant="bodyMedium" textAlign="center" color={"#137CA3"}>
          10%{" "}
        </Typography>
        and{" "}
        <Typography variant="bodyMedium" textAlign="center" color={"#137CA3"}>
          2.5%{" "}
        </Typography>
        respectively when their referees draw cards or get drawn.
      </Typography>
    ),
  },
  {
    image: "/how_it_works_9.png",
    title: (
      <Typography variant="bodyMedium" textAlign="center" height={140}>
        If your{" "}
        <Typography variant="bodyMedium" textAlign="center" color={"#137CA3"}>
          Referee&apos;s{" "}
        </Typography>
        referee becomes a wingman, you get{" "}
        <Typography variant="bodyMedium" textAlign="center" color={"#137CA3"}>
          2%{" "}
        </Typography>
        and{" "}
        <Typography variant="bodyMedium" textAlign="center" color={"#137CA3"}>
          0.5%{" "}
        </Typography>
        respectively when their referees spend or get drawn
      </Typography>
    ),
  },
];

const HowItWorksModal: FC<{
  open: boolean;
  handleClose: () => void;
  loading: boolean;
  setMinAmount: Function;
  setOpenTopup: Function;
  setLoading: Function;
  purchaseWingman: Function;
}> = ({
  open,
  handleClose,
  loading,
  setMinAmount,
  setOpenTopup,
  setLoading,
  purchaseWingman,
}) => {
  const user = useAppSelector((state) => state.user.user);
  const appState = useAppSelector((state) => state.appState);
  const [disabled, setDisabled] = useState(true);

  const { data: wingmanCatalog } = useQuery({
    queryKey: ["wingmanCatalog"],
    queryFn: () => getProductCatalogs("wingman"),
    enabled: !!user?.uid,
  });

  const wingmanPrice: PriceDto | undefined = useMemo(() => {
    return (wingmanCatalog || [])[0]?.prices.find(
      (p) => p.currency.label === appState.currency
    );
  }, [wingmanCatalog, appState]);

  useEffect(() => {
    const comingSoon = process.env.REACT_APP_COMING_SOON;
    if (comingSoon === "false") setDisabled(false);
  }, []);

  const { data: wallet } = useQuery({
    queryKey: ["wallet", user?.uid],
    queryFn: () => getFiatWallet(),
    enabled: !!user?.uid,
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(3px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        marginX={"auto"}
        width="90%"
        maxWidth={550}
        borderRadius={4}
        display={"flex"}
        flexDirection={"column"}
        sx={{
          backgroundColor: "white",
        }}
      >
        <Typography
          textAlign="center"
          variant="bodyLarge"
          paddingY={2}
          paddingX={4}
          fontSize={24}
        >
          Be A Wingman
        </Typography>
        <img
          src="/line.svg"
          alt="line"
          style={{
            width: "100%",
            height: "12px",
            objectFit: "cover",
            pointerEvents: "none",
          }}
        />
        <Swiper
          slidesPerView={1}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          pagination={{ clickable: true }}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {items.map((item, i) => (
            <SwiperSlide key={i}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                pt={3}
                paddingX={3}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                >
                  <Box
                    component={"img"}
                    height={200}
                    src={item.image}
                    alt="image"
                    sx={{
                      pointerEvents: "none",
                      objectFit: "contain",
                      height: 200,
                      marginBottom: 3,
                    }}
                  />
                </Box>
                {item.title}
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
        {!user?.wingman?.active && (
          <Stack direction={"column"} alignItems={"center"}>
            <Typography
              variant="bodySmall"
              textAlign="center"
              color="#B4237A"
              sx={{
                pl: 1.5,
                pr: 1.5,
                pt: 0.75,
                pb: 0.75,
                borderRadius: "16px",
                border: "1px solid #B4237A",
                mb: 0.5,
              }}
            >
              30% off
            </Typography>
            <Typography variant="bodyLarge" textAlign="center">
              {wingmanPrice?.currency?.symbol}{" "}
              {(wingmanPrice?.rate ?? 0).toFixed(2)}
            </Typography>
            <CustomButton
              text={loading ? "Processing" : "Sign me up"}
              variant="secondary"
              size="small"
              loading={loading}
              disabled={disabled}
              onClick={async () => {
                handleClose();
                if ((wallet?.balance ?? 0) < (wingmanPrice?.rate ?? 0)) {
                  setOpenTopup(true);
                  setMinAmount(
                    Math.max(
                      (wingmanPrice?.rate ?? 0) - (wallet?.balance ?? 0),
                      0
                    )
                  );
                  return;
                }
                purchaseWingman();
              }}
              sx={{
                pt: 1,
                pb: 1,
                pl: 3,
                pr: 3,
                mb: 3,
                mt: 1.5,
              }}
            />
            {loading && (
              <Typography
                sx={{
                  mt: 3,
                  cursor: "pointer",
                }}
                alignSelf="center"
                variant="notice"
                fontSize={14}
                fontWeight={400}
                onClick={() => {
                  setLoading(false);
                }}
              >
                Cancel
              </Typography>
            )}
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default HowItWorksModal;
