import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import gsap from "gsap";
import {
  getDeviceBrowser,
  getMobileOperatingSystem,
} from "../../utility/device";
import { CustomButton } from "../common/CustomButton";
import { Close } from "@mui/icons-material";

export const PwaReminder: React.FC = () => {
  const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<any>();
  const [browser, setBrowser] = useState("unknown");
  const [os, setOs] = useState("unknown");

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = () => {
    if (promptInstall) {
      promptInstall.prompt();
    }
  };

  useEffect(() => {
    const b = getDeviceBrowser();
    setBrowser(b);
    const o = getMobileOperatingSystem();
    setOs(o);
    if (window.matchMedia("(display-mode: standalone)").matches) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!backdrop || !container) return;
    if (open) {
      gsap.to(backdrop, {
        visibility: "visible",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: 0,
        overwrite: true,
        duration: 0.05,
      });
    } else {
      gsap.to(backdrop, {
        visibility: "hidden",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: "100%",
        overwrite: true,
        duration: 0.05,
      });
    }
  }, [open, backdrop, container]);

  const renderGuideline = () => {
    if (os === "iOS") {
      return (
        <Stack direction="column" gap={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="notice" fontSize={14}>
              Step 1:
            </Typography>
            <Typography variant="notice" fontSize={14}>
              Tap on
            </Typography>
            <img
              src="/safari_1.png"
              alt="Guide"
              style={{
                height: 24,
                width: 24,
                objectFit: "contain",
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="notice" fontSize={14}>
              Step 2:
            </Typography>
            <Typography variant="notice" fontSize={14}>
              Select
            </Typography>
            <img
              src="/safari_2.png"
              alt="Guide"
              style={{
                height: 30,
                objectFit: "contain",
              }}
            />
          </Stack>
          <Typography variant="notice" fontSize={14}>
            Can&apos;t find "Add to Home Screen"?
            <br /> Tap on{"  "}
            <img
              src="/safari_3.png"
              alt="Guide"
              style={{
                height: 30,
                objectFit: "contain",
                display: "inline",
              }}
            />
            {"  . "}Select “Open in external browser”. Repeat step 1 & 2.
          </Typography>
        </Stack>
      );
    }
    // For android
    if (browser === "Firefox") {
      return (
        <Stack direction="column" gap={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="notice" fontSize={14}>
              Step 1:
            </Typography>
            <Typography variant="notice" fontSize={14}>
              Tap on
            </Typography>
            <img
              src="/firefox_1.png"
              alt="Guide"
              style={{
                height: 24,
                width: 24,
                objectFit: "contain",
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="notice" fontSize={14}>
              Step 2:
            </Typography>
            <Typography variant="notice" fontSize={14}>
              Select
            </Typography>
            <img
              src="/firefox_2.png"
              alt="Guide"
              style={{
                height: 24,
                objectFit: "contain",
              }}
            />
          </Stack>
          <Typography variant="notice" fontSize={14}>
            Can&apos;t find "Install app"?
            <br /> Tap on{"  "}
            <img
              src="/firefox_1.png"
              alt="Guide"
              style={{
                height: 30,
                objectFit: "contain",
                display: "inline",
              }}
            />
            {"  . "}Select "Copy link" and open in Chrome. Repeat step 1 & 2.
          </Typography>
        </Stack>
      );
    }
    return (
      <Stack direction="column" gap={2}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="notice" fontSize={14}>
            Step 1:
          </Typography>
          <Typography variant="notice" fontSize={14}>
            Tap on
          </Typography>
          <img
            src="/chrome_1.png"
            alt="Guide"
            style={{
              height: 24,
              width: 24,
              objectFit: "contain",
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="notice" fontSize={14}>
            Step 2:
          </Typography>
          <Typography variant="notice" fontSize={14}>
            Select
          </Typography>
          <img
            src="/chrome_2.png"
            alt="Guide"
            style={{
              height: 24,
              objectFit: "contain",
            }}
          />
        </Stack>
        <Typography variant="notice" fontSize={14}>
          Can&apos;t find "Install app"?
          <br /> Tap on{"  "}
          <img
            src="/firefox_1.png"
            alt="Guide"
            style={{
              height: 30,
              objectFit: "contain",
              display: "inline",
            }}
          />
          {"  . "}Select "Copy link" and open in Chrome. Repeat step 1 & 2.
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack
      direction="column"
      width="100vw"
      height="100%"
      sx={{
        position: "fixed",
        justifyContent: "center",
        alignItems: "center",
        top: 0,
        left: 0,
        zIndex: 100,
        visibility: "hidden",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      ref={(el) => setBackdrop(el)}
      onClick={(e) => {
        e.stopPropagation();
        // setOpen(false);
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: 350,
          maxWidth: "90vw",
          transform: "translateY(100%)",
          borderRadius: "24px",
          background: "white",
          position: "relative",
          cursor: "auto",
          maxHeight: "90vh",
          overflowY: "scroll",
          boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.15)",
          alignItems: "center",
          justifyContent: "center",
          pl: 3,
          pr: 3,
          pt: 3,
          pb: 6,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={(el) => setContainer(el)}
      >
        {os === "unknown" && (
          <Stack direction="row" justifyContent="flex-end" width="100%" mb={1}>
            <Close
              sx={{
                width: 24,
                height: 24,
                cursor: "pointer",
                color: "#5C646B",
                WebkitTapHighlightColor: "rgba(0,0,0,0)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </Stack>
        )}
        <Typography variant="bodyLarge" mb={3}>
          {os === "unknown" ? "Open in Phone" : "Add To Home Screen"}
        </Typography>
        <Typography variant="bodySmall" textAlign="center" mb={3}>
          {os === "unknown"
            ? "For better experience with Delulu, please open the app in your phone."
            : supportsPWA
            ? "For better experience with Delulu, please install the app in your homescreen."
            : "To continue, please add this website to your home screen."}
        </Typography>
        {!supportsPWA && os !== "unknown" && renderGuideline()}
        {supportsPWA && os !== "unknown" && (
          <CustomButton
            onClick={() => {
              onClick();
            }}
            text="Install App"
          />
        )}
      </Stack>
    </Stack>
  );
};
