import { Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getFiatWallet, getFreeDrawWallet } from "../../api/wallet";
import { useAppSelector } from "../../redux/hooks";
import { CustomButton } from "../common/CustomButton";

type Props = {
  setOpen: Function;
  loading: boolean;
  canOpenMissionPopup: boolean;
  setOpenMissionPopup: Function;
};

export const WalletBalance: React.FC<Props> = ({
  setOpen,
  loading,
  canOpenMissionPopup,
  setOpenMissionPopup,
}) => {
  const user = useAppSelector((state) => state.user.user);
  const appState = useAppSelector((state) => state.appState);

  const { data: wallet } = useQuery({
    queryKey: ["wallet", user?.uid],
    queryFn: () => getFiatWallet(),
    enabled: !!user?.uid,
  });

  const { data: freeDraw } = useQuery({
    queryKey: ["freeDraw", user?.uid],
    queryFn: () => getFreeDrawWallet(),
    enabled: !!user?.uid,
  });

  return (
    <Stack
      direction="row"
      alignItems="center"
      position="relative"
      justifyContent="space-between"
      sx={{
        width: "80%",
        maxWidth: "261px",
        borderRadius: "16px",
        border: "1px solid white",
        background: "rgba(0, 0, 0, 0.10)",
        backdropFilter: "blur(5px)",
        py: 1,
        px: 2,
        mb: 3,
      }}
    >
      {(freeDraw?.balance ?? 0) > 0 && (
        <Typography
          variant="bodySmall"
          color="white"
          sx={{
            py: "6px",
            px: "12px",
            borderRadius: "16px",
            background: "#B4237A",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, 0%)",
            bottom: -22,
            width: "80%",
          }}
          noWrap
          textAlign="center"
        >
          {`${appState.currencySymbol} ${
            freeDraw?.balance ?? 0
          } FREE CREDIT LEFT`}
        </Typography>
      )}
      {(freeDraw?.balance ?? 0) <= 0 && canOpenMissionPopup && (
        <Typography
          variant="bodySmall"
          color="white"
          sx={{
            py: "6px",
            px: "12px",
            borderRadius: "16px",
            background: "#B4237A",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, 0%)",
            bottom: -22,
            width: "80%",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenMissionPopup(true);
          }}
          noWrap
          textAlign="center"
        >
          GET MORE FREE DRAWS
        </Typography>
      )}
      <Stack direction="column">
        <Typography
          variant="bodySmall"
          color="white"
          sx={{
            opacity: 0.5,
          }}
        >
          Your Balance
        </Typography>
        <Typography variant="bodyMedium" color="white">
          {wallet?.asset?.symbol ?? appState.currencySymbol}
          {wallet?.balance?.toFixed(2) ?? "0.00"}
        </Typography>
      </Stack>
      <CustomButton
        size="small"
        variant="white"
        text="Top up"
        loading={loading}
        onClick={() => {
          setOpen(true);
        }}
      />
    </Stack>
  );
};
