import { OrderDto } from "../types/OrderDto";
import { ProductDto } from "../types/ProductDto";
import API from "./api";

export const getProductCatalogs = async (slug: string) => {
  const resp = await API.get(
    `/product-catalogs/${slug}?populate=products.prices.currency`
  );
  return (resp.data?.products ?? []) as ProductDto[];
};

export const createWingmanOrder = async (
  productId: number,
  currencyId: number
) => {
  const resp = await API.post("/orders", {
    data: {
      items: [
        {
          productId,
          quantity: 1,
        },
      ],
      currency: currencyId,
      paymentGatewayCode: "internal",
      paymentReturnUrl: `${process.env.REACT_APP_APP_URL}/referral`,
    },
  });
  return resp.data;
};


export const createCustomOrder = async (
  productId: number,
  currencyId: number,
  quantity: number
) => {
  const resp = await API.post("/orders", {
    data: {
      items: [
        {
          productId,
          quantity: quantity,
        },
      ],
      currency: currencyId,
      paymentGatewayCode: "internal",
    },
  });
  return resp.data;
};

export const createOrder = async (
  productId: number,
  currencyId: number,
  paymentGatewayCode?: "internal" | "payex" | "razer-pay",
  quantity?: number,
  redirectUrl?: string
) => {
  const resp = await API.post("/orders", {
    data: {
      items: [
        {
          productId,
          quantity: quantity ?? 1,
        },
      ],
      currency: currencyId,
      paymentGatewayCode: paymentGatewayCode ?? "payex",
      paymentReturnUrl:
        redirectUrl ?? `${process.env.REACT_APP_APP_URL}/payment_success`,
    },
  });
  return resp.data;
};

export const getOrder = async (orderUid: string) => {
  const resp = await API.get(`/orders/${orderUid}`);
  return resp.data?.data as OrderDto | undefined;
};

export const transferAsset = async (
  recipient: string,
  amount: number,
  assetId: number,
  roomUid: string,
  orderUid: string
) => {
  const resp = await API.post("/transfer-assets", {
    data: {
      amount,
      assetId,
      recipient,
      meta: {
        roomUid: roomUid,
        order: orderUid,
      },
    },
  });
  return resp.data;
};

export const getWalletAssets = async () => {
  const resp = await API.get("/wallet-assets");
  return resp.data?.data;
};
