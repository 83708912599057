import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import countries from "../../constants/countries";

type AppState = {
  country: string
  currency: string
  currencySymbol: string
  countryCode: string
  withdrawalMethod: "TNG" | "AMAZON"
  paymentGatewayCode: "payex" | "razer-pay"
};

const initialState: AppState = {
  country: "US",
  currency: "USD",
  currencySymbol: "$",
  countryCode: "+1",
  withdrawalMethod: "AMAZON",
  paymentGatewayCode: "razer-pay"
};

export const appState = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setAppStateByPhoneNumber: (state, action: PayloadAction<{
      phoneNumber: string
    }>) => {
      const { phoneNumber } = action.payload;

      const country = countries.find((c) => phoneNumber.startsWith(c.countryCode));

      if (!country) return;

      state = {
        ...country,
      };

      return state;
    }
  },
});

export const { setAppStateByPhoneNumber } = appState.actions;
export default appState.reducer;
