import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import gsap from "gsap";
import { useNavigate } from "react-router";
import { CustomButton } from "../common/CustomButton";

type Props = {
  open: boolean;
  setOpen: Function;
};

export const FreeEarlyBirdModal: React.FC<Props> = ({ open, setOpen }) => {
  const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!backdrop || !container) return;
    if (open) {
      gsap.to(backdrop, {
        visibility: "visible",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: 0,
        overwrite: true,
        duration: 0.05,
      });
    } else {
      gsap.to(backdrop, {
        visibility: "hidden",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: "100%",
        overwrite: true,
        duration: 0.05,
      });
    }
  }, [open, backdrop, container]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        position: "fixed",
        justifyContent: "center",
        top: 0,
        left: 0,
        zIndex: 10,
        alignItems: "center",
        visibility: "hidden",
        height: "100dvh",
      }}
      ref={(el) => setBackdrop(el)}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(false);
        navigate("/");
        localStorage.setItem("hasShownRm5Draw", "true");
      }}
    >
      <Stack
        direction="column"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        sx={{
          backdropFilter: "blur(5px)",
        }}
      >
        <Stack
          direction="column"
          sx={{
            transform: "translateY(100%)",
            borderRadius: "16px",
            background: "white",
            position: "relative",
            cursor: "auto",
            alignItems: "center",
            pl: 6,
            pr: 6,
            pb: 3,
            mt: 3,
            overflow: "visible",
            maxWidth: "90%",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={(el) => setContainer(el)}
        >
          <img
            src="/close.svg"
            alt="close"
            style={{
              width: 16,
              height: 16,
              cursor: "pointer",
              color: "black",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
              position: "absolute",
              top: -16,
              right: -16,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
              navigate("/");
              localStorage.setItem("hasShownRm5Draw", "true");
            }}
          />
          <img
            src="/popup_thumbnail.png"
            alt="popup"
            width={200}
            height={200}
            style={{
              objectFit: "contain",
              width: 200,
              height: 200,
              marginTop: -48,
              pointerEvents: "none",
            }}
          />
          <Typography
            variant="headlineMedium"
            mb={3}
            textAlign="center"
            pl={4.5}
            pr={4.5}
          >
            Free RM 5
            <br />
            Credit!
          </Typography>
          <Typography
            variant="bodyMedium"
            mb={3}
            textAlign="center"
            color="#22171E"
          >
            RM 5 has been credited to your wallet as an early bird!
          </Typography>
          <img
            src="/short_line.svg"
            alt="short_line"
            style={{
              width: 100,
              height: 12,
              objectFit: "cover",
              pointerEvents: "none",
              transform: "rotateX(180deg)",
              marginBottom: 24,
            }}
          />
          <CustomButton
            text="Start Drawing Now"
            variant="tertiary"
            sx={{ mb: 3 }}
            onClick={() => {
              setOpen(false);
              navigate("/");
              localStorage.setItem("hasShownRm5Draw", "true");
            }}
          />
          <Typography
            variant="bodySmall"
            textAlign="center"
            color="#22171E"
            mb={3}
          >
            only valid for drawing cards
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
