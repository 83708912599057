import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import gsap from "gsap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { toBlob } from "html-to-image";
import { CustomButton } from "../common/CustomButton";
import { useQuery } from "@tanstack/react-query";
import { getReward } from "../../api/reward";
import { completeMission } from "../../api/mission";
import { delay } from "../../utility/time";

type Props = {
  sx?: any;
  open: boolean;
  setOpen: Function;
  setHowItWorksOpen: Function;
  refetch?: Function;
};

export const ReferModal: React.FC<Props> = ({
  sx,
  open,
  setOpen,
  setHowItWorksOpen,
  refetch,
}) => {
  const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  const { data: reward } = useQuery({
    queryKey: ["reward", user?.uid],
    queryFn: () => getReward(),
    enabled: !!user?.uid,
  });

  const handleShare = async () => {
    try {
      if (!user?.wingman) {
        dispatch(
          setSnackbar({
            open: true,
            message:
              "Referral code not found, please contact us via hello@delulu.fun for assistance.",
            severity: "error",
          })
        );
        return;
      }
      const el = document.getElementById("refer-ig");
      if (!el) {
        dispatch(
          setSnackbar({
            open: true,
            message: "Operation error, please reload your page and try again.",
            severity: "error",
          })
        );
        return;
      }
      await toBlob(el);
      await toBlob(el);
      delay(1);
      const newFile = await toBlob(el);
      if (!newFile) {
        dispatch(
          setSnackbar({
            open: true,
            message: "Image load error, please reload your page and try again.",
            severity: "error",
          })
        );
        return;
      }
      const data = {
        files: [
          new File([newFile], "image.png", {
            type: newFile.type,
          }),
        ],
      };

      if (!navigator.canShare) {
        // navigator not supported
        dispatch(
          setSnackbar({
            open: true,
            message:
              "Your device does not support image sharing, please use the other sharing options.",
            severity: "error",
          })
        );
        return;
      }
      await navigator.share(data);
      completeInviteMission();
    } catch (err) {
      console.error(err);
    }
  };

  const completeInviteMission = async () => {
    const mission = reward?.missions?.find(
      (m) => m.slug === "invite" && m.completionCount <= 0
    );
    if (!mission) return;
    try {
      await completeMission(mission.id);
      if (!!refetch) refetch();
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!backdrop || !container) return;
    if (open) {
      gsap.to(backdrop, {
        visibility: "visible",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: 0,
        overwrite: true,
        duration: 0.05,
      });
    } else {
      gsap.to(backdrop, {
        visibility: "hidden",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: "100%",
        overwrite: true,
        duration: 0.05,
      });
    }
  }, [open, backdrop, container]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        position: "fixed",
        justifyContent: "flex-end",
        top: 0,
        left: 0,
        zIndex: 10,
        alignItems: "center",
        backdropFilter: "blur(5px)",
        visibility: "hidden",
        height: "100dvh",
      }}
      ref={(el) => setBackdrop(el)}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "600px",
          transform: "translateY(100%)",
          borderRadius: "24px 24px 0px 0px",
          background: "white",
          position: "relative",
          cursor: "auto",
          overflowY: "scroll",
          alignItems: "center",
          p: 3,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={(el) => setContainer(el)}
      >
        <Typography variant="headlineMedium" mb={2} textAlign="center">
          Invite your friends
        </Typography>
        <CustomButton
          text="Share Link"
          onClick={async () => {
            if (!navigator.canShare) {
              navigator.clipboard.writeText(
                `Join me on Delulu, I'm your wingman to help you find your crush: ${
                  process.env.REACT_APP_APP_URL
                }/refer/${user?.wingman?.code ?? ""}`
              );
              dispatch(
                setSnackbar({
                  open: true,
                  severity: "success",
                  message: "Copied your referral link to clipboard.",
                })
              );
              return;
            }

            const data = {
              title: `Join me on Delulu, I'm your wingman to help you find your crush: ${
                process.env.REACT_APP_APP_URL
              }/refer/${user?.wingman?.code ?? ""}`,
              text: `Join me on Delulu, I'm your wingman to help you find your crush: ${
                process.env.REACT_APP_APP_URL
              }/refer/${user?.wingman?.code ?? ""}`,
            };

            try {
              await navigator.share(data);
              completeInviteMission();
            } catch (err) {
              console.error(err);
            }
          }}
          size="small"
          variant="secondary"
          sx={{
            pt: "14px",
            pb: "14px",
            mb: 2,
            width: 200,
          }}
        />
        <CustomButton
          text="Share QR"
          onClick={() => {
            handleShare();
          }}
          size="small"
          variant="tertiary"
          sx={{
            pt: "14px",
            pb: "14px",
            mb: 4,
            width: 200,
          }}
        />
        <Typography
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
            mb: 4,
          }}
          alignSelf="center"
          variant="bodySmall"
          onClick={() => {
            setHowItWorksOpen(true);
          }}
        >
          How it works?
        </Typography>
        <Typography
          sx={{
            cursor: "pointer",
          }}
          alignSelf="center"
          variant="notice"
          fontSize={14}
          fontWeight={400}
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Typography>
      </Stack>
    </Stack>
  );
};
