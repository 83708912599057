import { WalletDto } from "../types/WalletDto";
import API from "./api";
import { store } from "../redux/store";

export const getFiatWallet = async () => {
  const resp = await API.get("/wallets?populate=asset");
  const appState = store.getState().appState;

  return (resp.data?.data?.find((w: any) => w?.asset?.label === appState.currency) ?? {
    uid: "",
    asset: {
      id: 0,
      createdAt: "",
      updatedAt: "",
      label: appState,
      symbol: appState.currencySymbol,
      type: "",
    },
    balance: 0,
    createdAt: "",
    updatedAt: "",
  }) as WalletDto;
};

export const getFreeDrawWallet = async () => {
  const resp = await API.get("/wallets?populate=asset");
  return (resp.data?.data?.find(
    (w: any) => w?.asset?.label === "FREE DRAW"
  ) ?? {
    uid: "",
    asset: {
      id: 0,
      createdAt: "",
      updatedAt: "",
      label: "FREE DRAW",
      symbol: "FREE DRAW",
      type: "",
    },
    balance: 0,
    createdAt: "",
    updatedAt: "",
  }) as WalletDto;
};

export const getGiftWallet = async () => {
  const resp = await API.get("/wallets?populate=asset");
  return (resp.data?.data?.find(
    (w: any) => w?.asset?.label === "Gift"
  ) ?? {
    uid: "",
    asset: {
      id: 0,
      createdAt: "",
      updatedAt: "",
      label: "Gift",
      symbol: "GIFT",
      type: "",
    },
    balance: 0,
    createdAt: "",
    updatedAt: "",
  }) as WalletDto;
};
