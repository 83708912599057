import { openDB, IDBPDatabase } from 'idb';
import { ChatMessage } from '../redux/features/chatSlice';

class Completer<T> {
  public readonly promise: Promise<T>;
  public complete!: (value: (PromiseLike<T> | T)) => void;
  private reject!: (reason?: any) => void;

  public constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.complete = resolve;
      this.reject = reject;
    })
  }
}

interface ChatRoomState {
  roomId: string;
  hasUnreadMessages: boolean;
  lastMessage: ChatMessage | null;
}

const DB_NAME = 'delulu-db';
const MESSAGES_STORE_NAME = 'messages';
const CHAT_ROOM_STATE_STORE_NAME = 'chatRoomState';

class DBService {
  private static instance: DBService;
  private dbPromise = new Completer<IDBPDatabase>();

  async init() {
    const db = await openDB(DB_NAME, 1, {
      upgrade(db) {
        // Create a store of objects
        db.createObjectStore(MESSAGES_STORE_NAME);
        db.createObjectStore(CHAT_ROOM_STATE_STORE_NAME, {
          keyPath: 'roomId',
        });
      },
    });

    this.dbPromise.complete(db);
  }

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }

  public async getMessages(key: string) {
    try {
      return (await this.dbPromise.promise).get(MESSAGES_STORE_NAME, key) as Promise<ChatMessage[]>;
    } catch (error) {
      return Promise.resolve(null);
    }
  }

  public async setMessages(key: string, val: ChatMessage[]) {
    return (await this.dbPromise.promise).put(MESSAGES_STORE_NAME, val, key);
  }

  public async getChatRoomState(key: string) {
    try {
      return (await this.dbPromise.promise).get(CHAT_ROOM_STATE_STORE_NAME, key) as Promise<ChatRoomState>;
    } catch (error) {
      return Promise.resolve(null);
    }
  }

  public async setChatRoomState(val: ChatRoomState) {
    return (await this.dbPromise.promise).put(CHAT_ROOM_STATE_STORE_NAME, val);
  }

  public async setChatRoomHasUnreadMessagesStates(roomId: string, val: boolean) {
    const roomState = await this.getChatRoomState(roomId);
    if (!roomState) return;

    return this.setChatRoomState({
      ...roomState,
      hasUnreadMessages: val,
    });
  }

  public async setChatRoomLastMessageStates(roomId: string, val: ChatMessage) {
    const roomState = await this.getChatRoomState(roomId);
    if (!roomState) return;

    return this.setChatRoomState({
      ...roomState,
      lastMessage: val,
    });
  }
}

export default DBService;