import { FC } from "react";
import { Permission, useAuth } from "../../hooks/useAuth";
import { Stack, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router";

export const PrivacyPage: FC = () => {
  useAuth(Permission.ANYONE);
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/dull_bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
        alignItems: "center",
        pt: 6,
        pb: 6,
      }}
    >
      <Stack width="100%" maxWidth={600} mb={2}>
        <ChevronLeft
          sx={{
            cursor: "pointer",
            fontSize: 16,
            color: "white",
            alignSelf: "flex-start",
            ml: 2,
          }}
          onClick={() => navigate(-1)}
        />
      </Stack>
      <Stack
        borderRadius={4}
        flexDirection={"column"}
        sx={{
          backgroundColor: "white",
          width: "calc(100% - 8px - 8px)",
          flex: 1,
          maxWidth: 600,
          overflowY: "auto",
          p: 2,
        }}
      >
        <Typography
          variant="headlineMedium"
          color="#22171E"
          mb={2}
          textAlign="center"
        >
          Delulu Privacy Policy
        </Typography>
        <Typography
          variant="bodySmall"
          whiteSpace="pre-line"
          textAlign="justify"
        >
          {`Welcome to Delulu! We are committed to protecting your privacy and your personal information. We respect your privacy and are committed to protecting it through our compliance with this privacy policy ("Privacy Policy").

This Privacy Policy applies to all information we collect from or about you when you use our mobile applications (the "App") and other online products and services (collectively, the "Services").

Please read this Privacy Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, you may choose not to use our Services. By accessing or using our Services, you agree to this Privacy Policy.

By accessing or using our Services, you agree to this Privacy Policy.
​
INFORMATION WE COLLECT

We collect information about you when you use our Services, including:
- Information you provide directly to us, such as your name, email address, phone number, gender, date of birth, photos, and preferences.
- Information we collect automatically, such as your IP address, device type, operating system, and usage data.
- Information we receive from third-party sources, such as social media platforms and verification services.
- Chat history
​
HOW WE USE YOUR INFORMATION

We use your information to provide and improve our Services, including:
- Matching you with other users based on your preferences and location.
- Facilitating communication between you and other users.
- Personalizing your experience on our Services.
- Conducting research and analytics.
- Communicating with you about our Services, promotions, and updates.
- Enforcing our terms of use and other agreements.
- Complying with legal obligations.

SHARING OF YOUR INFORMATION

We may share your information with third parties as follows:
- With other users of our Services, in accordance with your preferences.
- With our service providers who help us provide and improve our Services, such as payment processing, customer support, and marketing.
- With our affiliates and business partners for their own marketing purposes, subject to your consent where required by law.
- With law enforcement or other governmental agencies as required by law or as necessary to protect our rights and property or the rights and property of others.

COOKIES AND SIMILAR TECHNOLOGIES

We use cookies and similar technologies to collect information about your usage of our Services and to improve our Services.

YOUR CHOICES

You may control the information we collect from or about you through our Services in various ways, such as by updating your profile information, adjusting your privacy settings, and opting out of promotional emails.

CHILDREN'S PRIVACY

Our Services are not intended for children under the age of 18, and we do not knowingly collect personal information from children under the age of 18.

INTERNATIONAL TRANSFER

Your information may be transferred to, and processed in, countries other than the country in which you are a resident. By using our Services, you consent to the transfer of your information to such countries.

SECURITY

We take reasonable measures to protect the security of your information, including using SSL encryption to protect the transmission of sensitive information and storing your information on secure servers. However, no security system is perfect, and we cannot guarantee the security of your information.

CHANGES TO THIS PRIVACY POLICY

We may update this Privacy Policy from time to time by posting a new version on our Services. You should check this page periodically to review any changes.

CONTACT US

If you have any questions or concerns about this Privacy Policy or our data practices, you may contact us at hello@delulu.fun`}
        </Typography>
      </Stack>
    </Stack>
  );
};
