import { Stack, Typography } from "@mui/material";
import { Message } from "@chatscope/chat-ui-kit-react";
import { useQuery } from "@tanstack/react-query";
import { useAppSelector } from "../../redux/hooks";
import { getGift } from "../../api/gift";

type Props = {
  text: string;
  giftNumber?: string;
  uid?: string;
  isSender: boolean;
  senderUid?: string;
  setGiftUid: Function;
  setGiftAmount: Function;
  setOpen: Function;
};

export const GiftMessage: React.FC<Props> = ({
  text,
  giftNumber,
  uid,
  isSender,
  senderUid,
  setGiftAmount,
  setGiftUid,
  setOpen,
}) => {
  const user = useAppSelector((state) => state.user.user);

  const { data: gift } = useQuery({
    queryKey: ["transfer-assets", user?.uid, uid],
    queryFn: () => getGift(uid ?? "", senderUid ?? ""),
    enabled: !!user?.uid && !!uid && !isSender && !!senderUid,
  });

  return (
    <Message.CustomContent>
      <Stack
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        gap="6px"
      >
        <Message.TextContent>{text}</Message.TextContent>
        <Typography variant="bodyLarge" color="black">
          {giftNumber}
        </Typography>
        <img
          src="/gift.png"
          alt="gift"
          style={{
            objectFit: "contain",
            width: 24,
            height: 24,
            pointerEvents: "none",
          }}
        />
        {/* {!isSender && (gift?.amount ?? 0) > 0 && (
          <Typography
            variant="notice"
            fontSize={12}
            color="white"
            sx={{
              py: 0.5,
              px: 1,
              borderRadius: "100px",
              background:
                "linear-gradient(272deg, #6135B5 -26.5%, #C6507E 101.21%)",
              cursor: "pointer",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
              opacity: !!gift?.returned ? 0.5 : 1,
            }}
            onClick={() => {
              if (!!gift?.returned) return;
              setGiftAmount(gift?.amount ?? 0);
              setGiftUid(gift?.uid ?? "");
              setOpen(true);
            }}
          >
            {!!gift?.returned ? "RETURNED" : "RETURN"}
          </Typography>
        )} */}
      </Stack>
    </Message.CustomContent>
  );
};
