import { ReactNode } from "react";
import ReactFlipCard from "reactjs-flip-card";

type Props = {
  frontComponent: ReactNode;
  backComponent: ReactNode;
  showBack?: boolean;
};

export const CustomFlipCard: React.FC<Props> = ({
  frontComponent,
  backComponent,
  showBack,
}) => {
  return (
    <ReactFlipCard
      flipCardStyle={{
        width: "100%",
        height: "100%",
      }}
      containerStyle={{
        width: "calc(100% - 8px - 8px)",
        height: "100%",
        maxWidth: 600,
      }}
      frontStyle={{}}
      backStyle={{}}
      flipTrigger="disabled"
      frontComponent={frontComponent}
      backComponent={backComponent}
      flipByProp={showBack}
    />
  );
};
