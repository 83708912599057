import { Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = {
  title: string;
};

export const CustomCard: React.FC<PropsWithChildren<Props>> = ({
  children,
  title,
}) => {
  return (
    <Stack
      direction="column"
      width="calc(100% - 16px - 16px)"
      sx={{
        background: "white",
        borderRadius: "24px",
        maxWidth: 600,
      }}
    >
      <Typography textAlign="center" variant="bodyLarge" pt={2} pb={1}>
        {title}
      </Typography>
      <img
        src="/line.svg"
        alt="line"
        style={{
          width: "100%",
          height: "12px",
          objectFit: "cover",
        }}
      />
      <Stack p={2} direction="column" alignItems="center">
        {children}
      </Stack>
    </Stack>
  );
};
