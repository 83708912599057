import { Stack } from "@mui/material";
import Lottie from "lottie-react";
import { CustomCard } from "../common/CustomCard";
import loading from "./loading.json";

export const SplashScreen = () => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      width="100%"
      height="100dvh"
      sx={{
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/wingman_bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        zIndex: 10,
      }}
      justifyContent="center"
    >
      <CustomCard title="Loading Data">
        <Lottie
          animationData={loading}
          loop={true}
          style={{
            width: 300,
            height: 300,
          }}
        />
      </CustomCard>
    </Stack>
  );
};
