import { Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { getFiatWallet, getGiftWallet } from "../../api/wallet";
import { Permission, useAuth } from "../../hooks/useAuth";
import { useAppSelector } from "../../redux/hooks";
import { CustomButton } from "../common/CustomButton";
import { CashGiftModal } from "./CashGiftModal";
import { useState } from "react";

type Props = {
  openTopup: boolean;
  setOpenTopup: (v: boolean) => void;
  loading: boolean;
};

export const ProfileFront: React.FC<Props> = ({
  openTopup,
  setOpenTopup,
  loading,
}) => {
  const user = useAppSelector((state) => state.user.user);
  const appState = useAppSelector((state) => state.appState);
  useAuth(Permission.USER_ONLY);
  const navigate = useNavigate();

  const [isCashGiftModalOpen, setIsCashGiftModalOpen] = useState(false);

  const { data: wallet, refetch: refetchWallet } = useQuery({
    queryKey: ["wallet", user?.uid],
    queryFn: () => getFiatWallet(),
    enabled: !!user?.uid,
  });

  const { data: giftWallet, refetch: refetchGiftWallet } = useQuery({
    queryKey: ["giftWallet", user?.uid],
    queryFn: () => getGiftWallet(),
    enabled: !!user?.uid,
  });

  return (
    <Stack
      sx={{
        width: "calc(100% - 8px - 8px)",
        height: "100%",
        borderRadius: "16px",
        background: "white",
        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
        alignItems: "center",
        overflowY: "auto",
        maxWidth: 600,
      }}
    >
      <img
        src="/sep.svg"
        height={20}
        width={118}
        alt="sep"
        style={{
          objectFit: "contain",
          marginBottom: 16,
          marginTop: 16,
        }}
      />
      <img
        src={!!user.avatar ? user.avatar : "/logo_border.png"}
        alt="avatar"
        style={{
          borderRadius: "50%",
          width: 100,
          height: 100,
          objectFit: "cover",
          marginBottom: 8,
          cursor: "pointer",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
        }}
        onClick={() => {
          navigate(`/profile/${user?.uid ?? ""}`);
        }}
      />
      <Typography
        variant="headlineLarge"
        color="#22171E"
        textAlign="center"
        pl={4}
        pr={4}
        mb={1}
      >
        {!!user.displayName ? user.displayName : "-"}
      </Typography>
      <Typography
        variant="bodySmall"
        color="#22171E"
        textAlign="center"
        mb={1.5}
      >
        looking for {user.lookingFor}
      </Typography>
      <CustomButton
        text="Edit"
        onClick={() => {
          navigate("/settings");
        }}
        sx={{
          width: "calc(100% - 32px - 32px)",
          pt: "10px",
          pb: "10px",
          mb: 2,
        }}
        size="medium"
        propagate
      />
      <img
        src="/line.svg"
        alt="line"
        style={{
          width: "100%",
          // height: "12px",
          objectFit: "cover",
          marginBottom: 16,
          transform: "rotate(180deg)",
        }}
      />
      <Stack marginY={2}>
        <Typography
          variant="bodyLarge"
          color="#22171E"
          textAlign={"center"}
          sx={{
            opacity: 0.5,
          }}
        >
          You Have
        </Typography>

        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
        >
          <Typography variant="bodyLarge" fontSize={36}>
            {giftWallet?.balance ?? 0}
          </Typography>

          <img
            src="/gift.png"
            alt="gift"
            style={{
              width: 36,
              height: 36,
            }}
          />
        </Stack>

        <CustomButton
          text="Cash Out"
          onClick={() => setIsCashGiftModalOpen(true)}
          sx={{
            // pt: "10px",
            // pb: "10px",
            mb: 2,
            mt: 1,
          }}
          size="medium"
          propagate
        />

        <CashGiftModal
          open={isCashGiftModalOpen}
          handleClose={() => setIsCashGiftModalOpen(false)}
          giftCount={giftWallet?.balance ?? 0}
          onComplete={() => {
            refetchWallet();
            refetchGiftWallet();
          }}
        />
      </Stack>

      <img
        src="/line.svg"
        alt="line"
        style={{
          width: "100%",
          // height: "12px",
          objectFit: "cover",
          marginBottom: 16,
        }}
      />

      <Stack
        direction="row"
        alignItems="center"
        width="60%"
        justifyContent="space-between"
        mb={2}
      >
        <Stack direction="column">
          <Typography
            variant="bodySmall"
            color="#22171E"
            sx={{
              opacity: 0.5,
            }}
          >
            Your Balance
          </Typography>
          <Typography variant="bodyMedium" color="#22171E">
            {wallet?.asset?.symbol ?? appState.currencySymbol}
            {wallet?.balance?.toFixed(2) ?? "0.00"}
          </Typography>
        </Stack>
        <CustomButton
          text={loading ? "Processing" : "Top up"}
          loading={loading}
          onClick={() => {
            setOpenTopup(true);
          }}
          size="small"
          variant="tertiary"
        />
      </Stack>
      {/* <img
        src="/line.svg"
        alt="line"
        style={{
          width: "100%",
          height: "12px",
          objectFit: "cover",
          marginBottom: 16,
        }}
      /> */}
      {/* <Stack
        direction="row"
        alignItems="center"
        gap={2}
        width="100%"
        mb={"20px"}
      >
        <Stack flex={1} direction="column" alignItems="center" ml={3}>
          <Typography
            variant="headlineSmall"
            color="black"
            textAlign="center"
            mb={0.5}
          >
            Drawn Chance
          </Typography>
          <Typography
            variant="heroSmall"
            color="black"
            textAlign="center"
            mb={0.5}
          >
            12%
          </Typography>
          <Typography variant="notice" textAlign="center">
            Your drawn chance increases as you reply to more messages
          </Typography>
        </Stack>
        <img
          src="/vertical_line.svg"
          alt="line"
          style={{
            height: 68,
            width: 12,
            objectFit: "cover",
          }}
        />
        <Stack flex={1} direction="column" alignItems="center" mr={3}>
          <Typography
            variant="headlineSmall"
            color="black"
            textAlign="center"
            mb={0.5}
          >
            Drawn
          </Typography>
          <Typography
            variant="heroSmall"
            color="black"
            textAlign="center"
            mb={0.5}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            0{" "}
            <span
              style={{
                fontSize: 16,
              }}
            >
              times
            </span>
          </Typography>
          <Typography variant="notice" textAlign="center">
            Your drawn chance increases as you reply to more messages
          </Typography>
        </Stack>
      </Stack> */}
      <Stack
        sx={{
          flex: 1,
        }}
      />
      <img
        src="/sep.svg"
        height={20}
        width={118}
        alt="sep"
        style={{
          objectFit: "contain",
          marginBottom: 16,
          marginTop: 16,
        }}
      />
    </Stack>
  );
};
