import { FC, createRef, useEffect, useState, useMemo } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { MessageList, Message } from "@chatscope/chat-ui-kit-react";
import ChatSocket from "../../socket/chatSocket";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ChatMessage, setCurrentRoomId } from "../../redux/features/chatSlice";
import {
  resetMessages,
  addMessage,
  loadMessages,
} from "../../redux/features/messageSlice";
import { Box, Popover, Stack, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import ImageViewer from "react-simple-image-viewer";
import { CustomTextField } from "../common/CustomTextField";
import { ReportModal } from "../ReportModal/ReportModal";
import { GiftModal } from "../GiftModal/GiftModal";
import { GiftConfirmModal } from "../GiftConfirmModal/GiftConfirmModal";
import { TopUpModal } from "../TopUpModal/TopUpModal";
import { compressImage } from "../../utility/file";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { uploadAsset } from "../../api/file";

//Gift Message Implementation
import { GiftMessage } from "../GiftNotification/GiftMessage";
import { useQuery } from "@tanstack/react-query";
import { getGiftsReceived } from "../../api/gift";
import { useSearchParams } from "react-router-dom";
import { ReturnGiftModal } from "../ReturnGiftModal/ReturnGiftModal";
import { GiftReturnedMessage } from "../GiftNotification/GiftReturnedMessage";
import { delay } from "../../utility/time";
import { getOrder } from "../../api/payment";
import { getUser } from "../../api/user";
import { setUser } from "../../redux/features/userSlice";
import { ChatRoomBanner } from "../ChatRoomsPage/ChatRoomBanner";
import md5 from "md5";

const comingSoon = process.env.REACT_APP_COMING_SOON;

let localLoading = false;

const ChatPage: FC = () => {
  const roomId = useParams().roomId ?? "";

  const user = useAppSelector((state) => state.user.user);
  const room = useAppSelector((state) => state.chat.rooms).find(
    (r) => r.id === roomId
  );
  const messages = useAppSelector((state) => state.messages.messages);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fileRef = createRef<HTMLInputElement>();

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [imageUrl, setImageUrl] = useState<undefined | string>();
  const [text, setText] = useState("");
  const [messagesEl, setMessagesEl] = useState<HTMLDivElement | null>(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [popoverEl, setPopoverEl] = useState<HTMLImageElement | null>(null);
  const [openReport, setOpenReport] = useState(false);
  const [openGift, setOpenGift] = useState(false);
  const [openGiftConfirm, setOpenGiftConfirm] = useState(false);
  const [count, setCount] = useState(3);
  const [openTopup, setOpenTopup] = useState(false);
  const [minAmount, setMinAmount] = useState(0);
  const [params] = useSearchParams();
  const [openReturn, setOpenReturn] = useState(false);
  const [giftAmount, setGiftAmount] = useState(0);
  const [giftUid, setGiftUid] = useState("");
  const [loading, setLoading] = useState(false);
  const [messagesChecked, setMessagesChecked] = useState(false);

  useEffect(() => {
    localLoading = loading;
  }, [loading]);

  const checkOrder = async (orderUid: string) => {
    await delay(1);
    const newOrder = await getOrder(orderUid);
    if (
      newOrder?.status === "PAYMENT_FAILED" ||
      newOrder?.status === "CANCELLED" ||
      newOrder?.status === "ERROR"
    ) {
      setLoading(false);
      dispatch(
        setSnackbar({
          open: true,
          message: "Error processing order.",
          severity: "error",
        })
      );
    } else if (newOrder?.status === "COMPLETED") {
      setLoading(false);
      const count = +(params.get("count") ?? "0");
      setCount(count);
      setOpenGiftConfirm(true);
      dispatch(
        setSnackbar({
          open: true,
          message: "Topup successful.",
          severity: "success",
        })
      );
      const newUser = await getUser(user?.uid ?? "");
      dispatch(setUser(newUser));
    }
    if (!localLoading) return;
    checkOrder(orderUid);
  };

  useEffect(() => {
    if (!user?.uid) return;
    const chatTopupOrderUid = localStorage.getItem("chatTopupOrderUid");
    if (!chatTopupOrderUid) return;
    localStorage.removeItem("chatTopupOrderUid");
    setLoading(true);
    // check if order comes thru, if so, popup modal
    checkOrder(chatTopupOrderUid);
  }, [user?.uid, checkOrder]);

  const { data: gift } = useQuery({
    queryKey: ["gift", room?.user?.uid],
    queryFn: () => getGiftsReceived(room?.user?.uid ?? ""),
    enabled: !!user?.uid && !!room?.user?.uid,
  });

  type TextMessageType = "TEXT" | "GIF" | "GIFT" | "IMAGE";

  const sendTextMessage = (
    text: string,
    type: TextMessageType,
    giftNumber?: string,
    transferAssetId?: string,
    returned?: boolean
  ) => {
    const msg = ChatSocket.Instance.sendMessage({
      type,
      authorId: user.uid,
      roomId: roomId,
      text: text,
      metadata: { giftNumber, transferAssetId, returned },
    });

    dispatch(addMessage({ roomId: roomId, message: msg }));
  };

  useEffect(() => {
    ChatSocket.Instance.connect();
    dispatch(setCurrentRoomId(roomId));
    dispatch(loadMessages(roomId));

    return () => {
      dispatch(setCurrentRoomId(null));
      dispatch(resetMessages());
    };
  }, [dispatch, roomId]);

  useEffect(() => {
    if (!messagesEl) return;
    messagesEl.scrollTo(0, messagesEl.scrollHeight);
  }, [messagesEl]);

  const renderContent = (message: ChatMessage) => {
    switch (message.type) {
      case "IMAGE":
        return (
          <Message.CustomContent>
            <Box
              component={"img"}
              src={message.metadata.assetUrl || "/loading.gif"}
              width={message.metadata.assetUrl ? 150 : 20}
              height={message.metadata.assetUrl ? 150 : 20}
              style={{
                cursor: "pointer",
                objectFit: "cover",
              }}
              onClick={() =>
                setImageUrl(message.metadata.assetUrl || "/loading.gif")
              }
            />
          </Message.CustomContent>
        );
      case "GIFT":
        if (!!message.metadata?.returned) {
          return (
            <Message.CustomContent>
              <GiftReturnedMessage
                giftNumber={message.metadata?.giftNumber}
                isSender={user.uid === message.authorId}
              />
            </Message.CustomContent>
          );
        }
        return (
          <Message.CustomContent>
            <GiftMessage
              text={
                user.uid === message.authorId ? `You've sent` : message.text
              }
              giftNumber={message.metadata?.giftNumber}
              uid={message.metadata?.transferAssetId}
              isSender={user.uid === message.authorId}
              senderUid={message.authorId}
              setGiftAmount={setGiftAmount}
              setGiftUid={setGiftUid}
              setOpen={setOpenReturn}
            />
          </Message.CustomContent>
        );

      default:
        return <Message.TextContent>{message.text}</Message.TextContent>;
    }
  };

  // Render Gift Notification on top bar of chat room. Pending BE
  const renderGiftNotification = (giftNumber: number) => {
    return (
      <Stack
        sx={{
          px: 1,
          py: 0.5,
          background: "white",
          borderRadius: "100px",
          border: "1px solid #6135B5",
          gap: 1,
        }}
        direction="row"
        alignItems="center"
      >
        <Typography variant="notice" fontSize={14} whiteSpace="nowrap">
          Sent {giftNumber}
        </Typography>
        <img
          src="/gift.png"
          alt="gift"
          width={24}
          height={24}
          style={{
            width: 24,
            height: 24,
            pointerEvents: "none",
            objectFit: "contain",
            minWidth: 24,
            minHeight: 24,
          }}
        />
      </Stack>
    );
  };

  const uniqueMessages = useMemo(() => {
    // const uids = messages.map((message) => message.metadata.uid);
    const tmpIds = messages.map((message) => message.metadata.tmpId);
    return messages.filter((message, index) => {
      return !tmpIds.includes(message.metadata.tmpId, index + 1);
    });
  }, [messages]);

  useEffect(() => {
    if (messages.length === 0) return;
    if (messagesChecked) return;

    const lastMessagesCount = 10;
    const lastMessages = uniqueMessages.slice(-lastMessagesCount);

    const checksum = md5(
      lastMessages
        .reverse()
        .map((m) => m.metadata.uid || "")
        .join(",")
    );

    ChatSocket.Instance.checkMessages(roomId, checksum, lastMessagesCount);

    setMessagesChecked(true);
  }, [uniqueMessages, messagesChecked, roomId]);

  return (
    <Stack
      height="100dvh"
      position={"relative"}
      direction="column"
      width="100%"
    >
      <Stack direction={"column"} padding={1}>
        <Box display={"flex"} gap={1} alignItems={"center"}>
          <ChevronLeft
            sx={{
              cursor: "pointer",
              fontSize: 24,
              color: "black",
              mr: 1,
            }}
            onClick={() => navigate("/chat")}
          />
          <img
            src={
              room?.user?.avatar ??
              (room?.user?.gender === "M"
                ? "/new_male.png"
                : room?.user?.gender === "F"
                ? "new_female.png"
                : "/logo_border.png")
            }
            alt="avatar"
            style={{
              width: 36,
              height: 36,
              objectFit: "cover",
              cursor: "pointer",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
              borderRadius: "50%",
            }}
            onClick={() => {
              if (room?.id?.startsWith("sys-") || !room?.user?.uid) return;
              navigate(`/profile/${room?.user?.uid ?? ""}`);
            }}
          />
          <Typography variant="headlineSmall" color="black">
            {room?.user.displayName ?? "-"}
          </Typography>
          {!!gift && renderGiftNotification(gift ?? 0)}
          <Stack flex={1} />
          {!roomId?.startsWith("sys-") && comingSoon === "false" && (
            <img
              src="/more.svg"
              alt="more"
              style={{
                color: "black",
                width: 16,
                height: 16,
                objectFit: "contain",
                cursor: "pointer",
                WebkitTapHighlightColor: "rgba(0,0,0,0)",
              }}
              ref={(el) => setPopoverEl(el)}
              onClick={() => {
                setOpenPopover(true);
              }}
            />
          )}
        </Box>
      </Stack>
      <ChatRoomBanner
        primaryText="Stand out by gifting"
        secondaryText="You're 5x more likely to get a reply! Gifts received will be exchangable for cash soon"
      />
      <Stack
        direction="column"
        flex={1}
        sx={{
          backgroundImage: "url(/chat_bg.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          overflow: "auto",
          mb: 5,
        }}
        ref={(el) => setMessagesEl(el)}
      >
        <MessageList
          style={{
            backgroundImage: "url(/chat_bg.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          autoScrollToBottomOnMount
          autoScrollToBottom
        >
          {uniqueMessages.map((message, i) => (
            <Message
              key={`${message.metadata.uid}-${i}`}
              model={{
                message: message.text,
                sentTime: new Date(message.createdAt).toISOString(),
                sender: message.authorId,
                position: "single",
                direction:
                  message.authorId === user.uid ? "outgoing" : "incoming",
              }}
              style={{
                marginTop: i === 0 ? 24 : 8,
              }}
            >
              {renderContent(message)}
            </Message>
          ))}
        </MessageList>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        px={1}
        gap={1}
        width="100%"
        position="absolute"
        bottom={0}
        mb={3}
      >
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          sx={{
            px: 1.5,
            borderRadius: "48px",
            border: "1px solid #EAEAEA",
            background: "white",
          }}
          width="100%"
        >
          <CustomTextField
            placeholder="Type a message..."
            value={text}
            setValue={setText}
            sx={{
              flex: 1,
            }}
            maxRows={3}
            variant="none"
            size="small"
            className="message-input"
          />
          <img
            src="/camera.svg"
            alt="camera"
            style={{
              objectFit: "contain",
              width: 24,
              height: 24,
              cursor: "pointer",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
            }}
            onClick={() => {
              fileRef.current?.click();
            }}
          />
          {!!text && (
            <img
              src="/send_msg.svg"
              alt="send message"
              style={{
                objectFit: "contain",
                width: 20,
                height: 20,
                cursor: "pointer",
                WebkitTapHighlightColor: "rgba(0,0,0,0)",
              }}
              onClick={() => {
                sendTextMessage(text, "TEXT");
                // focus custom-input
                const el = document.getElementsByClassName("message-input");
                if (el.length > 0) {
                  // @ts-ignore
                  el[0].focus();
                }
                setText("");
              }}
            />
          )}
        </Stack>
        {!text && comingSoon === "false" && (
          <img
            src="/gift.svg"
            alt="gift"
            style={{
              objectFit: "contain",
              width: 40,
              height: 40,
              cursor: "pointer",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
            }}
            onClick={() => {
              setOpenGift(true);
            }}
          />
        )}
      </Stack>
      <input
        accept="image/*"
        id="selectImage"
        type="file"
        style={{ display: "none" }}
        onChange={async (e) => {
          const file = e.target.files?.item(0);
          if (file) {
            dispatch(
              addMessage({
                roomId: roomId,
                message: {
                  type: "IMAGE",
                  authorId: user.uid,
                  roomId: roomId,
                  text: "",
                  metadata: {
                    assetUrl: "",
                  },
                  createdAt: new Date().getTime(),
                  updatedAt: new Date().getTime(),
                },
              })
            );

            const compressedFile = await compressImage(file);
            if (!compressedFile) {
              dispatch(
                setSnackbar({
                  open: true,
                  severity: "error",
                  message:
                    "You file size is too big, please choose another image.",
                })
              );
              return;
            }
            uploadAsset(compressedFile, "chat")
              .then((url) => {
                ChatSocket.Instance.sendMessage({
                  type: "IMAGE",
                  authorId: user.uid,
                  roomId: roomId,
                  text: "",
                  metadata: {
                    assetUrl: url,
                  },
                });
              })
              .catch((err: any) => {
                dispatch(
                  setSnackbar({
                    open: true,
                    severity: "error",
                    message:
                      err?.response?.data?.error?.message ??
                      "Error uploading image.",
                  })
                );
              });
          }
        }}
        ref={fileRef}
      />

      {imageUrl && (
        <ImageViewer
          src={[imageUrl]}
          closeOnClickOutside={true}
          onClose={() => setImageUrl(undefined)}
        />
      )}

      {showContextMenu && (
        <Box
          position={"absolute"}
          top={0}
          left={0}
          width={"100%"}
          height={"100vh"}
          display={"flex"}
          alignItems={"flex-end"}
          zIndex={999}
          sx={{
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
          onClick={() => setShowContextMenu(false)}
        >
          <ul className="chat-ui-kit-attach-menu">
            <li onClick={() => fileRef.current?.click()}>Image</li>
            <li>Gif</li>
          </ul>
        </Box>
      )}
      <Popover
        open={openPopover}
        anchorEl={popoverEl}
        onClose={() => {
          setOpenPopover(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          mt: 2,
        }}
      >
        <Stack
          alignItems="center"
          gap={1.5}
          direction="row"
          py={1}
          px={2}
          sx={{
            cursor: "pointer",
            WebkitTapHighlightColor: "rgba(0,0,0,0)",
          }}
          onClick={() => {
            setOpenReport(true);
          }}
        >
          <img
            src="/report.svg"
            alt="Report"
            style={{
              width: 24,
              height: 24,
              objectFit: "contain",
            }}
          />
          <Typography variant="notice" fontSize={16} fontWeight={400}>
            Report
          </Typography>
        </Stack>
      </Popover>
      <ReportModal
        open={openReport}
        setOpen={setOpenReport}
        target={room?.user?.uid ?? ""}
        name={room?.user?.displayName ?? "-"}
      />
      <GiftModal
        open={openGift}
        setOpen={setOpenGift}
        setOpenGiftConfirm={setOpenGiftConfirm}
        count={count}
        setCount={setCount}
        loading={loading}
      />
      <GiftConfirmModal
        open={openGiftConfirm}
        setOpen={setOpenGiftConfirm}
        count={count}
        setOpenTopup={setOpenTopup}
        recipient={room?.user}
        sendTextMessage={sendTextMessage}
        roomUid={roomId ?? ""}
        setMinAmount={setMinAmount}
        paymentLoading={loading}
      />
      <TopUpModal
        open={openTopup}
        setOpen={setOpenTopup}
        isRequired
        minAmount={minAmount}
        successUrl={`${process.env.REACT_APP_APP_URL}/chat/${roomId}?count=${count}`}
        from="chat"
      />
      <ReturnGiftModal
        open={openReturn}
        setOpen={setOpenReturn}
        name={room?.user?.displayName ?? "-"}
        amount={giftAmount}
        uid={giftUid}
        sendTextMessage={sendTextMessage}
      />
    </Stack>
  );
};

export default ChatPage;
