import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { Permission, useAuth } from "../../hooks/useAuth";
import { CustomAutocomplete, Option } from "../common/CustomAutocomplete";
import { CustomButton } from "../common/CustomButton";
import { CustomCard } from "../common/CustomCard";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getInterests } from "../../api/template";
import { useMemo } from "react";
import { createPost } from "../../api/post";
import { useEffect } from "react";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { getUser } from "../../api/user";
import { setUser } from "../../redux/features/userSlice";

export const InterestsPage = () => {
  const navigate = useNavigate();
  useAuth(Permission.USER_ONLY);
  const [values, setValues] = useState<Option[]>([]);
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  const { data: interests } = useQuery({
    queryKey: ["interests"],
    queryFn: () => getInterests(),
    enabled: !!user?.uid,
  });

  const options = useMemo(() => {
    if (!interests) return [];
    return interests.map((inter: any) => ({
      value: inter.value,
      label: inter.label,
    }));
  }, [interests]);

  useEffect(() => {
    setValues(
      user.interests.map((inter) => ({
        value: inter.id,
        label: inter.label,
      }))
    );
  }, [user]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
    >
      <CustomCard title="Your Delulu Details">
        <Typography variant="headlineSmall" textAlign="center" mb={1}>
          Lastly, tell us your 5 interests
        </Typography>
        <CustomAutocomplete
          placeholder="E.G. Cooking"
          values={values}
          setValues={setValues}
          options={options}
          max={5}
        />
        <CustomButton
          text="Next"
          variant="outlined"
          size="medium"
          onClick={async () => {
            if (!user?.uid) return;
            try {
              await createPost("INTERESTS", {
                interests: values.map((v) => v.value),
              });
              const newUser = await getUser(user.uid);
              dispatch(setUser(newUser));
              navigate("/");
            } catch (err: any) {
              dispatch(
                setSnackbar({
                  open: true,
                  message:
                    err?.response?.data?.error?.message ??
                    "Error updating interests.",
                  severity: "error",
                })
              );
            }
          }}
          sx={{
            mt: 2,
            mb: 4,
          }}
        />
      </CustomCard>
    </Stack>
  );
};
