import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { Permission, useAuth } from "../../hooks/useAuth";
import { Stack, Typography } from "@mui/material";
import { CustomButton } from "../common/CustomButton";
import HowItWorksModal from "./HowItWorksModal";
import { ReferModal } from "../ReferModal/ReferModal";
import { TotalEarningCard } from "./TotalEarningCard";
import { WingmanSuccessModal } from "../WingmanSuccessModal/WingmanSuccessModal";
import { WithdrawModal } from "../WithdrawModal/WithdrawModal";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useAppSelector } from "../../redux/hooks";
import { getTransactions } from "../../api/transaction";
import {
  GetTransactionsResponse,
  TransactionDto,
} from "../../types/TransactionDto";
import moment from "moment-timezone";
import { ReferralIgPage } from "../ReferIgPage/ReferIgPage";
import { useSearchParams } from "react-router-dom";
import { TopUpModal } from "../TopUpModal/TopUpModal";

type Props = {
  openWingmanSuccess: boolean;
  setOpenWingmanSuccess: Function;
  isActive: boolean;
  loading: boolean;
  setLoading: Function;
  purchaseWingman: Function;
  onRefetch: () => void;
};

export const WingmanReferralPage: FC<Props> = ({
  openWingmanSuccess,
  setOpenWingmanSuccess,
  isActive,
  loading,
  setLoading,
  purchaseWingman,
  onRefetch,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReferModalOpen, setIsReferModalOpen] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const appState = useAppSelector((state) => state.appState);
  const [params] = useSearchParams();
  const [openTopup, setOpenTopup] = useState(false);
  const [minAmount, setMinAmount] = useState(0);

  useEffect(() => {
    const from = params.get("from");
    if (from === "mission") {
      setIsReferModalOpen(true);
    }
  }, [params]);

  useAuth(Permission.USER_ONLY);

  const {
    data: transactionPages,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    [`TRANSACTIONS`, user.uid],
    (queryContext) => getTransactions(25, queryContext?.pageParam ?? 0),
    {
      enabled: !!user?.uid,
      cacheTime: 0,
      getNextPageParam: (lastPage: GetTransactionsResponse, allPages: any) => {
        if (
          lastPage?.data?.length < lastPage?.meta.pagination.limit ||
          lastPage?.meta.pagination.start + lastPage?.meta.pagination.limit >=
            lastPage?.meta.pagination.total
        ) {
          return undefined;
        }
        return lastPage.meta.pagination.start + lastPage.meta.pagination.limit;
      },
    }
  );

  const transactions = useMemo(() => {
    const result: TransactionDto[] = [];
    transactionPages?.pages.forEach((page) => {
      result.push(...page.data);
    });
    return result;
  }, [transactionPages]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/dull_bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
        alignItems: "center",
        pt: 6,
        pb: 13,
      }}
    >
      <Stack
        direction="column"
        borderRadius={4}
        sx={{
          backgroundColor: "white",
          alignItems: "center",
          width: "calc(100% - 8px - 8px)",
          flex: 1,
          maxWidth: 600,
          overflowY: "auto",
        }}
      >
        <TotalEarningCard setOpenWithdraw={setOpenWithdraw} />
        <Typography variant="bodyLarge">Earn when</Typography>
        <Typography variant="bodySmall" mb={3} textAlign={"center"}>
          you friends spend,
          <br />
          get matched or <br />
          sign up as a wingman
        </Typography>
        <img
          src="/sep.svg"
          height={20}
          width={118}
          alt="sep"
          style={{
            objectFit: "contain",
            marginBottom: 16,
            pointerEvents: "none",
          }}
        />
        {!user?.wingman?.active && (
          <CustomButton
            text="Activate Wingman Mode"
            variant="tertiary"
            size="small"
            onClick={() => {
              setIsModalOpen(true);
            }}
            sx={{
              alignSelf: "center",
              pt: 1,
              pb: 1,
              pl: 3,
              pr: 3,
              width: 250,
              maxWidth: "100%",
            }}
          />
        )}
        <CustomButton
          text="Invite a friend"
          onClick={() => setIsReferModalOpen(true)}
          size="small"
          variant="secondary"
          sx={{
            pt: 1,
            pb: 1,
            pl: 3,
            pr: 3,
            mb: 2,
            mt: 1,
            width: 250,
            maxWidth: "100%",
          }}
        />
        <Typography
          variant="bodySmall"
          sx={{
            cursor: "pointer",
            WebkitTapHighlightColor: "rgba(0,0,0,0)",
          }}
          onClick={() => setIsModalOpen(true)}
          mb={3}
        >
          How it works
        </Typography>
        {transactions.length > 0 && (
          <>
            <img
              src="/line.svg"
              alt="line"
              style={{
                width: "100%",
                height: "12px",
                objectFit: "cover",
                pointerEvents: "none",
                marginBottom: 12,
              }}
            />
            <Typography variant="bodySmall" color="#22171E" mb={2}>
              Transaction History
            </Typography>
          </>
        )}
        <Stack direction="column" width="100%" height="100%">
          {transactions
            .filter(
              (t) =>
                t.wallet.asset.label === "FREE DRAW" ||
                (t.wallet.asset.type === "GIFT" && t.type === "TRANSFER_IN") ||
                t.wallet.asset.label === appState.currency
            )
            .map((transaction, index) => {
              return (
                <Stack
                  width="100%"
                  gap={1}
                  direction="column"
                  justifyContent="flex-start"
                  pl={2}
                  pr={2}
                  key={index}
                >
                  <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Stack direction="column" gap={0.5}>
                      <Typography
                        variant="notice"
                        fontSize={12}
                        fontWeight={400}
                        color="#22171E"
                      >
                        {transaction.wallet.asset.label === "FREE DRAW" &&
                        transaction.metadata?.refType !== "swipe"
                          ? "Free Draw"
                          : transaction.wallet.asset.type === "GIFT"
                          ? "Received Gift"
                          : transaction?.description || "GIft Cash Out"}
                      </Typography>
                      <Typography variant="notice" color="#22171E">
                        {moment(transaction?.updatedAt).format("DD-MM, h:mm a")}{" "}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="notice"
                      color={transaction.amount >= 0 ? "#4F9238" : "#DC2D6D"}
                      fontSize={12}
                      fontWeight={700}
                    >
                      {transaction.amount >= 0 ? "+" : "-"}
                      {transaction.wallet.asset.type !== "GIFT" &&
                        (transaction.wallet.asset.label === "FREE DRAW"
                          ? appState.currencySymbol
                          : transaction.wallet.asset.symbol)}{" "}
                      {Math.abs(transaction.amount).toFixed(2)}
                      {transaction.wallet.asset.type === "GIFT" && " 🎁"}
                    </Typography>
                  </Stack>
                  {index !== transactions.length - 1 && (
                    <Stack
                      width="100%"
                      height="1px"
                      sx={{
                        backgroundColor: "#EAEAEA",
                        mb: 1,
                      }}
                    />
                  )}
                  {index === transactions.length - 1 && <Stack height={16} />}
                </Stack>
              );
            })}
          {hasNextPage && (
            <>
              <CustomButton
                size="small"
                text="Load More"
                variant="outlined"
                sx={{
                  alignSelf: "center",
                }}
                onClick={() => {
                  fetchNextPage();
                }}
              />
              <Stack pb={2} />
              <img
                src="/short_line.svg"
                alt="short_line"
                style={{
                  width: 200,
                  height: 12,
                  objectFit: "cover",
                  pointerEvents: "none",
                  alignSelf: "center",
                }}
              />
              <Stack pb={2} />
            </>
          )}
        </Stack>
      </Stack>
      <TopUpModal
        open={openTopup}
        setOpen={setOpenTopup}
        isRequired
        minAmount={minAmount}
        successUrl={`${process.env.REACT_APP_APP_URL}/referral`}
        from="wingman"
      />
      <HowItWorksModal
        purchaseWingman={purchaseWingman}
        open={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        setLoading={setLoading}
        loading={loading}
        setMinAmount={setMinAmount}
        setOpenTopup={setOpenTopup}
      />
      <ReferModal
        open={isReferModalOpen}
        setOpen={setIsReferModalOpen}
        setHowItWorksOpen={setIsModalOpen}
        refetch={() => {
          onRefetch();
        }}
      />
      <WingmanSuccessModal
        open={openWingmanSuccess}
        setOpen={setOpenWingmanSuccess}
        refetch={() => {
          onRefetch();
        }}
      />
      <WithdrawModal
        open={openWithdraw}
        setOpen={setOpenWithdraw}
        isWingman={isActive}
        activateWingman={() => {
          setIsModalOpen(true);
          setOpenWithdraw(false);
        }}
      />
      <ReferralIgPage />
    </Stack>
  );
};
