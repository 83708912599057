import { Stack, Typography } from "@mui/material";
import { Message } from "@chatscope/chat-ui-kit-react";

type Props = {
  giftNumber?: string;
  isSender: boolean;
};

export const GiftReturnedMessage: React.FC<Props> = ({
  giftNumber,
  isSender,
}) => {
  return (
    <Message.CustomContent>
      <Stack
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        gap="6px"
      >
        {isSender && <Message.TextContent>You returned</Message.TextContent>}
        <Typography variant="bodyLarge" color="black">
          {giftNumber}
        </Typography>
        <img
          src="/gift.png"
          alt="gift"
          style={{
            objectFit: "contain",
            width: 24,
            height: 24,
            pointerEvents: "none",
          }}
        />
        {!isSender && (
          <Message.TextContent> has been returned to you</Message.TextContent>
        )}
      </Stack>
    </Message.CustomContent>
  );
};
