import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Stack, Typography } from "@mui/material";
import { Option } from "./CustomAutocomplete";

type Props = {
  placeholder: string;
  value: string;
  setValue: (v: string) => void;
  options: Option[];
  sx?: any;
  variant?: "outlined" | "normal";
};

export const CustomSelectField: React.FC<Props> = ({
  placeholder,
  value,
  setValue,
  options,
  sx,
  variant = "outlined",
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };

  if (variant === "normal") {
    return (
      <Stack
        direction="column"
        gap={0.5}
        width="100%"
        sx={sx}
        onClick={(e) => e.stopPropagation()}
      >
        <Typography variant="bodySmall" color="#1E1E1E">
          {placeholder}
        </Typography>
        <FormControl
          size="small"
          sx={{
            width: "100%",
            borderRadius: "8px",
            color: "#1E1E1E",
            boxShadow: "none",
            border: "1px solid rgba(0, 0, 0, 0.10)",
            ".MuiInputBase-input": {
              color: "#1E1E1E",
              fontSize: 14,
              fontWeight: 400,
              fontFamily: "Rye",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiSelect-icon": {
              color: "#D2D2D2",
              fontSize: 14,
            },
          }}
        >
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={value}
            onChange={handleChange}
            variant="outlined"
            disableUnderline
            size="small"
            placeholder={placeholder}
          >
            {options.map((opt) => (
              <MenuItem value={opt.value} key={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    );
  }

  return (
    <Stack direction="column" width="100%">
      <FormControl
        size="small"
        sx={{
          width: "100%",
          borderRadius: "8px",
          color: "#1E1E1E",
          boxShadow: "none",
          border: "1px solid rgba(0,0,0,0.1)",
          ".MuiInputBase-input": {
            color: "#1E1E1E",
            fontSize: 14,
            fontWeight: 400,
            fontFamily: "Rye",
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          ".MuiSelect-icon": {
            color: "#D2D2D2",
            fontSize: 14,
          },
        }}
      >
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={value}
          onChange={handleChange}
          variant="outlined"
          disableUnderline
          size="small"
          placeholder={placeholder}
        >
          {options.map((opt) => (
            <MenuItem value={opt.value} key={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
