import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { uploadAsset } from "../../api/file";
import { getUser, updateUser } from "../../api/user";
import { Permission, useAuth } from "../../hooks/useAuth";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { setUser } from "../../redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { UserDto } from "../../types/UserDto";
import { compressImage } from "../../utility/file";
import { CustomButton } from "../common/CustomButton";
import { CustomCard } from "../common/CustomCard";

type Props = {};

export const AvatarPage: React.FC<Props> = () => {
  const user = useAppSelector((state) => state.user.user);
  useAuth(Permission.USER_ONLY);
  const [avatar, setAvatar] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAvatar(user?.avatar ?? "");
  }, [user]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
    >
      <CustomCard title="Your Delulu Details">
        <Typography variant="headlineSmall" textAlign="center" mb={1}>
          Upload your avatar
        </Typography>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="image-file"
          hidden
          type="file"
          onChange={async (e) => {
            setLoading(true);
            // Upload to S3 and setAvatar link after getting URL
            if (!!e.target.files?.[0]) {
              const compressedFile = await compressImage(e.target.files[0]);
              if (!compressedFile) {
                dispatch(
                  setSnackbar({
                    open: true,
                    severity: "error",
                    message:
                      "You file size is too big, please choose another image.",
                  })
                );
                return;
              }
              uploadAsset(compressedFile, "avatar")
                .then((url) => {
                  setAvatar(url);
                  setLoading(false);
                })
                .catch((err: any) => {
                  setLoading(false);
                  dispatch(
                    setSnackbar({
                      open: true,
                      severity: "error",
                      message:
                        err?.response?.data?.error?.message ??
                        "Error uploading avatar.",
                    })
                  );
                });
            } else {
              setLoading(false);
            }
          }}
        />
        <label
          htmlFor="image-file"
          style={{
            alignSelf: "center",
          }}
        >
          <Stack
            position="relative"
            sx={{
              width: "120px",
              height: "120px",
              mb: 2,
              cursor: "pointer",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
            }}
          >
            <img
              src={
                !!avatar
                  ? avatar
                  : user?.gender === "M"
                  ? "/new_male.png"
                  : "/new_female.png"
              }
              alt="avatar"
              style={{
                width: 120,
                height: 120,
                objectFit: "cover",
                borderRadius: "50%",
                position: "absolute",
              }}
              className="avatar"
            />
            <Stack
              justifyContent="center"
              alignItems="center"
              width="120px"
              height="120px"
              sx={{
                borderRadius: "50%",
                position: "absolute",
                background: "rgba(0,0,0,0.3)",
              }}
            >
              <img
                src="/camera.svg"
                alt="camera"
                style={{
                  width: 40,
                  height: 40,
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Stack>
          </Stack>
        </label>
        <CustomButton
          text="Next"
          variant="outlined"
          size="medium"
          loading={loading}
          disabled={!avatar}
          onClick={async () => {
            if (!user?.uid) return;
            try {
              await updateUser(user.uid, {
                avatar: avatar,
              });
              // get user
              const newUser: UserDto = await getUser(user.uid);
              dispatch(setUser(newUser));
              navigate("/");
            } catch (err: any) {
              dispatch(
                setSnackbar({
                  open: true,
                  message:
                    err?.response?.data?.error?.message ??
                    "Error updating avatar.",
                  severity: "error",
                })
              );
            }
          }}
          sx={{
            mt: 2,
            mb: 4,
          }}
        />
      </CustomCard>
    </Stack>
  );
};
