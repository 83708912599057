import { Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getEarnStats } from "../../api/stats";
import { useAppSelector } from "../../redux/hooks";
import { CustomButton } from "../common/CustomButton";

type Props = {
  setOpenWithdraw: Function;
};

export const TotalEarningCard: React.FC<Props> = ({ setOpenWithdraw }) => {
  const user = useAppSelector((state) => state.user.user);
  const appState = useAppSelector((state) => state.appState);

  const { data: stat } = useQuery({
    queryKey: ["stat", user?.uid],
    queryFn: () => getEarnStats(user.uid),
    enabled: !!user?.uid,
  });

  const myEarningPercentage = useMemo(() => {
    if (!stat) return "0%";
    return `${
      ((stat.swipeEarnings ?? 0) /
        ((stat.referralEarnings ?? 0) + (stat.swipeEarnings ?? 0))) *
      100
    }%`;
  }, [stat]);

  return (
    <Stack
      direction="column"
      pt={4}
      pl={4}
      pr={4}
      alignItems="center"
      sx={{
        background: "white",
        width: "100%",
        maxWidth: 600,
      }}
    >
      <Typography variant="headlineSmall" color="#22171E" mb={1}>
        Total Earning
      </Typography>
      <img
        src="/short_line.svg"
        alt="short_line"
        style={{
          width: 232,
          height: 12,
          objectFit: "cover",
          pointerEvents: "none",
          marginBottom: 4,
        }}
      />
      <Typography variant="heroSmall" mb={1} color="#22171E">
        {appState.currencySymbol}{" "}
        {((stat?.swipeEarnings ?? 0) + (stat?.referralEarnings ?? 0)).toFixed(
          2
        )}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        sx={{
          background: "#137CA3",
          borderRadius: "100px",
          height: "4px",
          mb: 1,
        }}
      >
        <Stack
          sx={{
            borderRadius: "100px",
            border: "2px solid white",
            background: "#DC2D6D",
            width: myEarningPercentage,
            height: "8px",
          }}
        />
      </Stack>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Stack direction="column" gap={0.5}>
          <Typography
            variant="notice"
            fontSize={12}
            color="#DC2D6D"
            fontWeight={700}
          >
            Your Earnings
          </Typography>
          <Typography variant="notice" fontSize={12} fontWeight={400}>
            {appState.currencySymbol} {(stat?.swipeEarnings ?? 0).toFixed(2)}
          </Typography>
        </Stack>
        <Stack direction="column" alignItems="flex-end" gap={0.5}>
          <Typography
            variant="notice"
            fontSize={12}
            color="#137CA3"
            fontWeight={700}
          >
            Referral Earnings
          </Typography>
          <Typography variant="notice" fontSize={12} fontWeight={400}>
            {appState.currencySymbol} {(stat?.referralEarnings ?? 0).toFixed(2)}
          </Typography>
        </Stack>
      </Stack>
      <CustomButton
        text="Withdraw"
        onClick={() => {
          // if (!isActive) setOpenWingman(true);
          // else setOpenWithdraw(true);

          setOpenWithdraw(true);
        }}
        variant="outlined"
        size="small"
        sx={{
          mb: 3,
        }}
      />
      <img
        src="/sep.svg"
        height={20}
        width={118}
        alt="sep"
        style={{
          objectFit: "contain",
          marginBottom: 16,
          pointerEvents: "none",
        }}
      />
    </Stack>
  );
};
