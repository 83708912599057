import API from "./api";
import { store } from "../redux/store";

export const createWithdrawal = async (
  method: "TNG" | "AMAZON",
  amount: number,
  account: string,
  data: {
    currency?: string
    phone?: string
    email?: string
  }
) => {
  const appState = store.getState().appState;
  data = Object.fromEntries(Object.entries(data).filter(([_, v]) => v));

  const resp = await API.post("/withdrawals", {
    data: {
      amount: amount,
      currency: appState.currency,
      metadata: {
        method: method,
        account: account,
        ...data
      },
    },
  });
  return resp.data?.data;
};
