import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDto } from "../../types/UserDto";
import DBService from "../../services/DBService";

export interface ChatMessage {
  authorId: string;
  roomId: string;
  text: string;
  type: "TEXT" | "IMAGE" | "GIF" | "GIFT";
  createdAt: number;
  updatedAt: number;
  metadata: {
    tmpId?: string;
    uid?: string;
    assetUrl?: string;
    giftNumber?: string;
    transferAssetId?: string;
    returned?: boolean;
  };
}

export interface ChatRoom {
  id: string;
  user: UserDto;
  lastMessage: ChatMessage | null;
  timestamp: number;
  hasUnreadMessages: boolean;
}

interface ChatRoomsState {
  rooms: ChatRoom[];
  currentRoomId: string | null;
}

const initialState: ChatRoomsState = {
  rooms: [],
  currentRoomId: null,
};

export const chatRooms = createSlice({
  name: "chatRooms",
  initialState,
  reducers: {
    resetChatRooms: () => initialState,
    setChatRooms: (state, action: PayloadAction<ChatRoom[]>) => {
      let rooms = action.payload.sort((a, b) => b.timestamp - a.timestamp);
      rooms = rooms.sort((a, b) => (a.id.includes("sys-") ? -1 : 0));

      state.rooms = rooms;
    },
    setCurrentRoomId: (state, action: PayloadAction<string | null>) => {
      const roomId = action.payload;

      if (roomId) {
        DBService.Instance.setChatRoomHasUnreadMessagesStates(roomId, false);
      }

      state.currentRoomId = roomId;
    },
  },
});

export const { resetChatRooms, setChatRooms, setCurrentRoomId } =
  chatRooms.actions;
export default chatRooms.reducer;
