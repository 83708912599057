import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { gsap } from "gsap";

type Props = {
  index: number;
  selected: boolean;
  open: boolean;
};

export const GachaEndCard: React.FC<Props> = ({ selected, index, open }) => {
  useEffect(() => {
    if (selected) {
      gsap.to(`.gacha-card-container-${index}`, {
        opacity: 1,
        zIndex: 5,
        x: 0,
        y: 0,
      });
      gsap.set(".card-count", {
        text: `Draw more`,
        delay: 0.25,
        fontSize: 12,
        stagger: 0.25,
        cursor: "pointer",
      });
    } else {
      gsap.to(`.gacha-card-container-${index}`, {
        opacity: 0,
        zIndex: -1,
        x: 0,
        y: 0,
      });
      gsap.set(".card-count", {
        text: `Tap to rotate`,
        delay: 0.25,
        fontSize: 12,
        stagger: 0.25,
        cursor: "auto",
      });
    }
  }, [selected]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        position: "absolute",
        width: "100%",
        gap: 2,
        height: open ? "100%" : "500px",
        bottom: open ? 0 : 56,
        paddingBottom: open ? "48px" : 0,
      }}
      className={`gacha-card-container gacha-card-container-${index} gacha-card-container-others`}
    >
      <Stack
        direction="column"
        sx={{
          width: "100%",
          borderRadius: "16px",
          background: open ? "white" : "url(/card_back.png) no-repeat center",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
          alignItems: "center",
          transform: "translate(0%, -300%)",
          maxWidth: open ? "450px" : "300px",
          height: "100%",
        }}
        className={`gacha-card-${index} gacha-card gacha-card-others`}
      >
        <Stack
          direction="column"
          alignItems="center"
          width="100%"
          height="100%"
          sx={{
            opacity: index !== 0 && open ? 1 : 0,
            overflowY: "auto",
            pointerEvents: "none",
          }}
          className={`gacha-content gacha-content-${index}`}
        >
          <img
            src="/sep.svg"
            height={20}
            width={118}
            alt="sep"
            style={{
              objectFit: "contain",
              marginBottom: 16,
              marginTop: 16,
              pointerEvents: "none",
            }}
          />
          <Stack alignItems="center" justifyContent="center" flex={1}>
            <Typography
              variant="headlineMedium"
              textAlign="center"
              color="#22171E"
            >
              End of your Draw.
            </Typography>
          </Stack>
          <img
            src="/short_line.svg"
            alt="short_line"
            style={{
              width: 100,
              height: 12,
              objectFit: "cover",
              pointerEvents: "none",
              transform: "rotateX(180deg)",
              marginBottom: 16,
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
