import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { CustomButton } from "../common/CustomButton";

export const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
      position="relative"
    >
      <img
        src="/logo.svg"
        alt="Delulu"
        style={{
          objectFit: "contain",
          width: 126,
          height: 164,
          marginBottom: 16,
          pointerEvents: "none",
        }}
      />
      <Typography variant="heroSmall" mb={3}>
        DeLuLu
      </Typography>
      <CustomButton
        text="Let's get Delulu"
        onClick={() => {
          navigate("/login");
        }}
        sx={{
          pl: 6,
          pr: 6,
        }}
      />
      <Typography
        textAlign="center"
        variant="notice"
        color="white"
        fontSize={14}
        mt={3}
        width={200}
      >
        By logging in, you agree with our{" "}
        <span
          style={{
            textDecorationLine: "underline",
            cursor: "pointer",
            WebkitTapHighlightColor: "rgba(0,0,0,0)",
          }}
          onClick={() => {
            navigate("/privacy-policy");
          }}
        >
          Privacy Policy
        </span>{" "}
        and{" "}
        <span
          style={{
            textDecorationLine: "underline",
            cursor: "pointer",
            WebkitTapHighlightColor: "rgba(0,0,0,0)",
          }}
          onClick={() => {
            navigate("/terms-conditions");
          }}
        >
          Terms & Conditions
        </span>
        .
      </Typography>
    </Stack>
  );
};
