import { Stack, Typography } from "@mui/material";
import { Permission, useAuth } from "../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { LandingPage } from "../LandingPage/LandingPage";
import { useEffect, useState } from "react";
import { ComingSoonPage } from "../ComingSoonPage/ComingSoonPage";
import { gsap } from "gsap";
import { PriceCards } from "../PriceCards/PriceCards";
import { GachaCards } from "../GachaCards/GachaCards";
import { getUser } from "../../api/user";
import { setUser } from "../../redux/features/userSlice";
import { MissionModal } from "../MissionModal/MissionModal";
import { WalletBalance } from "../WalletBalance/WalletBalance";
import { TopUpModal } from "../TopUpModal/TopUpModal";
import { ProductDto } from "../../types/ProductDto";
import { SwipeDto } from "../../types/SwipeDto";
import { SplashScreen } from "../SplashScreen/SplashScreen";
import { useNavigate } from "react-router";
import { setMessageSuggestion } from "../../redux/features/messageSuggestionSlice";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getReward } from "../../api/reward";
import { getFreeDrawWallet } from "../../api/wallet";
import { PwaReminder } from "../PwaReminder/PwaReminder";
import { PaymentSuccessModal } from "../PaymentSuccessModal/PaymentSuccessModal";
import { FreeDrawModal } from "../FreeDrawModal/FreeDrawModal";
import { ReferModal } from "../ReferModal/ReferModal";
import HowItWorksModal from "../ReferralPage/HowItWorksModal";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { ReferralIgPage } from "../ReferIgPage/ReferIgPage";
import { delay } from "../../utility/time";
import {
  createWingmanOrder,
  getOrder,
  getProductCatalogs,
} from "../../api/payment";
import { WingmanSuccessModal } from "../WingmanSuccessModal/WingmanSuccessModal";
import { FreeEarlyBirdModal } from "../FreeEarlyBirdModal/FreeEarlyBirdModal";
import OneSignal from "react-onesignal";
import { setAppStateByPhoneNumber } from "../../redux/features/appStateSlice";

let localLoading = false;

export const MainPage = () => {
  const user = useAppSelector((state) => state.user.user);
  const [comingSoon, setComingSoon] = useState(true);
  const [openPrice, setOpenPrice] = useState(false);
  const [canAnimate, setCanAnimate] = useState(false);
  const [animateNext, setAnimateNext] = useState(false);
  const [animateGacha, setAnimateGacha] = useState(false);
  const dispatch = useAppDispatch();
  const [slug, setSlug] = useState("draw-female");
  const [gender, setGender] = useState("GIRL");
  const [selectedProduct, setSelectedProduct] = useState<ProductDto | null>(
    null
  );
  const [openMissionPopup, setOpenMissionPopup] = useState(false);
  const [openFreeDraw, setOpenFreeDraw] = useState(false);
  const [openTopup, setOpenTopup] = useState(false);
  const [openWingmanTopup, setOpenWingmanTopup] = useState(false);
  const [swipes, setSwipes] = useState<SwipeDto[]>([]);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const navigate = useNavigate();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const [params] = useSearchParams();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wingmanMinAmount, setWingmanMinAmount] = useState(0);
  const [openWingmanSuccess, setOpenWingmanSuccess] = useState(false);
  const [openRm5FreeDraw, setOpenRm5FreeDraw] = useState(false);
  const [canOpenMissionPopup, setCanOpenMissionPopup] = useState(false);

  const checkWingmanOrder = async (orderUid: string) => {
    await delay(1);
    const newOrder = await getOrder(orderUid);
    if (
      newOrder?.status === "PAYMENT_FAILED" ||
      newOrder?.status === "CANCELLED" ||
      newOrder?.status === "ERROR"
    ) {
      dispatch(
        setSnackbar({
          open: true,
          message: "Error processing order.",
          severity: "error",
        })
      );
    } else if (newOrder?.status === "COMPLETED") {
      setOpenWingmanSuccess(true);
      const newUser = await getUser(user?.uid ?? "");
      dispatch(setUser(newUser));
    } else {
      checkWingmanOrder(orderUid);
    }
  };

  const purchaseWingman = async () => {
    // purchase wingman here
    try {
      const wingmanCatalog = await getProductCatalogs("wingman");
      const product = wingmanCatalog?.[0];
      if (!product) {
        dispatch(
          setSnackbar({
            open: true,
            message: "Product not found.",
            severity: "error",
          })
        );
        return;
      }
      const currencyId = product.prices?.[0]?.currency?.id;
      if (!currencyId) {
        dispatch(
          setSnackbar({
            open: true,
            message: "Currency not found.",
            severity: "error",
          })
        );
        return;
      }
      const wingmanOrder = await createWingmanOrder(product.id, currencyId);
      checkWingmanOrder(wingmanOrder.uid);
    } catch (err: any) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message:
            err?.response?.data?.error?.message ??
            "Error purchasing, please reload and try again.",
        })
      );
    }
  };

  useEffect(() => {
    localLoading = loading;
  }, [loading]);

  const checkOrder = async (orderUid: string) => {
    await delay(1);
    const newOrder = await getOrder(orderUid);
    if (
      newOrder?.status === "PAYMENT_FAILED" ||
      newOrder?.status === "CANCELLED" ||
      newOrder?.status === "ERROR"
    ) {
      setLoading(false);
      dispatch(
        setSnackbar({
          open: true,
          message: "Error processing order.",
          severity: "error",
        })
      );
    } else if (newOrder?.status === "COMPLETED") {
      setLoading(false);
      setSuccess(true);
      const newUser = await getUser(user?.uid ?? "");
      dispatch(setUser(newUser));
    }
    if (!localLoading) return;
    checkOrder(orderUid);
  };

  const initOneSignal = async () => {
    try {
      const isSupported = OneSignal.Notifications.isPushSupported();
      // console.log("OneSignal isSupported", isSupported);
      // console.log(
      //   "OneSignal isPermitted",
      //   OneSignal.Notifications.permission,
      //   OneSignal.Notifications.permissionNative
      // );
      // console.log("OneSignal logging out");
      // await OneSignal.logout();
      // console.log("OneSignal logging in", user.uid);
      const loggedInOneSignalUid = localStorage.getItem("onesignal");
      if (isSupported && loggedInOneSignalUid !== user.uid) {
        await OneSignal.login(user.uid);
        localStorage.setItem("onesignal", user.uid);
      }
      //console.log("OneSignal login success");
    } catch (err) {
      //console.log("Error in OneSignal", err);
      console.error(err);
    }
  };

  useEffect(() => {
    if (!user?.uid) return;
    initOneSignal();
    const mainTopupOrderUid = localStorage.getItem("mainTopupOrderUid");
    if (!mainTopupOrderUid) return;
    localStorage.removeItem("mainTopupOrderUid");
    setLoading(true);
    // check if order comes thru, if so, popup modal
    checkOrder(mainTopupOrderUid);
  }, [user?.uid]);

  useEffect(() => {
    const comingSoon = process.env.REACT_APP_COMING_SOON;
    if (comingSoon === "false") setComingSoon(false);
  }, []);

  useEffect(() => {
    const action = params.get("action");
    const invite = params.get("invite");
    if (invite === "true") {
      setShowInvite(true);
      return;
    }
    if (!!action) {
      setOpenPrice(true);
      setGender(action.includes("female") ? "GIRL" : "BOY");
      setSlug(action);
    } else {
      setOpenPrice(false);
      setGender("GIRL");
      setSlug("draw-female");
    }
  }, [params]);

  useEffect(() => {
    if (!user?.uid) return;
    getUser(user.uid)
      .then((newUser) => {
        dispatch(setUser(newUser));
        dispatch(
          setAppStateByPhoneNumber({ phoneNumber: newUser.email.split("@")[0] })
        );
      })
      .catch((err) => {
        console.log(err);
      });
    // try {
    //   if (!!navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(
    //       (v) => {
    //         const lat = v.coords.latitude ?? 0;
    //         const long = v.coords.longitude ?? 0;
    //         // update user location
    //         updateUserLocation(lat, long);
    //       },
    //       (err: any) => {
    //         console.log(err);
    //       }
    //     );
    //   }
    // } catch (err: any) {
    //   console.log(err);
    // }
  }, []);

  useAuth(Permission.ANYONE);
  // useNotificationRedirect();

  // validate user info
  useEffect(() => {
    if (!user || !user.uid) return;
    if (!user?.displayName) {
      navigate("/name");
      return;
    }
    if (!user?.avatar) {
      navigate("/avatar");
      return;
    }
    if (!user?.dob) {
      navigate("/dob");
      return;
    }
    if (!user.gender) {
      navigate("/gender");
      return;
    }
    const infoPost = user.posts?.find((p) => p.section === "INFO");
    if (!infoPost?.content?.school) {
      navigate("/uni");
      return;
    }

    try {
      const notificationPermission = Notification.permission;
      const skipNotificationCheck = localStorage.getItem(
        "skipNotificationCheck"
      );
      if (notificationPermission !== "granted" && !skipNotificationCheck) {
        navigate("/notification");
        return;
      }
    } catch (e) {
      console.error(e);
    }

    setShowSplashScreen(false);
  }, [user]);

  const { data: freeDraw, refetch: refetchFreeDraw } = useQuery({
    queryKey: ["freeDraw", user?.uid],
    queryFn: () => getFreeDrawWallet(),
    enabled: !!user?.uid,
  });

  const { data: reward, refetch: refetchReward } = useQuery({
    queryKey: ["reward", user?.uid],
    queryFn: () => getReward(),
    enabled: !!user?.uid,
  });

  useEffect(() => {
    if (!user?.uid) return;
    refetchFreeDraw();
    refetchReward();
  }, []);

  useEffect(() => {
    // check if has pending tasks
    reward?.missions?.forEach((mission) => {
      if (mission.completionCount > 0) return;
      setCanOpenMissionPopup(true);
    });
    const hasShownRm5Draw = localStorage.getItem("hasShownRm5Draw");
    setOpenMissionPopup(false);
    const comingSoon = process.env.REACT_APP_COMING_SOON;
    const canPopFreeDraw = localStorage.getItem("canPopFreeDraw");
    if ((freeDraw?.balance ?? 0) > 0) {
      if ((freeDraw?.balance ?? 0) >= 5 && hasShownRm5Draw !== "true") {
        setOpenRm5FreeDraw(true);
        return;
      }
      if (comingSoon !== "false" || canPopFreeDraw !== "true") return;
      // if there is any free draw, don't show mission
      setOpenFreeDraw(true);
      localStorage.setItem("canPopFreeDraw", "false");
      return;
    }
    // check if has pending tasks
    reward?.missions?.forEach((mission) => {
      if (mission.completionCount > 0) return;
      if (comingSoon === "false") setOpenMissionPopup(true);
    });
  }, [reward, freeDraw]);

  useEffect(() => {
    if (!openPrice) {
      const tl = gsap.timeline();
      tl.call(() => {
        setAnimateGacha(false);
        setCanAnimate(false);
        setAnimateNext(false);
      })
        .to(".gender-card", {
          pointerEvents: "auto",
          display: "flex",
          duration: "0.01",
        })
        .to(
          ".gender-card",
          {
            opacity: 1,
            pointerEvents: "auto",
            display: "flex",
          },
          "<"
        );
    } else {
      const tl = gsap.timeline();
      tl.to(
        ".gender-card",
        {
          opacity: 0,
          pointerEvents: "none",
        },
        "<"
      )
        .to(".gender-card", {
          pointerEvents: "none",
          display: "none",
          duration: "0.01",
        })
        .call(() => {
          setCanAnimate(true);
        });
    }
  }, [openPrice]);

  useEffect(() => {
    if (!animateNext) {
      const tl = gsap.timeline();
      tl.to(".main-bg", {
        filter: "grayscale(100%)",
        "-webkit-filter": "grayscale(100%)",
      });
    } else {
      const tl = gsap.timeline();
      tl.to(".main-bg", {
        filter: "grayscale(0%)",
        "-webkit-filter": "grayscale(0%)",
      });
    }
  }, [animateNext]);

  if (!user?.uid) return <LandingPage />;

  if (showSplashScreen) return <SplashScreen />;

  if (comingSoon) return <ComingSoonPage setComingSoon={setComingSoon} />;

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        gap: 2,
        justifyContent: "center",
        height: "100dvh",
      }}
      position="relative"
    >
      <Stack
        sx={{
          width: "100%",
          height: "100dvh",
          position: "absolute",
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/wingman_bg.png), lightgray 50% / cover no-repeat",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          WebkitFilter: "grayscale(100%)",
          filter: "grayscale(100%)",
          zIndex: -1,
          pointerEvents: "none",
          overflowY: "auto",
        }}
        className="main-bg"
      />
      <Stack height={16} />
      {!animateGacha && (
        <WalletBalance
          setOpen={setOpenTopup}
          loading={loading}
          canOpenMissionPopup={canOpenMissionPopup}
          setOpenMissionPopup={setOpenMissionPopup}
        />
      )}
      <PriceCards
        canAnimate={canAnimate}
        setAnimateGacha={setAnimateGacha}
        gender={gender}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        slug={slug}
        setSwipes={setSwipes}
      />
      <GachaCards
        canAnimate={animateGacha}
        setAnimateNext={setAnimateNext}
        swipes={swipes}
        showSuggestions={showSuggestions}
        setShowSuggestions={setShowSuggestions}
      />
      <Stack
        direction="column"
        position="relative"
        sx={{
          width: "80%",
          height: "50%",
          borderRadius: "16px",
          border: "2px solid #FFF",
          background: "url(/new_female.png), lightgray 50% / cover no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          boxShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.70)",
          maxWidth: "261px",
          justifyContent: "flex-end",
          alignItems: "center",
          cursor: "pointer",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
        }}
        className="gender-card"
        onClick={() => {
          navigate("/?action=draw-female");
        }}
      >
        {(freeDraw?.balance ?? 0) > 0 && (
          <Typography
            variant="bodySmall"
            color="white"
            sx={{
              py: "4px",
              px: "8px",
              borderRadius: "8px",
              background: "#B4237A",
              position: "absolute",
              top: 8,
              right: 16,
              width: "fit-content",
            }}
            noWrap
            textAlign="center"
          >
            Free Draw Available
          </Typography>
        )}
        <Typography
          variant="headlineMedium"
          color="#22171E"
          textAlign="center"
          sx={{
            background: "white",
            borderRadius: "16px",
            pt: "6px",
            pb: "6px",
            pl: "12px",
            pr: "12px",
            mb: "18px",
          }}
        >
          Draw Female
        </Typography>
      </Stack>
      <Stack
        direction="column"
        sx={{
          width: "80%",
          height: "50%",
          borderRadius: "16px",
          border: "2px solid #FFF",
          background: "url(/new_male.png), lightgray 50% / cover no-repeat",
          backgroundPosition: "top",
          backgroundSize: "cover",
          boxShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.70)",
          maxWidth: "261px",
          justifyContent: "flex-end",
          alignItems: "center",
          cursor: "pointer",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
        }}
        position="relative"
        className="gender-card"
        onClick={() => {
          navigate("/?action=draw-male");
        }}
      >
        {(freeDraw?.balance ?? 0) > 0 && (
          <Typography
            variant="bodySmall"
            color="white"
            sx={{
              py: "4px",
              px: "8px",
              borderRadius: "8px",
              background: "#B4237A",
              position: "absolute",
              top: 8,
              right: 16,
              width: "fit-content",
            }}
            noWrap
            textAlign="center"
          >
            Free Draw Available
          </Typography>
        )}
        <Typography
          variant="headlineMedium"
          color="#22171E"
          textAlign="center"
          sx={{
            background: "white",
            borderRadius: "16px",
            pt: "6px",
            pb: "6px",
            pl: "12px",
            pr: "12px",
            mb: "18px",
          }}
        >
          Draw Male
        </Typography>
      </Stack>
      {!showSuggestions ? (
        <Stack height={104} />
      ) : (
        <Stack
          direction="column"
          gap={0.5}
          p={1}
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          // position="fixed"
          // bottom={0}
          sx={{
            background: "white",
            zIndex: 10,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {[
            "If you were to only eat 1 cuisine for the rest of your life, what would it be?",
            "One destination to travel to",
            "What would you do with the money if you win a 1Mil lottery?",
          ].map((t, index) => {
            return (
              <Typography
                key={index}
                textAlign="center"
                variant="bodyMedium"
                px={1.5}
                py={1.25}
                sx={{
                  borderRadius: "16px",
                  border: "1px solid rgba(0, 0, 0, 0.10)",
                  cursor: "pointer",
                  WebkitTapHighlightColor: "rgba(0,0,0,0)",
                }}
                onClick={() => {
                  dispatch(setMessageSuggestion(t));
                  setShowSuggestions(false);
                }}
              >
                {t}
              </Typography>
            );
          })}
        </Stack>
      )}
      <MissionModal
        open={openMissionPopup}
        setOpen={setOpenMissionPopup}
        missions={reward?.missions ?? []}
      />
      <FreeDrawModal
        open={openFreeDraw}
        setOpen={setOpenFreeDraw}
        missions={reward?.missions ?? []}
      />
      <HowItWorksModal
        open={showHowItWorks}
        handleClose={() => {
          setShowHowItWorks(false);
        }}
        loading={loading}
        setLoading={setLoading}
        setMinAmount={setWingmanMinAmount}
        setOpenTopup={setOpenWingmanTopup}
        purchaseWingman={purchaseWingman}
      />
      <TopUpModal
        open={openWingmanTopup}
        setOpen={setOpenWingmanTopup}
        isRequired
        minAmount={wingmanMinAmount}
        successUrl={`${process.env.REACT_APP_APP_URL}/referral`}
        from="wingman"
      />
      <TopUpModal
        open={openTopup}
        setOpen={setOpenTopup}
        isRequired={false}
        successUrl={`${process.env.REACT_APP_APP_URL}`}
        from="main"
      />
      <PaymentSuccessModal open={success} setOpen={setSuccess} />
      <PwaReminder />
      <ReferModal
        open={showInvite}
        setOpen={(v: boolean) => {
          setShowInvite(v);
          navigate("/");
        }}
        setHowItWorksOpen={setShowHowItWorks}
        refetch={() => {
          refetchFreeDraw();
          refetchReward();
          localStorage.setItem("canPopFreeDraw", "true");
        }}
      />
      <ReferralIgPage />
      <WingmanSuccessModal
        open={openWingmanSuccess}
        setOpen={setOpenWingmanSuccess}
        refetch={() => {
          refetchFreeDraw();
          refetchReward();
          localStorage.setItem("canPopFreeDraw", "true");
        }}
      />
      <FreeEarlyBirdModal open={openRm5FreeDraw} setOpen={setOpenRm5FreeDraw} />
    </Stack>
  );
};
