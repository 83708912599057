import { Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { truncate } from "../../utility/string";
import { CustomFlipCard } from "../common/CustomFlipCard";
import { ProfileImages } from "./ProfileImages";
import moment from "moment";
import { UserDto } from "../../types/UserDto";

type Props = {
  user: UserDto;
};

export const ProfileCard: React.FC<Props> = ({ user }) => {
  const [showback, setShowback] = useState(false);

  const ageRange = useMemo(() => {
    if (!user?.dob) return "-";
    var years = moment().diff(user?.dob, "years");
    if (years >= 18 && years <= 25) return "18 - 25";
    if (years >= 26 && years <= 35) return "26 - 35";
    if (years >= 36) return "36 and above";
    return "-";
  }, [user?.dob]);

  const description = useMemo(() => {
    if (!user?.posts) return "";
    const result = [];
    const infoPost = user?.posts?.find((p) => p.section === "INFO");
    if (!!infoPost?.content?.school) {
      result.push(infoPost?.content?.school);
    }
    if (!!infoPost?.content?.job) {
      result.push(infoPost?.content?.job);
    }
    if (!!infoPost?.content?.religion) {
      result.push(infoPost?.content?.religion);
    }
    if (!!infoPost?.content?.height) {
      result.push(infoPost?.content?.height + " cm");
    }
    return result.join(", ");
  }, [user?.posts]);

  const bio = useMemo(() => {
    if (!user?.posts) return "";
    const bioPost = user?.posts?.find((p) => p.section === "BIO");
    if (!!bioPost?.content?.text) {
      return bioPost?.content?.text;
    }
    return "";
  }, [user?.posts]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        width: "100%",
        height: "100%",
      }}
      className={`gacha-card-container`}
      onClick={() => {
        setShowback((prev) => !prev);
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "calc(100% - 16px - 16px)",
          borderRadius: "16px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          background: "white",
          backgroundPosition: "center",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
          alignItems: "center",
          maxWidth: 600,
          height: "100%",
          overflowY: "auto",
        }}
        className={`gacha-card`}
      >
        <CustomFlipCard
          showBack={showback}
          frontComponent={
            <Stack
              direction="column"
              alignItems="center"
              width="100%"
              height="100%"
              className={`gacha-content`}
            >
              <Stack
                direction="column"
                alignItems="center"
                position="absolute"
                top={16}
                left={16}
              >
                <Typography
                  variant="headlineSmall"
                  color="#22171E"
                  mb={1}
                  textAlign="center"
                >
                  {ageRange}
                </Typography>
              </Stack>
              <img
                src="/sep.svg"
                height={20}
                width={118}
                alt="sep"
                style={{
                  objectFit: "contain",
                  marginBottom: 16,
                  marginTop: 16,
                  pointerEvents: "none",
                }}
              />
              <img
                src={
                  !!user?.avatar
                    ? user?.avatar
                    : user?.gender === "M"
                    ? "/new_male.png"
                    : user?.gender === "F"
                    ? "new_female.png"
                    : "/logo_border.png"
                }
                alt="avatar"
                style={{
                  borderRadius: "50%",
                  width: 100,
                  height: 100,
                  objectFit: "cover",
                  marginBottom: 8,
                  pointerEvents: "none",
                }}
              />
              <Typography
                variant="headlineLarge"
                color="#22171E"
                textAlign="center"
                mb={1}
              >
                {user?.displayName ?? "-"}
              </Typography>
              <Typography
                variant="bodySmall"
                color="#22171E"
                textAlign="center"
                mb={1.5}
              >
                looking for {user?.lookingFor ?? "-"}
              </Typography>
              <img
                src="/line.svg"
                alt="line"
                style={{
                  width: "100%",
                  height: "12px",
                  objectFit: "cover",
                  marginBottom: 8,
                  pointerEvents: "none",
                }}
              />
              <Typography
                variant="headlineSmall"
                color="#22171E"
                mb={1}
                ml={4}
                mr={4}
                textAlign="center"
                whiteSpace="pre-line"
              >
                {description}
              </Typography>
              <Stack
                p={3}
                sx={{
                  background: !!bio ? "url(/container.svg)" : undefined,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  mb: 2,
                }}
              >
                <Typography
                  variant="notice"
                  color="black"
                  textAlign="center"
                  whiteSpace="pre-line"
                  fontSize={12}
                >
                  {truncate(bio, 100)}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  flex: 1,
                }}
              />
              <img
                src="/sep.svg"
                height={20}
                width={118}
                alt="sep"
                style={{
                  objectFit: "contain",
                  marginBottom: 16,
                  marginTop: 16,
                  pointerEvents: "none",
                }}
              />
            </Stack>
          }
          backComponent={
            <Stack
              direction="column"
              alignItems="center"
              width="100%"
              height="100%"
              className={`gacha-content`}
            >
              <Stack height={8} />
              <ProfileImages user={user} />
              <img
                src="/short_line.svg"
                alt="shirt_line"
                style={{
                  width: 184,
                  height: 10,
                  objectFit: "cover",
                  pointerEvents: "none",
                  marginBottom: 16,
                  marginTop: 24,
                }}
              />
              <img
                src="/sep.svg"
                height={20}
                width={118}
                alt="sep"
                style={{
                  objectFit: "contain",
                  marginBottom: 16,
                  marginTop: 16,
                  pointerEvents: "none",
                }}
              />
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};
