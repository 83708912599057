import { Stack, Typography } from "@mui/material";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { Permission, useAuth } from "../../hooks/useAuth";
import { setUser } from "../../redux/features/userSlice";
import { useAppDispatch } from "../../redux/hooks";
import { auth } from "../../utility/firebase";
import { CustomButton } from "../common/CustomButton";
import { CustomCard } from "../common/CustomCard";
import OtpInput from "react-otp-input";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NoAuthAPI } from "../../api/api";
import { getUser } from "../../api/user";
import axios from "axios";

export const LoginPage = () => {
  const [phone, setPhone] = useState("+1");
  const [otp, setOtp] = useState("");
  const [openOtpPage, setOpenOtpPage] = useState(false);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const [verifier, setVerifier] = useState<RecaptchaVerifier | null>(null);
  const [confirmationResult, setConfirmationResult] = useState<
    ConfirmationResult | null | boolean
  >(null);

  useAuth(Permission.GUEST_ONLY);

  useEffect(() => {
    if (!element) return;
    const v = new RecaptchaVerifier(auth, element, {
      size: "invisible",
    });
    setVerifier(v);
    return () => {
      v.clear();
    };
  }, [element]);

  useEffect(() => {
    axios.get("https://ipapi.co/json/").then((resp) => {
      switch (resp.data.country.toLowerCase()) {
        case "my":
          setPhone("60");
          break;
        default:
          setPhone("1");
          break;
      }
    });
  }, []);

  const isTestNumber = (phoneNumber: string) =>
    phoneNumber.indexOf("60150") === 0 || phoneNumber.indexOf("11111111") === 0;

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
    >
      <CustomCard title="Phone Number">
        <Stack alignItems="center">
          <PhoneInput
            onlyCountries={["my", "us"]}
            value={phone}
            disabled={openOtpPage}
            onChange={(v) => {
              if (!openOtpPage) setPhone(v);
            }}
            inputStyle={{
              fontSize: 24,
              fontWeight: 400,
              color: "#1E1E1E",
              fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
            }}
            buttonStyle={{
              background: "white",
              border: "none",
            }}
            dropdownStyle={{
              fontSize: 14,
              fontWeight: 400,
              color: "#1E1E1E",
              fontFamily: "Rye, Roboto, Helvetica, Arial, sans-serif",
            }}
          />
        </Stack>
        <Stack height={16} />
        <img
          src="/sep.svg"
          height={12}
          width={68}
          alt="sep"
          style={{
            objectFit: "contain",
            marginBottom: 8,
          }}
        />
        {openOtpPage && (
          <OtpInput
            value={otp}
            onChange={async (v) => {
              setOtp(v);
              if (v.length === 6 && !!confirmationResult) {
                try {
                  setLoading(true);
                  let token = v;
                  let strategy = "firebase";
                  if (isTestNumber(phone)) {
                    strategy = "tester";
                  } else {
                    await (confirmationResult as ConfirmationResult).confirm(v);
                    token = (await auth.currentUser?.getIdToken()) ?? "";
                  }
                  const resp = await NoAuthAPI.post("/auth/logins", {
                    data: {
                      strategy,
                      identifier: `+${phone}`,
                      otp: token,
                    },
                  });
                  localStorage.setItem(
                    "app_session",
                    resp.data?.data?.token ?? ""
                  );
                  const newUser = await getUser(resp.data?.data?.user?.uid);
                  dispatch(setUser(newUser));
                  setLoading(false);
                } catch (err: any) {
                  console.error(err);

                  setLoading(false);
                  dispatch(
                    setSnackbar({
                      open: true,
                      message:
                        err?.response?.data?.error?.message ??
                        "Error logging in",
                      severity: "error",
                    })
                  );
                }
              }
            }}
            numInputs={6}
            renderSeparator={<span style={{ width: 4 }}></span>}
            renderInput={(props) => (
              <input
                {...props}
                style={{
                  borderRadius: "8px",
                  padding: "8.5px 13.5px",
                  border: "1px solid rgba(0,0,0,0.1)",
                  width: 40,
                  textAlign: "center",
                }}
                pattern="[0-9]*"
                min="0"
                inputMode="numeric"
                type="number"
              />
            )}
          />
        )}
        <CustomButton
          disabled={
            (!openOtpPage && !phone) ||
            (openOtpPage && otp.length !== 6 && !!confirmationResult)
          }
          loading={loading}
          text={openOtpPage ? "Submit" : "Request OTP"}
          variant="outlined"
          size="medium"
          sx={{
            mt: 2,
            mb: openOtpPage ? 2 : 0,
          }}
          onClick={async () => {
            if (openOtpPage && !isTestNumber(phone)) {
              try {
                setLoading(true);
                if (!!confirmationResult)
                  await (confirmationResult as ConfirmationResult).confirm(otp);
                setLoading(false);
              } catch (err: any) {
                setLoading(false);
                dispatch(
                  setSnackbar({
                    open: true,
                    message:
                      err?.response?.data?.error?.message ?? "Error logging in",
                    severity: "error",
                  })
                );
              }
              return;
            }
            if (!verifier) {
              dispatch(
                setSnackbar({
                  open: true,
                  message:
                    "Recaptcha has expired, kindly reload this page and try again.",
                  severity: "error",
                })
              );
              return;
            }
            try {
              setLoading(true);
              if (isTestNumber(phone)) {
                // Test phone number can bypass firebase OTP
                setOpenOtpPage(true);
                setConfirmationResult(true);
                setLoading(false);
              } else {
                signInWithPhoneNumber(auth, `+${phone}`, verifier)
                  .then((confirmationResult) => {
                    setConfirmationResult(confirmationResult);
                    setOpenOtpPage(true);
                    setLoading(false);
                  })
                  .catch((error) => {
                    setLoading(false);
                    dispatch(
                      setSnackbar({
                        open: true,
                        message: error.toString() ?? "Error getting OTP",
                        severity: "error",
                      })
                    );
                  });
              }
            } catch (err: any) {
              console.log(err);
              setLoading(false);
              dispatch(
                setSnackbar({
                  open: true,
                  message:
                    err?.response?.data?.error?.message ?? "Error getting OTP",
                  severity: "error",
                })
              );
            }
          }}
        />
        {openOtpPage && (
          <Typography
            variant="bodySmall"
            sx={{
              cursor: "pointer",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
            }}
            onClick={async () => {
              if (!verifier) {
                dispatch(
                  setSnackbar({
                    open: true,
                    message:
                      "Recaptcha has expired, kindly reload this page and try again.",
                    severity: "error",
                  })
                );
                return;
              }
              try {
                setLoading(true);
                signInWithPhoneNumber(auth, `+${phone}`, verifier)
                  .then((confirmationResult) => {
                    setConfirmationResult(confirmationResult);
                    setOpenOtpPage(true);
                    setLoading(false);
                  })
                  .catch((error) => {
                    setLoading(false);
                    dispatch(
                      setSnackbar({
                        open: true,
                        message: error.toString() ?? "Error getting OTP",
                        severity: "error",
                      })
                    );
                  });
              } catch (err: any) {
                console.log(err);
                setLoading(false);
                dispatch(
                  setSnackbar({
                    open: true,
                    message:
                      err?.response?.data?.error?.message ??
                      "Error getting OTP",
                    severity: "error",
                  })
                );
              }
            }}
          >
            resend OTP
          </Typography>
        )}
      </CustomCard>
      <Stack id="sign-in-button" ref={(el) => setElement(el)} />
    </Stack>
  );
};
