import { io, Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import DBService from "../services/DBService";
import { ChatMessage, setChatRooms } from "../redux/features/chatSlice";
import { store } from "../redux/store";
import { addMessage } from "../redux/features/messageSlice";

const URL = process.env.REACT_APP_SOCKET_URL || "";

class ChatSocket {
  socket?: Socket;
  private static _instance: ChatSocket;

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  connect() {
    if (this.socket) {
      return;
    }

    const accessToken = localStorage.getItem("app_session");
    if (!accessToken) {
      return;
    }

    this.socket = io(URL, {
      autoConnect: true,
      forceNew: true,
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    this.socket.on("connect", () => this.onConnect());
    this.socket.on("messages", this.onMessage);
    this.socket.connect();
  }

  disconnect() {
    this.socket?.disconnect();
    this.socket = undefined;
  }

  onConnect() {
    console.log("connected");
    this.join();
  }

  async onMessage(data: ChatMessage[]) {
    const rooms: { [key: string]: ChatMessage[] } = {};
    const currentRoomId = store.getState().chat.currentRoomId;
    const existingRooms = store.getState().chat.rooms;

    data.forEach((m) => {
      if (!rooms[m.roomId]) rooms[m.roomId] = [];
      rooms[m.roomId].push(m);
      DBService.Instance.setChatRoomState({
        roomId: m.roomId,
        lastMessage: m,
        hasUnreadMessages: currentRoomId !== m.roomId,
      });

      localStorage.setItem("lastMessageId", m.metadata.uid ?? "");
    });

    if (currentRoomId !== null && rooms[currentRoomId]) {
      store.dispatch(addMessage({ roomId: currentRoomId, message: rooms[currentRoomId] }));
    }

    for (const roomId in rooms) {
      const messages = rooms[roomId];
      const existingMessages =
        (await DBService.Instance.getMessages(roomId)) || [];

      if (messages.length === 1) {
        // if there is only one messages trust its order
        await DBService.Instance.setMessages(roomId, [
          ...existingMessages,
          ...messages,
        ]);
      } else {
        // if there are more than one messages, make sure its unique and  sort them by createdAt
        const uniquesMessages = [
          ...existingMessages,
          ...messages.filter((m) => !existingMessages.find((em) => em.metadata.uid === m.metadata.uid))
        ].sort((a, b) => a.createdAt - b.createdAt);

        await DBService.Instance.setMessages(roomId, uniquesMessages);
      }

      const index = existingRooms.findIndex((r) => r.id === roomId);
      if (index === -1) continue;

      DBService.Instance.setChatRoomState({
        roomId,
        lastMessage: messages[messages.length - 1],
        hasUnreadMessages: currentRoomId !== roomId,
      });

      store.dispatch(
        setChatRooms(
          existingRooms.map((r, i) => {
            if (i !== index) return r;

            return {
              ...r,
              lastMessage: messages[messages.length - 1],
              timestamp: messages[messages.length - 1].createdAt,
              hasUnreadMessages: currentRoomId !== roomId,
            };
          })
        )
      );
    }
  }

  async join() {
    console.log("joining", localStorage.getItem("lastMessageId"));
    this.socket?.emit("join", {
      lastMessageId: localStorage.getItem("lastMessageId") ?? "",
    });
  }

  async checkMessages(chatRoomId: string, checksum: string, msgCount: number = 10) {
    this.socket?.emit("checkMessages", {
      roomId: chatRoomId,
      checksum,
      count: msgCount,
    });
  }

  sendMessage(data: {
    type: "TEXT" | "IMAGE" | "GIF" | "GIFT";
    authorId: string;
    roomId: string;
    text: string;
    metadata?: {
      assetUrl?: string;
      giftNumber?: string;
      transferAssetId?: string;
      returned?: boolean;
    };
  }) {
    const message = {
      ...data,
      metadata: {
        ...data.metadata,
        tmpId: uuidv4(),
        uid: "",
      },
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
    };

    this.socket?.emit("sendMessage", message);

    return message;
  }
}

export default ChatSocket;
