import { UserDto } from "../types/UserDto";
import API from "./api";

export const createPost = async (
  section: string,
  content: any,
  url?: string
) => {
  const resp = await API.post(`/posts`, {
    data: {
      content: content,
      section: section,
      url: url,
    },
  });
  return resp.data;
};
