import { Stack, Typography } from "@mui/material";
import { Permission, useAuth } from "../../hooks/useAuth";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { useAppDispatch } from "../../redux/hooks";
import { CustomButton } from "../common/CustomButton";
import { CustomCard } from "../common/CustomCard";
import { useNavigate } from "react-router";
import OneSignal from "react-onesignal";
import { PwaReminder } from "../PwaReminder/PwaReminder";

export const NotificationPage = () => {
  useAuth(Permission.USER_ONLY);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.56%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100dvh",
      }}
    >
      <CustomCard title="Enable Notification">
        <Typography
          variant="headlineSmall"
          textAlign="center"
          mb={3}
          mt={4}
          whiteSpace="pre-line"
        >
          Allow us to keep you notified when you earn or have new friends!
        </Typography>
        <CustomButton
          text="Enable"
          variant="outlined"
          sx={{
            mb: 2,
          }}
          size="medium"
          onClick={async () => {
            try {
              localStorage.setItem("skipNotificationCheck", "true");

              const nativePermission = OneSignal.Notifications.permissionNative;
              if (nativePermission === "granted") {
                // already granted
                navigate("/");
                return;
              }
              if (nativePermission === "denied") {
                // already denied, onesignal can do nothing
                dispatch(
                  setSnackbar({
                    open: true,
                    message:
                      "You have disabled notifications. Turn it back on to get notified when your friends reply you",
                    severity: "error",
                  })
                );
              }
              // hard prompt
              await Notification.requestPermission();
              // soft prompt
              //   await OneSignal.Slidedown.promptPush({ force: true });
              navigate("/");
            } catch (err) {
              console.log(err);
            }
          }}
        />
      </CustomCard>

      <PwaReminder />
    </Stack>
  );
};
