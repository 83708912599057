import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import gsap from "gsap";
import { CustomButton } from "../common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useQuery } from "@tanstack/react-query";
import { getFiatWallet } from "../../api/wallet";
import { ProductDto } from "../../types/ProductDto";
import { createOrder, getOrder } from "../../api/payment";
import { setSnackbar } from "../../redux/features/snackbarSlice";
import { getSwipeRequest } from "../../api/swipe-req";
import { delay } from "../../utility/time";

type Props = {
  sx?: any;
  open: boolean;
  setOpen: Function;
  setOpenTopup: Function;
  setAnimateNext: Function;
  selectedProduct: ProductDto | null;
  gender: string;
  setSwipes: Function;
  isFree: boolean;
};

let localLoading = true;

export const PriceModal: React.FC<Props> = ({
  sx,
  open,
  setOpen,
  setOpenTopup,
  setAnimateNext,
  selectedProduct,
  gender,
  setSwipes,
  isFree,
}) => {
  const [backdrop, setBackdrop] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const user = useAppSelector((state) => state.user.user);
  const appState = useAppSelector((state) => state.appState);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localLoading = loading;
  }, [loading]);

  const { data: wallet } = useQuery({
    queryKey: ["wallet", user?.uid],
    queryFn: () => getFiatWallet(),
    enabled: !!user?.uid,
  });

  const getSwipes = async (orderUid: string) => {
    await delay(0.5);
    const newOrder = await getOrder(orderUid);
    if (!!newOrder?.meta?.deliveryResults?.[0]?.result) {
      // order completed
      const swipeReqId = newOrder?.meta?.deliveryResults?.[0]?.result;
      const swipeReq = await getSwipeRequest(swipeReqId);
      setAnimateNext(true);
      setSwipes(swipeReq?.swipes ?? []);
      setLoading(false);
      setOpen(false);
      return;
    } else if (
      newOrder?.status === "PAYMENT_FAILED" ||
      newOrder?.status === "CANCELLED" ||
      newOrder?.status === "ERROR"
    ) {
      dispatch(
        setSnackbar({
          open: true,
          message:
            "Error processing order, please contact our admin for further assistance.",
          severity: "error",
        })
      );
      setLoading(false);
    }
    if (!localLoading) return;
    getSwipes(orderUid);
  };

  const purchaseDraw = async () => {
    try {
      setLoading(true);
      // create order
      const order = await createOrder(
        selectedProduct?.id ?? 0,
        selectedProduct?.prices?.find(
          (p) => p?.currency?.label === appState.currency
        )?.currency?.id ?? 0,
        "internal"
      );
      getSwipes(order.uid);
    } catch (err: any) {
      setLoading(false);
      dispatch(
        setSnackbar({
          open: true,
          message:
            err?.response?.data?.error?.message ??
            "Error creating order, please reload your page and try again.",
          severity: "error",
        })
      );
    }
  };

  const handleTopupOrder = async (orderUid: string) => {
    await delay(0.5);
    const newOrder = await getOrder(orderUid);
    if (!!newOrder?.meta?.deliveryResults?.[0]?.result) {
      // start purchase
      purchaseDraw();
      return;
    } else if (
      newOrder?.status === "PAYMENT_FAILED" ||
      newOrder?.status === "CANCELLED" ||
      newOrder?.status === "ERROR"
    ) {
      dispatch(
        setSnackbar({
          open: true,
          message:
            "Error processing order, please contact our admin for further assistance.",
          severity: "error",
        })
      );
      setLoading(false);
    }
    if (!localLoading) return;
    handleTopupOrder(orderUid);
  };

  // purchase draw if productId exists
  useEffect(() => {
    const orderUid = (localStorage.getItem("orderUid") ?? "").toString();
    if (!!selectedProduct && !!orderUid) {
      localStorage.removeItem("orderUid");
      setLoading(true);
      handleTopupOrder(orderUid);
    }
  }, [selectedProduct?.id]);

  const purchaseFreeDraw = async (orderUid: string) => {
    await delay(0.5);
    const newOrder = await getOrder(orderUid);
    if (!!newOrder?.meta?.deliveryResults?.[0]?.result) {
      // order completed
      const swipeReqId = newOrder?.meta?.deliveryResults?.[0]?.result;
      const swipeReq = await getSwipeRequest(swipeReqId);
      setAnimateNext(true);
      setSwipes(swipeReq?.swipes ?? []);
      setLoading(false);
      setOpen(false);
      return;
    } else if (
      newOrder?.status === "PAYMENT_FAILED" ||
      newOrder?.status === "CANCELLED" ||
      newOrder?.status === "ERROR"
    ) {
      dispatch(
        setSnackbar({
          open: true,
          message:
            "Error processing order, please contact our admin for further assistance.",
          severity: "error",
        })
      );
      setLoading(false);
    }
    if (!localLoading) return;
    purchaseFreeDraw(orderUid);
  };

  useEffect(() => {
    if (!backdrop || !container) return;
    if (open) {
      gsap.to(backdrop, {
        visibility: "visible",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: 0,
        overwrite: true,
        duration: 0.05,
      });
    } else {
      gsap.to(backdrop, {
        visibility: "hidden",
        overwrite: true,
        duration: 0.5,
      });
      gsap.to(container, {
        y: "100%",
        overwrite: true,
        duration: 0.05,
      });
    }
  }, [open, backdrop, container]);

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        position: "fixed",
        justifyContent: "flex-end",
        top: 0,
        left: 0,
        zIndex: 10,
        alignItems: "center",
        backdropFilter: "blur(5px)",
        visibility: "hidden",
        height: "100dvh",
      }}
      ref={(el) => setBackdrop(el)}
      onClick={(e) => {
        e.stopPropagation();
        if (loading) return;
        setOpen(false);
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "600px",
          transform: "translateY(100%)",
          borderRadius: "24px 24px 0px 0px",
          background: "white",
          position: "relative",
          cursor: "auto",
          overflowY: "scroll",
          alignItems: "center",
          p: 3,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={(el) => setContainer(el)}
      >
        <Typography variant="headlineMedium" mb={2} textAlign="center">
          Draw {selectedProduct?.metadata?.quantity ?? 0} Card
        </Typography>
        <Stack
          direction="column"
          p={1}
          pl={3}
          pr={3}
          sx={{
            background: "white",
            borderRadius: "16px",
            boxShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.20)",
            mb: 2,
          }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="heroSmall" color="#22171E">
              {selectedProduct?.metadata?.quantity ?? 0}
            </Typography>
            <Stack direction="column">
              <Typography variant="bodySmall" color="#22171E" fontSize={10}>
                CARD
              </Typography>
              <Typography
                variant="bodyMedium"
                color={isFree ? "#B4237A" : "#22171E"}
              >
                {isFree
                  ? "FREE DRAW"
                  : `${
                      selectedProduct?.prices?.find(
                        (p) => p?.currency?.label === appState.currency
                      )?.currency?.symbol ?? appState.currencySymbol
                    }${(
                      selectedProduct?.prices?.find(
                        (p) => p?.currency?.label === appState.currency
                      )?.rate ?? 0
                    ).toFixed(2)}`}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              borderRadius: "8px",
              background:
                "radial-gradient(60.11% 60.11% at 50% 50%, rgba(0, 0, 0, 0.00) 60.94%, rgba(0, 0, 0, 0.40) 100%), url(/girl.png), lightgray 50% / cover no-repeat",
            }}
            justifyContent="center"
            alignItems="center"
            width="100%"
            p={3}
            pt={1}
            pb={1}
            direction="column"
          >
            <Typography variant="bodySmall" color="white">
              DELULU
            </Typography>
            <Typography variant="headlineMedium" color="white">
              {gender}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            alignSelf="flex-end"
            sx={{
              transform: "rotate(180deg)",
            }}
          >
            <Typography variant="heroSmall" color="#22171E">
              {selectedProduct?.metadata?.quantity}
            </Typography>
            <Stack direction="column">
              <Typography variant="bodySmall" color="#22171E" fontSize={10}>
                CARD
              </Typography>
              <Typography
                variant="bodyMedium"
                color={isFree ? "#B4237A" : "#22171E"}
              >
                {isFree
                  ? "FREE DRAW"
                  : `${
                      selectedProduct?.prices?.find(
                        (p) => p?.currency?.label === appState.currency
                      )?.currency?.symbol ?? appState.currencySymbol
                    }${(
                      selectedProduct?.prices?.find(
                        (p) => p?.currency?.label === appState.currency
                      )?.rate ?? 0
                    ).toFixed(2)}`}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Typography
          textAlign="center"
          variant="bodySmall"
          color="#22171E"
          sx={{
            opacity: 0.5,
          }}
        >
          Your Balance
        </Typography>
        <Typography
          textAlign="center"
          variant="bodyMedium"
          color="#22171E"
          mb={2}
        >
          {wallet?.asset?.symbol ?? appState.currencySymbol}
          {wallet?.balance?.toFixed(2) ?? "0.00"}
        </Typography>
        <CustomButton
          text={`${
            loading
              ? "Processing"
              : `Draw ${selectedProduct?.metadata?.quantity ?? 0}`
          }`}
          loading={loading}
          onClick={async () => {
            // handle free draw
            if (isFree) {
              try {
                setLoading(true);
                // create order
                const order = await createOrder(
                  selectedProduct?.id ?? 0,
                  selectedProduct?.prices?.find(
                    (p) => p?.currency?.label === "FREE DRAW"
                  )?.currency?.id ?? 0,
                  "internal"
                );
                purchaseFreeDraw(order.uid);
              } catch (err: any) {
                setLoading(false);
                dispatch(
                  setSnackbar({
                    open: true,
                    message:
                      err?.response?.data?.error?.message ??
                      "Error creating order, please reload your page and try again.",
                    severity: "error",
                  })
                );
              }
              return;
            }
            if (
              !wallet?.balance ||
              wallet.balance < (selectedProduct?.metadata?.quantity ?? 0)
            ) {
              setOpenTopup(true);
            } else {
              purchaseDraw();
            }
          }}
          size="medium"
          variant="tertiary"
          sx={{
            fontSize: 14,
            pl: 6,
            pr: 6,
            pt: 1.25,
            pb: 1.25,
          }}
        />
        {loading && (
          <Typography
            sx={{
              mt: 3,
              cursor: "pointer",
            }}
            alignSelf="center"
            variant="notice"
            fontSize={14}
            fontWeight={400}
            onClick={() => {
              setLoading(false);
            }}
          >
            Cancel
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
