import { Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { gsap } from "gsap";
import { PriceCard } from "./PriceCard";
import { PriceModal } from "./PriceModal";
import { TopUpModal } from "../TopUpModal/TopUpModal";
import { useQuery } from "@tanstack/react-query";
import { getProductCatalogs } from "../../api/payment";
import { ProductDto } from "../../types/ProductDto";
import { useAppSelector } from "../../redux/hooks";
import { getFiatWallet, getFreeDrawWallet } from "../../api/wallet";
import { useSearchParams } from "react-router-dom";

type Props = {
  canAnimate: boolean;
  setAnimateGacha: (v: boolean) => void;
  gender: string;
  selectedProduct: ProductDto | null;
  setSelectedProduct: (v: ProductDto) => void;
  slug: string;
  setSwipes: Function;
};

export const PriceCards: React.FC<Props> = ({
  canAnimate,
  setAnimateGacha,
  gender,
  selectedProduct,
  setSelectedProduct,
  slug,
  setSwipes,
}) => {
  const [open, setOpen] = useState(false);
  const [openTopup, setOpenTopup] = useState(false);
  const [bottomModifier, setBottomModifier] = useState(1);
  const user = useAppSelector((state) => state.user.user);
  const appState = useAppSelector((state) => state.appState);
  const [params] = useSearchParams();

  const { data: products } = useQuery({
    queryKey: ["products", slug],
    queryFn: () => getProductCatalogs(slug),
    enabled: !!slug,
  });

  const { data: freeDraw } = useQuery({
    queryKey: ["freeDraw", user?.uid],
    queryFn: () => getFreeDrawWallet(),
    enabled: !!user?.uid,
  });

  const { data: wallet } = useQuery({
    queryKey: ["wallet", user?.uid],
    queryFn: () => getFiatWallet(),
    enabled: !!user?.uid,
  });

  const sortedProducts = useMemo(() => {
    if (!products) return [];
    return products?.sort(
      (a, b) => (b.metadata?.quantity ?? 0) - (a.metadata?.quantity ?? 0)
    );
  }, [products]);

  useEffect(() => {
    setBottomModifier((window.innerHeight - 520) / 4);
    function handleResize() {
      setBottomModifier((window.innerHeight - 520) / 4);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const selectProduct = (product: ProductDto, v: boolean) => {
    setSelectedProduct(product);
    setOpen(v);
    let cardSequence = [1, 3, 5, 10];
    switch (product?.metadata?.quantity) {
      case 10:
        cardSequence = [1, 3, 5, 10];
        break;
      case 5:
        cardSequence = [1, 3, 10, 5];
        break;
      case 3:
        cardSequence = [1, 10, 5, 3];
        break;
      default:
        cardSequence = [10, 5, 3, 1];
    }
    cardSequence.forEach((quantity, index) => {
      gsap.to(`.price-card-${quantity ?? 0}`, {
        zIndex: index + 1,
        duration: 0.1,
      });
    });
  };

  useEffect(() => {
    if (canAnimate) {
      const tl = gsap.timeline();
      tl.to(".price-cards", {
        display: "flex",
        duration: 0.01,
      })
        .to(".price-card", {
          display: "flex",
          duration: 0.01,
        })
        .to(".price-card", {
          display: "flex",
          y: 0,
          stagger: 0.25,
          reversed: true,
        });
    } else {
      const tl = gsap.timeline();
      tl.to(".price-card", { y: -1000, display: "none", duration: 0.01 }).to(
        ".price-cards",
        {
          display: "none",
          duration: 0.01,
        }
      );
    }
  }, [canAnimate]);

  // select product if paymentSuccess is true
  useEffect(() => {
    const productId = params.get("productId");
    if (!!productId) {
      const selectedProduct = products?.find((p) => p.id === +productId);
      if (!!selectedProduct) {
        selectProduct(selectedProduct, true);
      }
    }
  }, [params, products]);

  return (
    <Stack
      direction="column"
      sx={{
        width: "calc(100% - 8px - 8px)",
        height: "100%",
        backgroundPosition: "center",
        alignItems: "center",
        position: "relative",
        gap: 2,
        justifyContent: "flex-end",
        zIndex: 10,
        display: "none",
      }}
      className="price-cards"
    >
      {sortedProducts.map((product, index) => (
        <PriceCard
          quantity={product?.metadata?.quantity ?? 0}
          price={`${
            product?.prices?.find(
              (p) => p?.currency?.label === appState.currency
            )?.currency?.symbol ?? appState.currencySymbol
          }${(
            product?.prices?.find(
              (p) => p?.currency?.label === appState.currency
            )?.rate ?? 0
          ).toFixed(2)}`}
          isFree={
            !!product?.prices?.find(
              (p) => p?.currency?.label === "FREE DRAW"
            ) &&
            (freeDraw?.balance ?? 0) >=
              (product?.prices?.find((p) => p?.currency?.label === "FREE DRAW")
                ?.rate ?? 0)
          }
          gender={gender}
          key={product.id}
          sx={{
            bottom: (sortedProducts.length - 1 - index) * bottomModifier + 16,
            position: "absolute",
            zIndex: index + 1,
            transform: "translateY(-300%)",
            display: "none",
          }}
          setOpen={(v) => {
            selectProduct(product, v);
          }}
        />
      ))}
      <PriceModal
        selectedProduct={selectedProduct}
        open={open}
        setOpen={setOpen}
        setOpenTopup={setOpenTopup}
        gender={gender}
        setSwipes={setSwipes}
        isFree={
          !!selectedProduct?.prices?.find(
            (p) => p?.currency?.label === "FREE DRAW"
          ) &&
          (freeDraw?.balance ?? 0) >=
            (selectedProduct?.prices?.find(
              (p) => p?.currency?.label === "FREE DRAW"
            )?.rate ?? 0)
        }
        setAnimateNext={() => {
          const tl = gsap.timeline();
          tl.to(".price-card", {
            display: "none",
            duration: 0.01,
          })
            .to(".price-card", {
              display: "none",
              y: "-300%",
              duration: 0.01,
            })
            .to(".price-cards", {
              display: "none",
              duration: 0.01,
            })
            .call(() => {
              setAnimateGacha(true);
            });
        }}
      />
      <TopUpModal
        open={openTopup}
        setOpen={setOpenTopup}
        isRequired
        successUrl={`${process.env.REACT_APP_APP_URL}?action=${slug}&productId=${selectedProduct?.id}`}
        minAmount={Math.max(
          (selectedProduct?.prices?.find(
            (p) => p?.currency?.label === appState.currency
          )?.rate ?? 0) - (wallet?.balance ?? 0),
          0
        )}
        from="draw"
      />
    </Stack>
  );
};
