import { ChevronLeft } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router";
import { getUser } from "../../api/user";
import { Permission, useAuth } from "../../hooks/useAuth";
import { ProfileCard } from "../ProfileCard/ProfileCard";

export const OtherProfilePage = () => {
  useAuth(Permission.USER_ONLY);
  const params = useParams();
  const navigate = useNavigate();

  const { data: user } = useQuery({
    queryKey: ["user", params.uid?.toString() ?? ""],
    queryFn: () => getUser(params.uid?.toString() ?? ""),
    enabled: !!params.uid,
  });

  return (
    <Stack
      direction="column"
      width="100%"
      sx={{
        alignItems: "center",
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.83%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 21.37%), url(/dull_bg.png), lightgray 50% / cover no-repeat",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        gap: 2,
        justifyContent: "center",
        pt: 6,
        pb: 13,
        height: "100dvh",
      }}
    >
      <Stack width="100%" maxWidth={600}>
        <ChevronLeft
          sx={{
            cursor: "pointer",
            fontSize: 16,
            color: "white",
            alignSelf: "flex-start",
            ml: 2,
          }}
          onClick={() => navigate(-1)}
        />
      </Stack>
      {!!user ? <ProfileCard user={user} /> : <></>}
    </Stack>
  );
};
