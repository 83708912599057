import { Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { gsap } from "gsap";
import { GachaCard } from "./GachaCard";
import TextPlugin from "gsap/dist/TextPlugin";
import { SwipeDto } from "../../types/SwipeDto";
import { GachaEndCard } from "./GachaEndCard";

type Props = {
  canAnimate: boolean;
  setAnimateNext: (v: boolean) => void;
  rotated?: boolean;
  swipes: SwipeDto[];
  showSuggestions: boolean;
  setShowSuggestions: Function;
};

export const GachaCards: React.FC<Props> = ({
  canAnimate,
  setAnimateNext,
  swipes,
  showSuggestions,
  setShowSuggestions,
}) => {
  const [selectedCardIndex, setSelectedCardIndex] = useState(-1);
  const [openCard, setOpenCard] = useState(false);

  const selectedCard = useMemo(() => {
    if (!swipes || selectedCardIndex < 0) return undefined;
    return swipes?.[selectedCardIndex];
  }, [swipes, selectedCardIndex]);

  useEffect(() => {
    if (!swipes.length) return;
    gsap.registerPlugin(TextPlugin);
    if (canAnimate) {
      const tl = gsap.timeline();
      tl.to(".gacha-section", {
        display: "flex",
        duration: 0.01,
      }).to(
        ".gacha-card",
        {
          y: 0,
          stagger: 0.25,
        },
        "<"
      );
      for (let i = 1; i <= swipes.length; i++)
        tl.set(
          ".card-count",
          {
            text: `${i} card(s)`,
            delay: 0.25,
          },
          "<"
        );
      tl.call(() => {
        setTimeout(() => {
          setAnimateNext(true);
          const tl = gsap.timeline();
          tl.to(".gacha-card-container-0", {
            duration: 0.25,
            height: "100%",
            bottom: 0,
            stagger: 0.25,
            paddingBottom: "48px",
            zIndex: 11,
          })
            .to(
              `.gacha-card-0`,
              {
                rotationY: "+=360",
                zIndex: 11,
                duration: 1,
                background: "white",
                height: "100%",
                ease: "Power4.easeOut",
                cursor: "auto",
                maxWidth: "450px",
              },
              "<"
            )
            .set(
              ".card-count",
              {
                text: `Tap to rotate`,
                delay: 0.25,
                fontSize: 12,
                stagger: 0.25,
              },
              "<"
            )
            .to(
              ".gacha-content",
              {
                opacity: 1,
                pointerEvents: "auto",
              },
              "<0.5"
            )
            .set(".gacha-card-container-0", {
              zIndex: 5,
            })
            // // animate the other cards
            // .to(".gacha-card-container-others", {
            //   duration: 0.01,
            //   height: "100%",
            //   bottom: 0,
            //   paddingBottom: "48px",
            // })
            // .to(
            //   `.gacha-card-others`,
            //   {
            //     zIndex: 11,
            //     duration: 0.01,
            //     background: "white",
            //     height: "100%",
            //     ease: "Power4.easeOut",
            //     cursor: "auto",
            //     maxWidth: "450px",
            //   },
            //   "<"
            // )
            .call(() => {
              setSelectedCardIndex(0);
              setOpenCard(true);
            });
        }, 1000);
      });
    } else {
      setOpenCard(false);
      setSelectedCardIndex(-1);

      const tl = gsap.timeline();
      tl.to(".gacha-section", {
        display: "none",
        duration: 0.01,
      }).to(".gacha-card", {
        y: -1000,
        duration: 0.01,
      });
      // .to(".gacha-card-container", {
      //   opacity: 1,
      //   duration: 0.01,
      // });
      // .to(".gacha-card-0", {
      //   duration: 0.01,
      //   zIndex: 0,
      //   background: "url(/card_back.png) no-repeat center",
      //   width: "100%",
      //   maxWidth: "300px",
      //   height: "100%",
      //   rotationY: "-=360",
      // })
      // .set(".card-count", {
      //   text: "",
      //   fontSize: 32,
      //   duration: 0.01,
      // })
      // .to(".gacha-card-container", {
      //   duration: 0.01,
      //   height: "500px",
      //   width: "100%",
      //   bottom: 56,
      //   zIndex: 0,
      //   paddingBottom: 0,
      // })
      // .to("gacha-content", {
      //   opacity: 0,
      //   pointerEvents: "none",
      //   duration: 0.01,
      // });
    }
  }, [canAnimate, swipes]);

  return (
    <Stack
      direction="column"
      sx={{
        width: "calc(100% - 8px - 8px)",
        height: "100%",
        backgroundPosition: "center",
        alignItems: "center",
        position: "relative",
        display: "none",
        gap: 2,
        justifyContent: "flex-end",
        zIndex: 10,
      }}
      className="gacha-section"
    >
      <Stack
        position="relative"
        sx={{
          width: "100%",
          height: "100%",
        }}
        direction="column"
      >
        {swipes.map((swipe, index) => (
          <GachaCard
            key={swipe.uid}
            id={swipe.uid}
            index={index}
            selected={
              (!selectedCard || selectedCard?.uid === swipe.uid) &&
              selectedCardIndex < swipes.length
            }
            swipe={swipe}
            showSuggestions={showSuggestions}
            setShowSuggestions={setShowSuggestions}
            setSelectNext={() => {
              setSelectedCardIndex(
                index + 1 >= swipes.length ? swipes.length : index + 1
              );
            }}
            open={openCard}
          />
        ))}
        <GachaEndCard
          index={swipes.length}
          selected={selectedCardIndex === swipes.length}
          open={openCard}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          position="absolute"
          bottom={0}
          width="100%"
          maxWidth={600}
          sx={{
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 15,
          }}
        >
          <img
            src="/left_arrow.svg"
            alt="left"
            style={{
              width: 40,
              height: 40,
              cursor: "pointer",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
              opacity: selectedCardIndex === 0 ? 0 : 1,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedCardIndex((prev) => (prev - 1 < 0 ? 0 : prev - 1));
            }}
          />
          <Typography
            variant="headlineLarge"
            color="white"
            textAlign="center"
            className="card-count"
            onClick={() => {
              if (selectedCardIndex === swipes.length) {
                window.location.href = "/";
              }
            }}
          ></Typography>
          <img
            src="/right_arrow.svg"
            alt="right"
            style={{
              width: 40,
              height: 40,
              cursor: "pointer",
              WebkitTapHighlightColor: "rgba(0,0,0,0)",
              opacity: selectedCardIndex === swipes.length ? 0 : 1,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedCardIndex((prev) =>
                prev + 1 >= swipes.length ? swipes.length : prev + 1
              );
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
